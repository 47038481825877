import User1 from "../../assets/user1.png";
import "./Profile.scss";

const Profile = ({
  image = User1,
  name = "Andrew Donovan",
  role = "Editor",
}) => {
  return (
    <div className="profile-component">
      <div className="image-container">
        <img src={image} />
      </div>
      <div className="name-title">
        <h5>{name}</h5>
        <span>{role}</span>
      </div>
      {/* {role === 'Customer' && <button className='update-profile'>
                Update Profile
            </button>} */}
    </div>
  );
};

export default Profile;
