import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import Profile from "../../components/profile/Profile";
import SideBar from "../../components/sidebar/SideBar";
import { sideBarItems } from "../../components/sidebar/sideBarItems";
import "./Layout.scss";
import { BiSearch, BiBell, BiLogOut } from "react-icons/bi";
import {
  AiOutlineClose,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineMenu,
} from "react-icons/ai";
import User2 from "../../assets/user2.png";
import User3 from "../../assets/user3.png";
import User4 from "../../assets/user4.png";
import Logo from "../../assets/logo.png";
import Mark from "../../assets/mark.png";
import { Link } from "react-router-dom";
import { userService } from "../../services/user.service";
import Swal from "sweetalert2/dist/sweetalert2";
import Loading from "../loading/Loading";
import Cookies from "js-cookie";
import io, { Socket } from "socket.io-client";
import moment from "moment";
import NotificationSfx from "../../assets/songs/notification.mp3";
import { useFormik } from "formik";
import { authService } from "../../services/auth.service";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AdminLayout = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [currentPageName, setCurrentPageName] = useState("");
  const [addQuestion, setAddQuestion] = useState(false);
  const [addEditor, setAddEditor] = useState(false);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [audio, setAudio] = useState(new Audio(NotificationSfx));
  const [newNotification, setNewNotification] = useState(false);
  const [isPasswordShown, setIsPasswordShows] = useState(false);

  const registerFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      values.type = "editor";
      authService.register(values, (data) => {
        Swal.fire({
          title: "Editor registered!",
          text: "The editor was registered and will receive a email with a link to complete their profile",
          timer: 3000,
          customClass: {
            container: "my-swal",
          },
        }).then(() => {
          setAddEditor(false);
          registerFormik.setFieldValue("email", "");
          registerFormik.setFieldValue("password", "");
        });
      });
    },
  });

  useEffect(() => {
    if (newNotification) {
      setTimeout(() => {
        setNewNotification(false);
      }, 5000);
    }
  }, [newNotification]);

  useEffect(() => {
    setCurrentPage(window.location.pathname.split("/")[2]);
    userService.getProfile(
      (data) => {
        setUser(data);
      },
      (e) => {
        Swal.fire({
          title: "Error!",
          text: "Please, login before entering your account",
          icon: "error",
        }).then(() => {
          navigate("/auth/admin/sign-in");
        });
      }
    );
  }, []);

  useEffect(() => {
    const newSocket = io(`https://api.markstudioshub.com/notification`, {
      // const newSocket = io(`http://localhost:3001/notification`, {
      query: { token: Cookies.get("token") },
    });
    setSocket(newSocket);
    newSocket.emit("notification:join");
    newSocket.emit("notification:get");
    newSocket.on("notification:get-return", (data) => {
      setNotifications(data.reverse());
    });

    return () => {
      console.log("disconnecting");
      newSocket.emit("chat:disconnect");
      // newSocket.close()
    };
  }, []);

  useEffect(() => {
    if (socket && socket !== null) {
      socket.on("notification:new", (data) => {
        audio.play();
        setNotifications([data, ...notifications]);
        setNewNotification(true);
      });
    }
  }, [notifications]);

  useEffect(() => {
    if (window.location.pathname.split("/")[3]) {
      if (window.location.pathname.split("/")[2] === "editors") {
        setCurrentPageName("Editor Details");
      } else if (window.location.pathname.split("/")[2] === "clients") {
        setCurrentPageName("Client Details");
      } else if (window.location.pathname.split("/")[2] === "orders") {
        setCurrentPageName("Order Delivery");
      }
    } else {
      sideBarItems.sideBarItemsAdmin.map((item) => {
        if (item.id === currentPage) {
          setCurrentPageName(item.name);
        }
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (window.location.pathname.split("/")[2] === "editors") {
      setCurrentPageName("Editors");
      setCurrentPage("editors");
    } else if (window.location.pathname.split("/")[2] === "dashboard") {
      setCurrentPageName("Dashboard");
      setCurrentPage("dashboard");
    } else if (window.location.pathname.split("/")[2] === "earning") {
      setCurrentPageName("My Earnings");
      setCurrentPage("earnings");
    } else if (window.location.pathname.split("/")[2] === "inbox") {
      setCurrentPageName("Inbox");
      setCurrentPage("inbox");
    } else if (window.location.pathname.split("/")[2] === "support") {
      setCurrentPageName("FAQ");
      setCurrentPage("support");
    } else if (window.location.pathname.split("/")[2] === "profile") {
      setCurrentPageName("Profile");
      setCurrentPage("profile");
    } else if (window.location.pathname.split("/")[2] === "clients") {
      setCurrentPageName("Clients");
      setCurrentPage("clients");
    }

    if (window.location.pathname.split("/")[3]) {
      if (window.location.pathname.split("/")[2] === "editors") {
        setCurrentPageName("Editor Details");
      } else if (window.location.pathname.split("/")[2] === "clients") {
        setCurrentPageName("Client Details");
      } else if (window.location.pathname.split("/")[2] === "orders") {
        setCurrentPageName("Order Delivery");
      } else if (window.location.pathname.split("/")[2] === "packages") {
        if (window.location.pathname.split("/")[3] === "my-package") {
          setCurrentPageName("My Package");
        } else if (
          window.location.pathname.split("/")[3] === "select-package"
        ) {
          setCurrentPageName("Select your monthly package");
        } else if (
          window.location.pathname.split("/")[3] === "order-summary" ||
          window.location.pathname.split("/")[3] === "add-ons"
        ) {
          setCurrentPageName("Order Summary");
        } else if (
          window.location.pathname.split("/")[3] === "custom-package"
        ) {
          setCurrentPageName("Create Custom Package");
        } else if (window.location.pathname.split("/")[3] === "single-order") {
          setCurrentPageName("Single Order");
        }
      }
    }
  });

  const openNotifications = () => {
    setIsNotificationOpen(false);
    socket.emit("notification:read");
    socket.on("notification:read-return", (data) => {
      setNotifications(data);
    });
  };

  return user && user !== null ? (
    <div className="admin-layout-main-container">
      {addEditor ? (
        <div className="add-editor">
          <div className="login-box">
            <span className="title">Add a new editor</span>
            <div className="login-info">
              <div className="input-box">
                <span className="input-title">Email Address</span>
                <input
                  id="email"
                  name="email"
                  onChange={registerFormik.handleChange}
                  value={registerFormik.values.email}
                  type="email"
                  placeholder="youremail@gmail.com"
                />
              </div>
              <div className="input-box">
                <span className="input-title">Password</span>
                <div className="password">
                  <input
                    id="password"
                    name="password"
                    onChange={registerFormik.handleChange}
                    value={registerFormik.values.password}
                    type={isPasswordShown ? "text" : "password"}
                    placeholder="••••••••"
                  />
                  {isPasswordShown ? (
                    <AiOutlineEye
                      onClick={() => setIsPasswordShows(!isPasswordShown)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      onClick={() => setIsPasswordShows(!isPasswordShown)}
                    />
                  )}
                </div>
              </div>
            </div>
            <button className="login" onClick={registerFormik.handleSubmit}>
              Register
            </button>
            <button
              className="login"
              onClick={() => {
                registerFormik.setFieldValue("email", "");
                registerFormik.setFieldValue("password", "");
                setAddEditor(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : null}
      {newNotification && (
        <div
          className="new-notification"
          onClick={() => {
            if (notifications[0].link && notifications[0].link !== null) {
              navigate(`/admin${notifications[0].link}`);
            } else {
              navigate("/admin/inbox", {
                state: { chat: { data: { id: notifications[0].chatId } } },
              });
            }
          }}
        >
          <div className="notification">
            <div className="details">
              <div className="text">
                <span>{notifications[0].text} </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMenuOpen && (
        <div className="menu-side">
          <div className="menu-container">
            <div className="menu-header">
              <img className="logo" src={Logo} />
              <div className="icon-menu" onClick={() => setIsMenuOpen(false)}>
                <AiOutlineClose />
              </div>
            </div>
            <div
              className="menu-profile"
              onClick={() => {
                setCurrentPage("profile");
                setCurrentPageName("Profile");
                setIsMenuOpen(false);
                navigate("./profile");
              }}
            >
              <Profile
                name={`${user.firstName} ${user.lastName}`}
                role="Admin"
                image={
                  user.profilePicture !== null
                    ? user.profilePicture.url
                    : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                }
              />
            </div>
            <div className="menu-body main">
              {sideBarItems.sideBarItemsAdmin.map((item) => {
                if (item.show) {
                  return (
                    <Link
                      onClick={() => {
                        setCurrentPage(item.id);
                        setCurrentPageName(item.name);
                        setIsMenuOpen(false);
                      }}
                      to={`./${item.id}`}
                      className="item"
                    >
                      <span className="icon">{item.icon}</span>
                      <span>{item.name}</span>
                    </Link>
                  );
                }
              })}
            </div>
            <div className="menu-footer">
              <div className="menu-body">
                <div
                  className="item"
                  onClick={() => {
                    Cookies.remove("token");
                    navigate("/");
                  }}
                >
                  <span className="icon">
                    <BiLogOut />
                  </span>
                  <span>Logout</span>
                </div>
                <div className="terms-and-policy">
                  <span>Terms of Use</span>
                  <span>Privacy Policy</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isNotificationOpen && (
        <div className="notifications">
          <div className="notifications-container">
            <div className="notifications-header">
              <span className="title">Notifications</span>
              <div className="icon" onClick={() => openNotifications()}>
                <AiOutlineClose color="black" />
              </div>
            </div>
            <div className="notifications-body">
              {notifications.map((notification) => {
                return (
                  <div
                    className={`notification`}
                    onClick={() => {
                      if (notification.link && notification.link !== null) {
                        navigate(`/admin${notification.link}`);
                      } else {
                        navigate("/admin/inbox", {
                          state: {
                            chat: { data: { id: notification.chatId } },
                          },
                        });
                      }
                    }}
                  >
                    <div className="details">
                      <div className="text">
                        <span>
                          {notification.text}
                          {notification.read ? "" : " - New"}
                        </span>
                      </div>
                      <span className="time">
                        {moment(new Date(notification.createdAt)).format(
                          "DD MMMM YYYY"
                        )}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {getWindowDimensions().width > 950 && (
        <div className="sidebar">
          <SideBar
            notifications={notifications}
            type="admin"
            id={currentPage}
            changePage={setCurrentPage}
            changePageName={setCurrentPageName}
          />
        </div>
      )}
      <div className="content-container">
        <div className="header">
          {getWindowDimensions().width < 950 && (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="icon menu" onClick={() => setIsMenuOpen(true)}>
                <AiOutlineMenu />
              </div>
              {getWindowDimensions().width > 450 ? (
                <h1>{currentPageName}</h1>
              ) : (
                <h3>{currentPageName}</h3>
              )}
            </div>
          )}
          {getWindowDimensions().width > 950 && <h1>{currentPageName}</h1>}
          <div className="icons">
            {currentPage === "support" && getWindowDimensions().width > 450 && (
              <button
                onClick={() => setAddQuestion(true)}
                style={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#2A9DF4",
                  padding: "1em 2em",
                  fontWeight: "500",
                  border: 0,
                  borderRadius: "25px",
                }}
              >
                Add Question
              </button>
            )}
            {/* {currentPage === "orders" &&
              getWindowDimensions().width > 450 &&
              !window.location.pathname.split("/")[3] && (
                <button
                  onClick={() => setAddQuestion(true)}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "#2A9DF4",
                    padding: "1em 2em",
                    fontWeight: "500",
                    border: 0,
                    borderRadius: "25px",
                  }}
                >
                  + Create Order
                </button>
              )} */}
            {currentPage === "editors" &&
              getWindowDimensions().width > 450 &&
              !window.location.pathname.split("/")[3] && (
                <button
                  onClick={() => setAddEditor(true)}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "#2A9DF4",
                    padding: "1em 2em",
                    fontWeight: "500",
                    border: 0,
                    borderRadius: "25px",
                  }}
                >
                  Add Editor
                </button>
              )}
            {/* <div className="icon">
              <BiSearch />
            </div> */}
            <div
              className="icon notifications-icon"
              onClick={() => {
                setIsNotificationOpen(true);
              }}
            >
              {notifications.filter((item) => !item.read) &&
              notifications.filter((item) => !item.read).length ? (
                <div className="red-dot"></div>
              ) : null}
              <BiBell />
            </div>
            {getWindowDimensions().width > 450 && (
              <div
                className="profile"
                onClick={() => {
                  setCurrentPage("profile");
                  setCurrentPageName("Profile");
                  navigate("./profile");
                }}
              >
                <Profile
                  name={`${user.firstName} ${user.lastName}`}
                  role="Admin"
                  image={
                    user.profilePicture !== null
                      ? user.profilePicture.url
                      : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="content">
          <Outlet
            context={[
              addQuestion,
              setAddQuestion,
              user,
              setUser,
              addEditor,
              notifications,
              setNotifications,
              socket,
            ]}
          />
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default AdminLayout;
