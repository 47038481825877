import { coreService } from "./core.service"

const getAllEditors = (page, type = 'all', callback) => {
    coreService.get(`editor/list/${type}/${page}`, callback)
}

const getEditorDetails = (editorId, callback) => {
    coreService.get(`editor/details/${editorId}`, callback)
}

const changeAcceptance = (editorId, acceptance, callback) => {
    coreService.post(`editor/change-acceptance/${editorId}`, {acceptance}, callback)
}

export const editorService = {
    getAllEditors,
    getEditorDetails,
    changeAcceptance
}