import "./Clients.scss";
import { useEffect, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import AllClients from "../../../../components/clients/allClients/AllClients";
import BlockedClients from "../../../../components/clients/blockedClients/BlockedClients";
import { clientService } from "../../../../services/client.service";

const AdminClients = () => {
  const [currentPage, setCurrentPage] = useState("All Clients");
  const [clients, setClients] = useState([]);
  const [clientsCount, setClientsCount] = useState(0);

  useEffect(() => {
    getClients(1);
  }, [currentPage]);

  const getClients = (page) => {
    let type = "customer";
    if (currentPage === "Blocked Clients") {
      type = "blocked";
    }

    clientService.getAllClients(page, type, (data) => {
      setClients(data.clients);
      setClientsCount(data.count);
    });
  };

  const changeBlock = (id, block, index) => {
    clientService.changeBlock(id, block, (data) => {
      getClients(1);
    });
  };

  return (
    <div className="admin-layout-clients-page">
      <div className="header">
        <div className="types">
          <div
            onClick={() => setCurrentPage("All Clients")}
            className={`${currentPage === "All Clients" ? "active" : ""}`}
          >
            All Clients
          </div>
          <div
            onClick={() => setCurrentPage("Blocked Clients")}
            className={`${currentPage === "Blocked Clients" ? "active" : ""}`}
          >
            Blocked Clients
          </div>
        </div>
      </div>
      {!clients || (!clients.length && <h2>No Clients to Show</h2>)}
      {clients && clients.length && currentPage === "All Clients" ? (
        <AllClients
          getClients={getClients}
          clients={clients}
          count={clientsCount}
        />
      ) : null}
      {clients && clients.length && currentPage === "Blocked Clients" ? (
        <BlockedClients
          changeBlock={changeBlock}
          getClients={getClients}
          clients={clients}
          count={clientsCount}
        />
      ) : null}
    </div>
  );
};

export default AdminClients;
