import { useState, useEffect } from "react";
import "./Outro.scss";
import { FaCloudUploadAlt, FaRegTrashAlt } from "react-icons/fa";
import { AiFillFile } from "react-icons/ai";
import ProgressBar from "../../../../../../../components/progressBar/ProgressBar";
import { uploadService } from "../../../../../../../services/upload.service";
import LoadingUpload from "../../../../../../loadingUpload/LoadingUpload";

const Outro = ({
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  user,
  order,
  added,
}) => {
  const [add, setAdd] = useState("yes");
  const [studioDecides, setStudioDecides] = useState(false);
  const [status, setStatus] = useState("waiting");
  const [text, setText] = useState("");
  const [outro, setOutro] = useState(null);
  const [logo, setLogo] = useState(null);
  const [music, setMusic] = useState(null);
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (order.outro) {
      if (order.outro.outro && order.outro.outro !== null) {
        setOutro(order.outro.outro);
      }

      if (order.outro.text && order.outro.text !== null) {
        setText(order.outro.text);
      }

      if (order.outro.logo && order.outro.logo !== null) {
        setLogo(order.outro.logo);
      }

      if (order.outro.music && order.outro.music !== null) {
        setMusic(order.outro.music);
      }

      if (order.outro.studioDecides && order.outro.studioDecides !== null) {
        setStudioDecides(order.outro.studioDecides);
      }

      if (order.outro.comments && order.outro.comments !== null) {
        setComments(order.outro.comments);
      }
    }
  }, [order]);

  const changeStep = (isStepNext) => {
    if (isStepNext) {
      if (validateFields()) {
        const obj =
          add === "no"
            ? null
            : {
                text,
                outro,
                logo,
                music,
                studioDecides,
                comments,
              };
        changeComponent("outro", obj);

        nextStep();
      } else {
        validate("Please check that you added all required information");
      }
    } else {
      prevStep();
    }
  };

  const validateFields = () => {
    if (add === "already-have" && (!outro || outro === null)) {
      return false;
    }

    if (add === "yes") {
      if ((!music || music === null) && !studioDecides) {
        return false;
      }

      if (!logo || logo === null) {
        return false;
      }

      if (!text || text.trim() === "") {
        return false;
      }
    }

    return true;
  };

  return (
    <div className="outro-new-order">
      {loading ? <LoadingUpload /> : null}
      <div className="inner-content">
        <div className="item">
          <span className="title-item">Do you need a Personalized Outro?</span>
          <div className="checkboxes">
            <div className="radio-box">
              <input
                id="yes"
                name="yes"
                type="radio"
                checked={add === "yes"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAdd("yes");
                  }
                }}
              />
              <label for="yes" className="legend">
                Yes
              </label>
            </div>
            <div className="radio-box">
              <input
                id="already-have"
                name="already-have"
                type="radio"
                checked={add === "already-have"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAdd("already-have");
                  }
                }}
              />
              <label for="already-have" className="legend">
                I already have one
              </label>
            </div>
            {!added ? (
              <div className="radio-box">
                <input
                  id="no"
                  name="no"
                  type="radio"
                  checked={add === "no"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setAdd("no");
                    }
                  }}
                />
                <label for="no" className="legend">
                  No
                </label>
              </div>
            ) : null}
          </div>
        </div>
        {add === "yes" && (
          <div className="add-yes">
            <div className="item">
              <span className="title-item">
                What text would you like to add to your outro?
              </span>
              <input
                placeholder="Type here"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
            <div className="upload">
              <span className="upload-title">Upload Logo</span>
              <input
                style={{ display: "none" }}
                type="file"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  setLoading(true);

                  if (file) {
                    let data = new FormData();
                    data.append("file", file);

                    await uploadService.upload(data, (data) => {
                      setLoading(false);
                      setLogo(data);
                    });
                  }
                }}
                id="drop-files-logo-outro"
              />
              {!logo || logo === null ? (
                <label for="drop-files-logo-outro" className="upload-box">
                  <FaCloudUploadAlt size={60} color="#D9DFE9" />
                  <span>Drop files here or click to upload.</span>
                </label>
              ) : (
                <div className="uploaded-box">
                  <div className="files">
                    <div className="item">
                      <AiFillFile size={60} color="#2A9DF4" />
                      <span>
                        {logo.originalName}{" "}
                        <FaRegTrashAlt
                          onClick={() => setLogo(null)}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!studioDecides && (
              <div className="upload">
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    setLoading(true);

                    if (file) {
                      let data = new FormData();
                      data.append("file", file);

                      await uploadService.upload(data, (data) => {
                        setLoading(false);
                        setMusic(data);
                      });
                    }
                  }}
                  id="drop-files-music-outro"
                />
                <span className="upload-title">Upload Music</span>
                {!music || music === null ? (
                  <label for="drop-files-music-outro" className="upload-box">
                    <FaCloudUploadAlt size={60} color="#D9DFE9" />
                    <span>Drop files here or click to upload.</span>
                  </label>
                ) : (
                  <div className="uploaded-box">
                    <div className="files">
                      <div className="item">
                        <AiFillFile size={60} color="#2A9DF4" />
                        <span>
                          {music.originalName}{" "}
                          <FaRegTrashAlt
                            onClick={() => setMusic(null)}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="let-studio-decides-box">
              <input
                id="let-studio"
                name="let-studio"
                type="checkbox"
                onChange={(e) => {
                  setStudioDecides(e.target.checked);
                }}
              />
              <label for="let-studio">
                Let Mark Studios pick music for this video
              </label>
            </div>
            <div className="item">
              <span className="title-item">Additional Comments</span>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                placeholder="Add some comments"
              />
            </div>
          </div>
        )}
        {add === "already-have" && (
          <div className="add-yes">
            <div className="upload">
              <input
                style={{ display: "none" }}
                type="file"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  setLoading(true);

                  if (file) {
                    let data = new FormData();
                    data.append("file", file);

                    await uploadService.upload(data, (data) => {
                      setLoading(false);
                      setOutro(data);
                    });
                  }
                }}
                id="drop-files-outro"
              />
              {!outro || outro === null ? (
                <label for="drop-files-outro" className="upload-box">
                  <FaCloudUploadAlt size={60} color="#D9DFE9" />
                  <span>Drop files here or click to upload.</span>
                </label>
              ) : (
                <div className="uploaded-box">
                  <div className="files">
                    <div className="item">
                      <AiFillFile size={60} color="#2A9DF4" />
                      <span>
                        {outro.originalName}{" "}
                        <FaRegTrashAlt
                          onClick={() => setOutro(null)}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}
        <button
          className="next"
          onClick={() => {
            changeStep(true);
          }}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default Outro;
