import { gapi } from "gapi-script";

const initClient = () => {
  gapi.client
    .init({
      apiKey: "AIzaSyCMJr25I0OXjWkfMkeqgd0FllBGOdE1yOk",
      discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
      ],
      clientId:
        "68605964120-f50jvrdgrvs4us9qm1cn3hf7hodgh17p.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/drive",
    })
    .then(
      function () {},
      function (error) {}
    );
};

const initGoogleAuth = async () => {
  gapi.load("client:auth2", initClient);
};

const parseUrlToFolderId = (url) => {
  if (url.includes("folders/")) {
    let rtnUrl = url.slice(url.indexOf("folders/") + 8, url.length);
    if (rtnUrl.includes("/")) {
      rtnUrl = rtnUrl.slice(0, rtnUrl.indexOf("/"));
    }
    if (rtnUrl.includes("?")) {
      rtnUrl = rtnUrl.slice(0, rtnUrl.indexOf("?"));
    }
    return rtnUrl;
  } else if (!url.includes("folders/") && url.trim() !== "") {
    alert("INVALID URL");
    return null;
  }
};

const getTotalTime = async (url, includeSubFolders) => {
  let noMetaFiles = [];
  let folderId = parseUrlToFolderId(url);
  return await gapi.client.drive.files
    .list({
      q: `'${folderId}' in parents`,
      fields: "files(id, name, mimeType, videoMediaMetadata)",
    })
    .then(async function (response) {
      var files = response.result.files;
      if (files && files.length > 0) {
        let totalmillis = 0;
        for (let file of files) {
          if (file.videoMediaMetadata) {
            totalmillis =
              totalmillis + parseInt(file.videoMediaMetadata.durationMillis);
          } else if (file.mimeType !== "application/vnd.google-apps.folder") {
            noMetaFiles.push(file);
          }

          if (
            includeSubFolders &&
            file.mimeType === "application/vnd.google-apps.folder"
          ) {
            totalmillis =
              totalmillis +
              (await getTotalTime(`folders/${file.id}`, includeSubFolders));
          }
        }

        return totalmillis;
      } else {
        alert("NO FILES FOUND OR INVALID PERMISSION");
      }
    });
};

export const DriveService = {
  getTotalTime,
  initGoogleAuth,
};
