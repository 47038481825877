import { coreService } from "./core.service";

const list = async (page, callback) => {
  coreService.get(`custom-package-request/list/${page}`, callback);
};

const saveRequest = async (body, callback) => {
  coreService.post("custom-package-request/save", body, callback);
};

export const CustomPackageRequestService = {
  list,
  saveRequest,
};
