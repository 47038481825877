import { coreService } from "./core.service";

const register = (body, callback) => {
  coreService.post("auth/register", body, callback);
};

const registerGoogle = (body, callback) => {
  coreService.post("auth/register-google", body, callback);
};

const registerWithPackage = (user, userPackage, card, callback) => {
  coreService.post(
    "auth/register-with-package",
    { user, userPackage, card },
    callback,
    undefined,
    undefined,
    true
  );
};

const registerWithPackageGoogle = (user, userPackage, card, callback) => {
  coreService.post(
    "auth/register-with-package-google",
    { user, userPackage, card },
    callback,
    undefined,
    undefined,
    true
  );
};

const finishSignUp = (body, callback) => {
  coreService.post("auth/finish-sign-up", body, callback);
};

const login = (body, callback) => {
  coreService.post("auth/login", body, callback);
};

const getUserByUuid = (uuid, callback) => {
  coreService.get(`auth/user-by-uuid/${uuid}`, callback);
};

const resetPassword = (uuid, email, password, callback) => {
  coreService.post("auth/reset-password", { uuid, email, password }, callback);
};

const sendResetPasswordEmail = (email, callback) => {
  coreService.post("auth/send-reset-password-email", { email }, callback);
};

export const authService = {
  register,
  registerGoogle,
  login,
  finishSignUp,
  getUserByUuid,
  registerWithPackage,
  registerWithPackageGoogle,
  resetPassword,
  sendResetPasswordEmail,
};
