import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./customPackageRequestsOrders.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { reference } from "../../ordersReference";
import { createHTMLOrder, createHTMLOrderPrint } from "../../setItemsPrint";
import moment from "moment";
import { orderService } from "../../../services/order.service";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const CustomPackageRequestsOrders = ({ orders, getOrders, ordersCount }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrders, setCurrentOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getOrders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    console.log(orders);
    setCurrentOrders(orders);
  }, [orders]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="custom-requests-component">
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            customStyle="1fr .5fr .5fr 1.5fr 2fr"
            fourth={true}
            titles={["Name", "Budget", "Status", "Project Details", ""]}
          />
          <div className="order-table">
            <TableRow
              customStyle="1fr .5fr .5fr 1.5fr 2fr"
              fourth={true}
              items={currentOrders.map((order, index) => {
                return [
                  <span style={{ fontWeight: 500 }}>{order.name}</span>,
                  <span style={{ fontWeight: 500 }}>${order.budget}</span>,
                  <div className={`status ${order.status}`}>
                    <span>
                      {
                        reference.filter(
                          (item) => item.status === order.status
                        )[0].name
                      }
                    </span>
                  </div>,
                  <span style={{ fontWeight: 500 }}>{order.email}</span>,
                  <span style={{ fontWeight: 500, textAlign: "start" }}>
                    {order.description.length > 20
                      ? `${order.description.substring(0, 20)}...`
                      : order.description}
                  </span>,
                  // <div
                  //   style={{
                  //     display: "flex",
                  //     alignContent: "center",
                  //     alignItems: "center",
                  //     justifyContent: "center",
                  //     gap: "1em",
                  //   }}
                  // >
                  //   <button className="button accept">Accept</button>
                  //   <button className="button deny">Deny</button>
                  // </div>,
                ];
              })}
            />
          </div>
        </>
      ) : (
        <>
          <>
            {currentOrders.map((order, index) => {
              return (
                <div className="order-mobile">
                  <div className="header-mobile">
                    <div className={`status ${order.status}`}>
                      <span>
                        {
                          reference.filter(
                            (item) => item.status === order.status
                          )[0].name
                        }
                      </span>
                    </div>
                    <div className="details">
                      <span className="name">
                        {order.name} - {order.email}
                      </span>
                      <span className="number">${order.budget}</span>
                    </div>
                  </div>
                  <div className="body-mobile">
                    <span className="body-description">
                      {order.description.length > 30
                        ? `${order.description.substring(0, 30)}...`
                        : order.description}
                    </span>
                    {/* <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "1em",
                      }}
                    >
                      <button className="button accept">Accept</button>
                      <button className="button deny">Deny</button>
                    </div> */}
                  </div>
                </div>
              );
            })}
          </>
        </>
      )}
      <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {ordersCount > currentPage * 6 ? currentPage * 6 : ordersCount} of{" "}
            {ordersCount}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={ordersCount < currentPage * 6 ? "disable" : "active"}
            onClick={nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomPackageRequestsOrders;
