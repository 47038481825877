import "./Logo.scss";
import { FaCloudUploadAlt, FaRegTrashAlt } from "react-icons/fa";
import { AiFillFile } from "react-icons/ai";
import ProgressBar from "../../../../../../../components/progressBar/ProgressBar";
import { useState, useEffect } from "react";
import { uploadService } from "../../../../../../../services/upload.service";
import LoadingUpload from "../../../../../../loadingUpload/LoadingUpload";

const Logo = ({
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  user,
  order,
}) => {
  const [status, setStatus] = useState("waiting");
  const [primaryColor, setPrimaryColor] = useState("#fff");
  const [secondaryColor, setSecondaryColor] = useState("#fff");
  const [colors, setColors] = useState(["#000000", "#000000"]);
  const [text, setText] = useState("");
  const [slogan, setSlogan] = useState("");
  const [description, setDescription] = useState("");
  const [industry, setIndustry] = useState("");
  const [loading, setLoading] = useState(false);
  const [additionalFiles, setAdditionalFiles] = useState([]);

  useEffect(() => {
    if (order.logo) {
      if (order.logo.colors && order.logo.colors !== null) {
        setColors(order.logo.colors.map((item) => item.color));
      }

      if (order.logo.text && order.logo.text !== null) {
        setText(order.logo.text);
      }

      if (order.logo.slogan && order.logo.slogan !== null) {
        setSlogan(order.logo.slogan);
      }

      if (order.logo.description && order.logo.description !== null) {
        setDescription(order.logo.description);
      }

      if (order.logo.industry && order.logo.industry !== null) {
        setIndustry(order.logo.industry);
      }

      if (order.logo.additionalFiles && order.logo.additionalFiles.length) {
        setAdditionalFiles(order.logo.additionalFiles);
      }
    }
  }, [order]);

  const changeStep = (isStepNext) => {
    if (isStepNext) {
      if (validateFields()) {
        changeComponent("logo", {
          colors: [...colors].map((color) => {
            return { color };
          }),
          text,
          slogan,
          description,
          industry,
          additionalFiles,
        });

        nextStep();
      } else {
        validate("Please check that you added all required information");
      }
    } else {
      prevStep();
    }
  };

  const validateFields = () => {
    if (!colors || !colors.length) {
      return false;
    }

    if (!text || text.trim() === "") {
      return false;
    }

    if (!slogan || slogan.trim() === "") {
      return false;
    }

    if (!description || description.trim() === "") {
      return false;
    }

    if (!industry || industry.trim() === "") {
      return false;
    }

    return true;
  };

  return (
    <div className="logo-new-order">
      {loading ? <LoadingUpload /> : null}
      <div className="inner-content">
        <div className="item">
          <span className="title-item">Select Colors</span>
          {colors.map((color, index) => {
            return (
              <div className="color-box">
                <span>Color {index + 1}</span>
                <div className="color">
                  <label for="primary-color">Click to select color</label>
                  <input
                    value={color}
                    onChange={(e) => {
                      let arr = [...colors][index];
                      arr = e.target.value;

                      setColors([
                        ...colors.slice(0, index),
                        arr,
                        ...colors.slice(index + 1),
                      ]);
                    }}
                    id="primary-color"
                    name="primary-color"
                    type="color"
                  />
                </div>
              </div>
            );
          })}
        </div>
        <button
          className="add-color"
          onClick={() => setColors([...colors, "#fff"])}
        >
          Add Color
        </button>
        <div className="item">
          <span className="title-item">
            What text do you want on your logo?
          </span>
          <input
            placeholder="Enter text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
        <div className="item">
          <span className="title-item">
            Do you have a slogan you want incorporated in your logo?
          </span>
          <input
            placeholder="Enter slogan"
            value={slogan}
            onChange={(e) => setSlogan(e.target.value)}
          />
        </div>
        <div className="item">
          <span className="title-item">
            Describe what your organization or product does and its target
            audience.
          </span>
          <textarea
            placeholder="Add some comments"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="item">
          <span className="title-item">Select your industry</span>
          <select
            placeholder="Select Industry"
            onChange={(e) => setIndustry(e.target.value)}
          >
            <option disabled selected value="">
              Select your industry
            </option>
            <option value="Aerospace">Aerospace</option>
            <option value="Transport">Transport</option>
            <option value="Technology">Technology</option>
            <option value="Telecommunication">Telecommunication</option>
            <option value="Agriculture">Agriculture</option>
            <option value="Construction">Construction</option>
            <option value="Education">Education</option>
            <option value="Pharmaceutical">Pharmaceutical</option>
            <option value="Food">Food</option>
            <option value="HealthCare">HealthCare</option>
            <option value="Entertainment">Entertainment</option>
            <option value="News">News</option>
            <option value="Energy">Energy</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Music">Music</option>
            <option value="Mining">Mining</option>
            <option value="Eletronics">Eletronics</option>
          </select>
        </div>
        <div className="upload">
          <span className="upload-title">
            Do you have any images, sketches or documents that might be helpful?
          </span>
          <span className="upload-sub-title">
            E.g.Your current logo, fonts, photos, illustrations, content, layout
            ideas etc.
          </span>
          <input
            style={{ display: "none" }}
            type="file"
            multiple
            onChange={async (e) => {
              const arr = e.target.files;
              const filesArr = [...additionalFiles];

              const result = await Promise.all(
                [...arr].map(async (file) => {
                  setLoading(true);
                  if (file) {
                    let data = new FormData();
                    data.append("file", file);

                    await uploadService.upload(data, (data) => {
                      setAdditionalFiles([...filesArr, data]);
                      filesArr.push(data);
                      setLoading(false);
                    });
                  }
                })
              );
            }}
            id="drop-files-logo-additional"
          />
          {!additionalFiles || !additionalFiles.length ? (
            <label for="drop-files-logo-additional" className="upload-box">
              <FaCloudUploadAlt size={60} color="#D9DFE9" />
              <span>Drop files here or click to upload.</span>
            </label>
          ) : (
            <div className="uploaded-box">
              <label className="upload-more" for="drop-files-logo-additional">
                Upload more items
              </label>
              <div className="files">
                {additionalFiles.map((file) => {
                  return (
                    <div className="item">
                      <AiFillFile size={60} color="#2A9DF4" />
                      <span>
                        {file.originalName.substring(0, 10)}{" "}
                        <FaRegTrashAlt
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const arr = [...additionalFiles].filter(
                              (item) => item.originalName !== file.originalName
                            );
                            setAdditionalFiles(arr);
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}
        <button
          className="next"
          onClick={() => {
            changeStep(true);
          }}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default Logo;
