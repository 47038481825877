import "./Client.scss";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Youtube from "../../../../../assets/youtube.png";
import Facebook from "../../../../../assets/facebook.png";
import Instagram from "../../../../../assets/instagram.png";
import Twitter from "../../../../../assets/twitter.png";
import Tiktok from "../../../../../assets/tiktok.png";
import Linkedin from "../../../../../assets/linkedin.png";
import Snapchat from "../../../../../assets/snapchat.png";
import Website from "../../../../../assets/website.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { clientService } from "../../../../../services/client.service";
import Loading from "../../../../loading/Loading";
import { orderService } from "../../../../../services/order.service";
import {
  getCities,
  getCountries,
  getStates,
} from "../../../../../assets/js/location";
import { uploadService } from "../../../../../services/upload.service";
import { userService } from "../../../../../services/user.service";

const AdminClient = () => {
  const params = useParams();
  const [client, setClient] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [billingCountries, setBillingCountries] = useState([]);
  const [billingStates, setBillingStates] = useState([]);
  const [billingCities, setBillingCities] = useState([]);
  const [businessCountries, setBusinessCountries] = useState([]);
  const [businessStates, setBusinessStates] = useState([]);
  const [businessCities, setBusinessCities] = useState([]);
  const [billingAddressCountry, setBillingAddressCountry] = useState("");
  const [billingAddressState, setBillingAddressState] = useState("");
  const [businessAddressCountry, setBusinessAddressCountry] = useState("");
  const [businessAddressState, setBusinessAddressState] = useState("");
  const [editNetwork, setEditNetwork] = useState(false);
  const [businessAddressCity, setBusinessAddressCity] = useState("");
  const [billingAddressCity, setBillingAddressCity] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    clientService.getClientDetails(params.clientId, (data) => {
      setClient(data);
    });
  }, [params]);

  useEffect(() => {
    getCountries((data) => {
      setBillingCountries(data);
      setBusinessCountries(data);
    });
  }, []);

  useEffect(() => {
    if (
      client &&
      client.billingAddress &&
      client.business &&
      client.business.address
    ) {
      if (billingCountries && billingCountries.length) {
        setBillingAddressCountry(
          billingCountries.filter(
            (country) => country.name === client.billingAddress.country
          )[0].iso2
        );
      }

      if (businessCountries && businessCountries.length) {
        setBusinessAddressCountry(
          businessCountries.filter(
            (country) => country.name === client.business.address.country
          )[0].iso2
        );
      }

      if (billingStates && billingStates.length) {
        setBillingAddressState(
          billingStates.filter(
            (state) => state.name === client.billingAddress.state
          )[0].iso2
        );
      }

      if (businessStates && businessStates.length) {
        setBusinessAddressState(
          businessStates.filter(
            (state) => state.name === client.business.address.state
          )[0].iso2
        );
      }
    }
  }, [
    billingCountries,
    businessCountries,
    billingStates,
    businessStates,
    client,
  ]);

  useEffect(() => {
    if (billingAddressCountry !== "") {
      getStates(billingAddressCountry, (data) => {
        setBillingStates(data);
      });
    }
  }, [billingAddressCountry]);

  useEffect(() => {
    if (billingAddressState !== "" && billingAddressCountry !== "") {
      getCities(billingAddressCountry, billingAddressState, (data) => {
        setBillingCities(data);
      });
    }
  }, [billingAddressState]);

  useEffect(() => {
    if (businessAddressCountry !== "") {
      getStates(businessAddressCountry, (data) => {
        setBusinessStates(data);
      });
    }
  }, [businessAddressCountry]);

  useEffect(() => {
    if (businessAddressState !== "" && businessAddressCountry !== "") {
      getCities(businessAddressCountry, businessAddressState, (data) => {
        setBusinessCities(data);
      });
    }
  }, [businessAddressState]);

  const changeAttribute = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(client));
    obj[attribute] = e.target.value;
    setClient(obj);
  };

  const changeNetwork = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(client));
    if (obj.socialMedias && obj.socialMedias.length) {
      if (
        obj.socialMedias.filter((item) => item.type === attribute) &&
        obj.socialMedias.filter((item) => item.type === attribute).length
      ) {
        obj.socialMedias.filter((item) => item.type === attribute)[0].account =
          e.target.value;
      } else {
        obj.socialMedias.push({
          type: attribute,
          account: e.target.value,
        });
      }
    } else {
      obj.socialMedias = [
        {
          type: attribute,
          account: e.target.value,
        },
      ];
    }
    setClient(obj);
  };

  const changeImage = (attribute, data) => {
    const obj = JSON.parse(JSON.stringify(client));
    obj[attribute] = data;
    setClient(obj);
  };

  const changeAddress = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(client));
    if (e.target) {
      obj.billingAddress[attribute] = e.target.value;
    } else {
      obj.billingAddress[attribute] = e;
    }
    setClient(obj);
  };

  const changeBusinessAddress = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(client));
    if (e.target) {
      obj.business.address[attribute] = e.target.value;
    } else {
      obj.business.address[attribute] = e;
    }
    setClient(obj);
  };

  const changeBusinessAttribute = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(client));
    obj.business[attribute] = e.target.value;
    setClient(obj);
  };

  const networks = [
    {
      name: "Youtube",
      img: Youtube,
      data: "youtube.com/markstudios",
    },
    {
      name: "Facebook",
      img: Facebook,
      data: "facebook.com/markstudios",
    },
    {
      name: "Instagram",
      img: Instagram,
      data: "instagram.com/markstudios",
    },
    {
      name: "Twitter",
      img: Twitter,
      data: "twitter.com/markstudios",
    },
    {
      name: "Tiktok",
      img: Tiktok,
      data: "tiktok.com/markstudios",
    },
    {
      name: "Linkedin",
      img: Linkedin,
      data: "linkedin.com/markstudios",
    },
    {
      name: "Snapchat",
      img: Snapchat,
      data: "snapchat.com/markstudios",
    },
    {
      name: "Personal Website",
      img: Website,
      data: "www.markstudios.com",
    },
  ];
  return client && client.email ? (
    <div className="admin-layout-client-details-page">
      <div className="header-main">
        <Link
          to="../clients"
          className="link-back"
          style={{ textDecoration: "none" }}
        >
          <AiOutlineArrowLeft size={20} color="#2A9DF4" />
          <span>Back to Clients</span>
        </Link>
        {client.firstName ? (
          <div>
            <button
              onClick={() => {
                orderService.chatWithAdmin(null, client.id, (data) => {
                  navigate("../inbox", { state: { chat: data } });
                });
              }}
              className="chat"
            >
              Chat with Client
            </button>
            {edit ? (
              <button
                onClick={() => {
                  userService.updateUser(client, (data) => {
                    setEdit(false);
                    setClient(data);
                  });
                }}
                className="edit"
              >
                Save
              </button>
            ) : (
              <button onClick={() => setEdit(true)} className="edit">
                Edit
              </button>
            )}
          </div>
        ) : null}
      </div>
      <div className="profile-picture-info">
        {edit ? (
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            style={{ display: "none" }}
            id={`upload-profile-picture-profile-customer`}
            onChange={(e) => {
              let file = e.target.files[0];
              setLoading(true);

              if (file) {
                let data = new FormData();
                data.append("file", file);

                uploadService.upload(data, (data) => {
                  setLoading(false);
                  changeImage("profilePicture", data);
                });
              }
            }}
          />
        ) : null}
        <label
          for="upload-profile-picture-profile-customer"
          className="image-circle"
        >
          {/* <CircularProgressbar value={60} className="circular-level-bar" strokeWidth={2} counterClockwise={true}
                        styles={buildStyles({
                            trailColor: `#F2F5FA`,
                            pathColor: '#2A9DF4',
                            backgroundColor: 'white',
                        })} /> */}
          <div className="image">
            <img
              src={
                client.profilePicture
                  ? client.profilePicture.url
                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
              }
            />
          </div>
        </label>
        <span className="name">
          {client.firstName} {client.lastName}
        </span>
        <span className="title">Customer</span>
      </div>
      <div className="mainInfo">
        <div className="name">
          <div className="item">
            <span className="title">First Name</span>
            <div className="input">
              {/* <span>Enter First Name</span> */}
              <input
                value={client.firstName}
                onChange={(e) => changeAttribute("firstName", e)}
              />
            </div>
          </div>
          <div className="item">
            <span className="title">Last Name</span>
            <div className="input">
              {/* <span>Enter Last Name</span> */}
              <input
                value={client.lastName}
                onChange={(e) => changeAttribute("lastName", e)}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <div className="item">
            <span className="title">Email</span>
            <div className="input">
              {/* <span>Enter Email</span> */}
              <input
                value={client.email}
                onChange={(e) => changeAttribute("email", e)}
              />
            </div>
          </div>
          <div className="item">
            <span className="title">Phone Number</span>
            <div className="input">
              {/* <span>Enter Phone Number</span> */}
              <input
                value={client.phoneNumber}
                onChange={(e) => changeAttribute("phoneNumber", e)}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <div className="item" style={{ marginLeft: 0 }}>
            <span className="title">Business Name</span>
            <div className="input">
              {/* <span>Enter Business Name</span> */}
              <input
                value={client.business.name}
                onChange={(e) => changeBusinessAttribute("name", e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="business-adress">
        <span className="title">Business Address</span>
        <div className="field">
          <div className="input">
            <span>Street</span>
            <input
              value={client.business.address.street}
              onChange={(e) => changeBusinessAddress("street", e)}
            />
          </div>
          <div className="input">
            <span>App/Suite #</span>
            <input
              value={client.business.address.complement}
              onChange={(e) => changeBusinessAddress("complement", e)}
            />
          </div>
        </div>
        <div className="field">
          <div className="input">
            <span>Country</span>
            <select
              disabled={
                businessCountries && businessCountries.length && edit
                  ? false
                  : true
              }
              onChange={(e) => {
                setBusinessAddressCountry(e.target.value);
                const countryName = [...businessCountries].filter(
                  (country) => country.iso2 === e.target.value
                )[0].name;
                changeBusinessAddress("country", countryName);
              }}
            >
              {businessCountries.map((country) => {
                return (
                  <option
                    selected={
                      [...businessCountries].filter(
                        (country) =>
                          country.name === client.business.address.country
                      )[0].iso2 === country.iso2
                    }
                    value={country.iso2}
                  >
                    {country.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="input">
            <span>Zip Code</span>
            <input
              value={client.business.address.zipCode}
              onChange={(e) => changeBusinessAddress("zipCode", e)}
            />
          </div>
        </div>
        <div className="field">
          <div className="input">
            <span>State</span>
            <select
              disabled={
                businessStates && businessStates.length && edit ? false : true
              }
              onChange={(e) => {
                setBusinessAddressState(e.target.value);
                const stateName = [...businessStates].filter(
                  (state) => state.iso2 === e.target.value
                )[0].name;
                changeBusinessAddress("state", stateName);
              }}
              // value={
              //   [...businessStates].filter(
              //     (state) => state.name === client.business.address.state
              //   )[0].iso2
              // }
            >
              {businessStates.map((state) => {
                return (
                  <option
                    selected={
                      [...businessStates].filter((state) => {
                        return state.name === client.business.address.state;
                      })[0].iso2 === state.iso2
                    }
                    value={state.iso2}
                  >
                    {state.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="input">
            <span>City</span>
            <select
              disabled={
                businessCities && businessCities.length && edit ? false : true
              }
              onChange={(e) => {
                setBusinessAddressCity(e.target.value);
                const cityName = [...businessCities].filter(
                  (city) => city.name === e.target.value
                )[0].name;
                changeBusinessAddress("city", cityName);
              }}
            >
              {businessCities.map((city) => {
                return (
                  <option
                    selected={
                      [...businessCities].filter(
                        (city) => city.name === client.business.address.city
                      )[0].name === city.name
                    }
                    value={city.name}
                  >
                    {city.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="bio">
        <span className="title">Company Bio</span>
        <input
          value={client.business.bio}
          onChange={(e) => changeBusinessAttribute("bio", e)}
        />
      </div>
      <div className="business-adress">
        <span className="title">Billing Address</span>
        <div className="field">
          <div className="input">
            <span>Street</span>
            <input
              value={client.billingAddress.street}
              onChange={(e) => changeAddress("street", e)}
            />
          </div>
          <div className="input">
            <span>App/Suite #</span>
            <input
              value={client.billingAddress.complement}
              onChange={(e) => changeAddress("complement", e)}
            />
          </div>
        </div>
        <div className="field">
          <div className="input">
            <span>Country</span>
            <select
              disabled={
                billingCountries && billingCountries.length && edit
                  ? false
                  : true
              }
              onChange={(e) => {
                setBillingAddressCountry(e.target.value);
                const countryName = [...billingCountries].filter(
                  (country) => country.iso2 === e.target.value
                )[0].name;
                changeAddress("country", countryName);
              }}
            >
              {billingCountries.map((country) => {
                return (
                  <option
                    selected={
                      [...billingCountries].filter(
                        (country) =>
                          country.name === client.billingAddress.country
                      )[0].iso2 === country.iso2
                    }
                    value={country.iso2}
                  >
                    {country.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="input">
            <span>Zip Code</span>
            <input
              value={client.billingAddress.zipCode}
              onChange={(e) => changeAddress("zipCode", e)}
            />
          </div>
        </div>
        <div className="field">
          <div className="input">
            <span>State</span>
            <select
              disabled={
                billingStates && billingStates.length && edit ? false : true
              }
              onChange={(e) => {
                setBillingAddressState(e.target.value);
                const stateName = [...billingStates].filter(
                  (state) => state.iso2 === e.target.value
                )[0].name;
                changeAddress("state", stateName);
              }}
            >
              {billingStates.map((state) => {
                return (
                  <option
                    selected={
                      [...billingStates].filter(
                        (state) => state.name === client.billingAddress.state
                      )[0].iso2 === state.iso2
                    }
                    value={state.iso2}
                  >
                    {state.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="input">
            <span>City</span>
            {/* <input
              value={client.billingAddress.state}
              onChange={(e) => changeAddress("state", e)}
            /> */}
            <select
              disabled={
                billingCities && billingCities.length && edit ? false : true
              }
              onChange={(e) => {
                setBillingAddressCity(e.target.value);
                const cityName = [...billingCities].filter(
                  (city) => city.name === e.target.value
                )[0].name;
                changeAddress("city", cityName);
              }}
            >
              {billingCities.map((city) => {
                return (
                  <option
                    selected={
                      [...billingCities].filter(
                        (city) => city.name === client.billingAddress.city
                      )[0].name === city.name
                    }
                    value={city.name}
                  >
                    {city.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <div className="bio">
        <span className="title">Personal Bio</span>
        <input value={client.bio} onChange={(e) => changeAttribute("bio", e)} />
      </div>
      <div className="networking-info">
        <span className="title">Networking Info</span>
        <div className="networking-content">
          {networks.map((network) => {
            return (
              <div className="social-network">
                <div className="name-icon">
                  <img src={network.img} />
                  <span style={{ marginLeft: ".5em" }}>{network.name}</span>
                </div>
                <input
                  readOnly={!editNetwork}
                  onChange={(e) => {
                    changeNetwork(network.id, e);
                  }}
                  value={
                    client.socialMedias.filter(
                      (media) => media.type === network.id
                    ) &&
                    client.socialMedias.filter(
                      (media) => media.type === network.id
                    ).length
                      ? client.socialMedias.filter(
                          (media) => media.type === network.id
                        )[0].account
                      : ""
                  }
                  className="data"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default AdminClient;
