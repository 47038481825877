import { AiOutlineMail, AiFillLinkedin, AiFillInstagram, AiFillFacebook } from 'react-icons/ai'
import { useNavigate } from 'react-router'
import LogoLanding from '../../assets/landing/logo-landing.png'
import BlurredBg from '../../assets/landing/bg-blurred.png'
import './Portfolio.scss'
import Porfolio1 from '../../assets/landing/portfolio/portfolio-1.png'
import Porfolio2 from '../../assets/landing/portfolio/portfolio-2.png'
import Porfolio3 from '../../assets/landing/portfolio/portfolio-3.png'
import Porfolio4 from '../../assets/landing/portfolio/portfolio-4.png'
import Porfolio5 from '../../assets/landing/portfolio/portfolio-5.png'
import Porfolio6 from '../../assets/landing/portfolio/portfolio-6.png'
import Porfolio7 from '../../assets/landing/portfolio/portfolio-7.png'
import Porfolio8 from '../../assets/landing/portfolio/portfolio-8.png'

const Portfolio = () => {
    const navigate = useNavigate()

    return (
        <div className="portfolio-page">
            <div className='nav-bar'>
                <div className='nav-bar-content'>
                    <img onClick={() => navigate('../home')} src={LogoLanding} />
                </div>
            </div>
            <img className='blurred-bg' src={BlurredBg} />
            <div className='landing-page-content'>
                <span className='main-title'>Portfolio</span>
                <span className='sub-title'>Check below some of our best works</span>
                <div className='portfolio-items'>
                    <img src={Porfolio1}/>
                    <img src={Porfolio2}/>
                    <img src={Porfolio3}/>
                    <img src={Porfolio4}/>
                    <img src={Porfolio5}/>
                    <img src={Porfolio6}/>
                    <img src={Porfolio7}/>
                    <img src={Porfolio8}/>
                    <img src={Porfolio1}/>
                    <img src={Porfolio2}/>
                    <img src={Porfolio3}/>
                    <img src={Porfolio4}/>
                    <img src={Porfolio5}/>
                    <img src={Porfolio6}/>
                    <img src={Porfolio7}/>
                    <img src={Porfolio8}/>
                    <img src={Porfolio1}/>
                    <img src={Porfolio2}/>
                    <img src={Porfolio3}/>
                    <img src={Porfolio4}/>
                    <img src={Porfolio5}/>
                    <img src={Porfolio6}/>
                    <img src={Porfolio7}/>
                    <img src={Porfolio8}/>
                </div>
                <button className='see-more'>See More</button>
                <div className='contact-us'>
                    <div className='left'>
                        <img src={LogoLanding} />
                        <span className='contact-title'>Reach Us</span>
                        <div className='email'>
                            <AiOutlineMail />
                            <span>contact@markstudioshub.com</span>
                        </div>
                        <div className='icons'>
                            <AiFillFacebook size={20} />
                            <AiFillInstagram size={20} />
                            <AiFillLinkedin size={20} />
                        </div>
                        <span className='footnote'>Copyright © 2022 | Mark Studios | All Rights Reserved</span>
                    </div>
                    <div className='right'>
                        <span className='contact-title'>Leave a Message</span>
                        <div className='inputs'>
                            <div className='item'>
                                <span>Name</span>
                                <input />
                            </div>
                            <div className='item'>
                                <span>Email</span>
                                <input />
                            </div>
                        </div>
                        <div className='inputs'>
                            <div className='item'>
                                <span>Phone Number</span>
                                <input />
                            </div>
                            <div className='item'>
                                <span>Instagram Account</span>
                                <input />
                            </div>
                        </div>
                        <div className='item'>
                            <span>Instagram Account</span>
                            <input />
                        </div>
                        <button>Send Message</button>
                        <span className='footnote-right'>Copyright © 2022 | Mark Studios | All Rights Reserved</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Portfolio