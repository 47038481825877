import { BsCheck } from "react-icons/bs";
import { useEffect, useState } from "react";
import { PackageManagementService } from "../../../../services/package-management.service";
import "./Packages.scss";
import Loading from "../../../loading/Loading";
import Video from "../../../../assets/video-types/video.png";
import Time from "../../../../assets/video-types/time.png";
import Clock from "../../../../assets/video-types/clock.png";
import Thumbnail from "../../../../assets/video-types/thumbnail.png";
import Banner from "../../../../assets/video-types/banner.png";
import Logo from "../../../../assets/video-types/logo.png";
import ConsultationCall from "../../../../assets/video-types/consultation-call.png";
import Outro from "../../../../assets/video-types/outro.png";
import Intro from "../../../../assets/video-types/intro.png";

const AdminPackages = () => {
  const [packages, setPackages] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [editingPackages, setEditingPackages] = useState(false);
  const [editingAddOns, setEditingAddOns] = useState(false);

  useEffect(() => {
    const getPackagesAndAddOns = async () => {
      PackageManagementService.list((data) => {
        const temp = data.packages[1];
        data.packages[1] = data.packages[0];
        data.packages[0] = temp;
        setPackages(data.packages);
        setAddOns(data.addOns);
      });
    };

    getPackagesAndAddOns();
  }, []);

  const getTime = (minutes) => {
    return `${minutes >= 60 ? Math.floor(minutes / 60) : minutes} ${
      minutes >= 60 ? "hours" : "minutes"
    }`;
  };

  const savePackage = () => {
    PackageManagementService.savePackages(
      {
        packages,
      },
      () => {
        setEditingPackages(false);
      }
    );
  };

  const saveAddOns = () => {
    PackageManagementService.saveAddOns(
      {
        addOns,
      },
      () => {
        setEditingAddOns(false);
      }
    );
  };

  const changePackageValue = (field, newValue, id) => {
    const newPkg = [...packages].filter((pkg) => pkg.id === id)[0];
    newPkg[field] = newValue;

    const newPackages = [...packages].map((pkg) => {
      if (pkg.id === id) {
        pkg = newPkg;
      }

      return pkg;
    });

    setPackages(newPackages);
  };

  const changeAddOnCost = (index, newCost) => {
    const newAddOns = [...addOns].map((addOn, i) => {
      if (i === index) {
        addOn.cost = newCost;
      }

      return addOn;
    });

    setAddOns(newAddOns);
  };

  return packages && addOns ? (
    <div className="admin-layout-packages-page">
      <div className="white-container">
        <div className="title">
          <span>Current Package Offerings</span>
          {!editingPackages ? (
            <span className="edit" onClick={() => setEditingPackages(true)}>
              Edit
            </span>
          ) : (
            <span className="edit" onClick={() => savePackage()}>
              Save
            </span>
          )}
        </div>
        {!editingPackages ? (
          <div className="packages">
            {packages.map((pkg) => {
              return (
                <div className="package">
                  <div className="package-header">
                    <span className="name">{pkg.title}</span>
                    <span className="videos">
                      {pkg.videos} videos per month
                    </span>
                  </div>
                  <div className="price-overall">
                    <span className="price">
                      ${pkg.price}
                      <span className="month">/mo</span>
                    </span>
                    {pkg.savings ? (
                      <span className="savings">Save ${pkg.savings}</span>
                    ) : null}
                  </div>
                  <div className="features">
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      {pkg.runningVideo ? (
                        <span>
                          Up to {getTime(pkg.runningVideo)} of running time per
                          video
                        </span>
                      ) : (
                        <span>Unlimited running video</span>
                      )}
                    </div>
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      {pkg.rawFootage ? (
                        <span>
                          Up to {getTime(pkg.rawFootage)} of raw provided
                          footage per video
                        </span>
                      ) : (
                        <span>Unlimited provided footage</span>
                      )}
                    </div>
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      <span>
                        {pkg.deliveryTime - 1}-{pkg.deliveryTime} days delivery
                        guaranteed
                      </span>
                    </div>
                    {pkg.thumbnails && pkg.thumbnails > 0 ? (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>{pkg.thumbnails} thumbnails</span>
                      </div>
                    ) : pkg.thumbnails === 0 ? null : (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>Unlimited thumbnails</span>
                      </div>
                    )}
                    {pkg.outro && pkg.intro && pkg.banner ? (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>
                          Free Brand Identity Pack with Intro+Outro+Banner
                        </span>
                      </div>
                    ) : (
                      <>
                        {pkg.intro && pkg.intro > 0 ? (
                          <div className="feature">
                            <BsCheck size={25} color="#2A9DF4"></BsCheck>
                            <span>{pkg.intro} free Personalized Intro</span>
                          </div>
                        ) : pkg.intro === 0 ? null : (
                          <div className="feature">
                            <BsCheck size={25} color="#2A9DF4"></BsCheck>
                            <span>Unlimited Intros</span>
                          </div>
                        )}
                        {pkg.outro ? (
                          <div className="feature">
                            <BsCheck size={25} color="#2A9DF4"></BsCheck>
                            <span>{pkg.outro} free Personalized Outro</span>
                          </div>
                        ) : pkg.outro === 0 ? null : (
                          <div className="feature">
                            <BsCheck size={25} color="#2A9DF4"></BsCheck>
                            <span>Unlimited Outros</span>
                          </div>
                        )}
                        {pkg.banner ? (
                          <div className="feature">
                            <BsCheck size={25} color="#2A9DF4"></BsCheck>
                            <span>{pkg.banner} free Personalized Banner</span>
                          </div>
                        ) : pkg.banner === 0 ? null : (
                          <div className="feature">
                            <BsCheck size={25} color="#2A9DF4"></BsCheck>
                            <span>Unlimited Banners</span>
                          </div>
                        )}
                      </>
                    )}
                    {pkg.logo ? (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>{pkg.logo} free Personalized Logo</span>
                      </div>
                    ) : pkg.logo === 0 ? null : (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>Unlimited Logos</span>
                      </div>
                    )}
                    {pkg.consultationCall ? (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>
                          {pkg.consultationCall} free 15 min Consultation Call
                        </span>
                      </div>
                    ) : pkg.consultationCall === 0 ? null : (
                      <div className="feature">
                        <BsCheck size={25} color="#2A9DF4"></BsCheck>
                        <span>Unlimited 15 min Consultation Calls</span>
                      </div>
                    )}
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      <span>Unlimited Revisions</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="packages">
            {packages.map((pkg) => {
              return (
                <div className="package">
                  <div className="package-header">
                    <span className="name">{pkg.title}</span>
                    <span className="videos">
                      {pkg.videos} videos per month
                    </span>
                  </div>
                  <div className="price-overall">
                    <div className="input-row">
                      <span>Price per month</span>
                      <input
                        type={"number"}
                        value={pkg.price}
                        placeholder="$"
                        onChange={(e) => {
                          changePackageValue(
                            "price",
                            parseInt(e.target.value),
                            pkg.id
                          );
                        }}
                      />
                    </div>
                    <div className="input-row">
                      <span>Savings per month</span>
                      <input
                        type={"number"}
                        value={pkg.savings}
                        placeholder="$"
                        onChange={(e) => {
                          changePackageValue(
                            "savings",
                            parseInt(e.target.value),
                            pkg.id
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="features">
                    <span className="editing-title">Features</span>
                    <div className="feature-input-row">
                      <span>Running Video Time (Minutes)</span>
                      <div className="input-checkbox">
                        <input
                          type={"number"}
                          value={
                            pkg.runningVideo !== null
                              ? pkg.runningVideo
                              : "Unlimited"
                          }
                          placeholder="Time in Minutes"
                          onChange={(e) => {
                            changePackageValue(
                              "runningVideo",
                              parseInt(e.target.value),
                              pkg.id
                            );
                          }}
                        />
                        <div className="checkbox">
                          <span>Unlimited?</span>
                          <input
                            checked={pkg.runningVideo === null}
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                changePackageValue(
                                  "runningVideo",
                                  null,
                                  pkg.id
                                );
                              } else {
                                changePackageValue("runningVideo", 0, pkg.id);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="feature-input-row">
                      <span>Raw Footage Time (Minutes)</span>
                      <div className="input-checkbox">
                        <input
                          type={"number"}
                          value={
                            pkg.rawFootage !== null
                              ? pkg.rawFootage
                              : "Unlimited"
                          }
                          placeholder="Time in Minutes"
                          onChange={(e) => {
                            changePackageValue(
                              "rawFootage",
                              parseInt(e.target.value),
                              pkg.id
                            );
                          }}
                        />
                        <div className="checkbox">
                          <span>Unlimited?</span>
                          <input
                            checked={pkg.rawFootage === null}
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                changePackageValue("rawFootage", null, pkg.id);
                              } else {
                                changePackageValue("rawFootage", 0, pkg.id);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="feature-input-row">
                      <span>Maximum Delivery Time (Days)</span>
                      <div className="input-checkbox">
                        <input
                          type={"number"}
                          value={pkg.deliveryTime}
                          placeholder="Time in Days"
                          onChange={(e) => {
                            changePackageValue(
                              "deliveryTime",
                              parseInt(e.target.value),
                              pkg.id
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="feature-input-row">
                      <span>Number of Thumbnails</span>
                      <div className="input-checkbox">
                        <input
                          type={"number"}
                          value={
                            pkg.thumbnails !== null
                              ? pkg.thumbnails
                              : "Unlimited"
                          }
                          placeholder="Number of Thumbnails"
                          onChange={(e) => {
                            changePackageValue(
                              "thumbnails",
                              parseInt(e.target.value),
                              pkg.id
                            );
                          }}
                        />
                        <div className="checkbox">
                          <span>Unlimited?</span>
                          <input
                            type="checkbox"
                            checked={pkg.thumbnails === null}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changePackageValue("thumbnails", null, pkg.id);
                              } else {
                                changePackageValue("thumbnails", 0, pkg.id);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="feature-input-row">
                      <span>Number of Banners</span>
                      <div className="input-checkbox">
                        <input
                          type={"number"}
                          value={pkg.banner !== null ? pkg.banner : "Unlimited"}
                          placeholder="Number of Banners"
                          onChange={(e) => {
                            changePackageValue(
                              "banner",
                              parseInt(e.target.value),
                              pkg.id
                            );
                          }}
                        />
                        <div className="checkbox">
                          <span>Unlimited?</span>
                          <input
                            type="checkbox"
                            checked={pkg.banner === null}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changePackageValue("banner", null, pkg.id);
                              } else {
                                changePackageValue("banner", 0, pkg.id);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="feature-input-row">
                      <span>Number of Intros</span>
                      <div className="input-checkbox">
                        <input
                          type={"number"}
                          value={pkg.intro !== null ? pkg.intro : "Unlimited"}
                          placeholder="Number of Intros"
                          onChange={(e) => {
                            changePackageValue(
                              "intro",
                              parseInt(e.target.value),
                              pkg.id
                            );
                          }}
                        />
                        <div className="checkbox">
                          <span>Unlimited?</span>
                          <input
                            type="checkbox"
                            checked={pkg.intro === null}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changePackageValue("intro", null, pkg.id);
                              } else {
                                changePackageValue("intro", 0, pkg.id);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="feature-input-row">
                      <span>Number of Outros</span>
                      <div className="input-checkbox">
                        <input
                          type={"number"}
                          value={pkg.outro !== null ? pkg.outro : "Unlimited"}
                          placeholder="Number of Outros"
                          onChange={(e) => {
                            changePackageValue(
                              "outro",
                              parseInt(e.target.value),
                              pkg.id
                            );
                          }}
                        />
                        <div className="checkbox">
                          <span>Unlimited?</span>
                          <input
                            type="checkbox"
                            checked={pkg.outro === null}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changePackageValue("outro", null, pkg.id);
                              } else {
                                changePackageValue("outro", 0, pkg.id);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="feature-input-row">
                      <span>Number of Logos</span>
                      <div className="input-checkbox">
                        <input
                          type={"number"}
                          value={pkg.logo !== null ? pkg.logo : "Unlimited"}
                          placeholder="Number of Logos"
                          onChange={(e) => {
                            changePackageValue(
                              "logo",
                              parseInt(e.target.value),
                              pkg.id
                            );
                          }}
                        />
                        <div className="checkbox">
                          <span>Unlimited?</span>
                          <input
                            type="checkbox"
                            checked={pkg.logo === null}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changePackageValue("logo", null, pkg.id);
                              } else {
                                changePackageValue("logo", 0, pkg.id);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="feature-input-row">
                      <span>Number of Consultation Calls</span>
                      <div className="input-checkbox">
                        <input
                          type={"number"}
                          value={
                            pkg.consultationCall !== null
                              ? pkg.consultationCall
                              : "Unlimited"
                          }
                          placeholder="Number of Consultation Calls"
                          onChange={(e) => {
                            changePackageValue(
                              "consultationCall",
                              parseInt(e.target.value),
                              pkg.id
                            );
                          }}
                        />
                        <div className="checkbox">
                          <span>Unlimited?</span>
                          <input
                            type="checkbox"
                            checked={pkg.consultationCall === null}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changePackageValue(
                                  "consultationCall",
                                  null,
                                  pkg.id
                                );
                              } else {
                                changePackageValue(
                                  "consultationCall",
                                  0,
                                  pkg.id
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="white-container">
        <div className="title">
          <span>Add-Ons</span>
          {!editingAddOns ? (
            <span className="edit" onClick={() => setEditingAddOns(true)}>
              Edit
            </span>
          ) : (
            <span className="edit" onClick={() => saveAddOns()}>
              Save
            </span>
          )}
        </div>
        {editingAddOns ? (
          <div className="add-ons">
            {addOns.map((addOn, index) => {
              return (
                <div className="add-on">
                  {addOn.type === "Video" ? (
                    <img src={Video} alt="add-on-symbol" />
                  ) : addOn.type === "Thumbnail" ? (
                    <img src={Thumbnail} alt="add-on-symbol" />
                  ) : addOn.type === "Logo" ? (
                    <img src={Logo} alt="add-on-symbol" />
                  ) : addOn.type === "Banner Design" ? (
                    <img src={Banner} alt="add-on-symbol" />
                  ) : addOn.type === "Consultation Call" ? (
                    <img src={ConsultationCall} alt="add-on-symbol" />
                  ) : addOn.type === "Outro" ? (
                    <img src={Outro} alt="add-on-symbol" />
                  ) : addOn.type === "Intro" ? (
                    <img src={Intro} alt="add-on-symbol" />
                  ) : addOn.type === "Raw Footage Time" ? (
                    <img src={Time} alt="add-on-symbol" />
                  ) : (
                    <img src={Clock} alt="add-on-symbol" />
                  )}
                  <div className="details">
                    <span className="type">{addOn.type}</span>
                    <div className="cost-row">
                      <span>$</span>
                      <input
                        type={"number"}
                        value={addOn.cost}
                        placeholder="Price ($)"
                        onChange={(e) => {
                          changeAddOnCost(index, parseInt(e.target.value));
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="add-ons">
            {addOns.map((addOn) => {
              return (
                <div className="add-on">
                  {addOn.type === "Video" ? (
                    <img src={Video} alt="add-on-symbol" />
                  ) : addOn.type === "Thumbnail" ? (
                    <img src={Thumbnail} alt="add-on-symbol" />
                  ) : addOn.type === "Logo" ? (
                    <img src={Logo} alt="add-on-symbol" />
                  ) : addOn.type === "Banner Design" ? (
                    <img src={Banner} alt="add-on-symbol" />
                  ) : addOn.type === "Consultation Call" ? (
                    <img src={ConsultationCall} alt="add-on-symbol" />
                  ) : addOn.type === "Outro" ? (
                    <img src={Outro} alt="add-on-symbol" />
                  ) : addOn.type === "Intro" ? (
                    <img src={Intro} alt="add-on-symbol" />
                  ) : addOn.type === "Raw Footage Time" ? (
                    <img src={Time} alt="add-on-symbol" />
                  ) : (
                    <img src={Clock} alt="add-on-symbol" />
                  )}
                  <div className="details">
                    <span className="type">{addOn.type}</span>
                    <span className="cost">${addOn.cost}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default AdminPackages;
