import "./CheckEmail.scss";
import ConfirmEmail from "../../../../assets/auth/confirm-email.png";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import { useEffect } from "react";

const GeneralAuthCheckEmail = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [setIsTermsOfServiceOpen, setIsPrivacyPolicyOpen] = useOutletContext();
  return (
    <div className="auth-general-six-digits">
      <div className="login-box">
        <span className="title">Check your e-mail</span>
        <span className="subtitle">
          We have sent you a verification email to{" "}
          <b>{state.email}. Click and follow the link.</b>
        </span>
        <div className="login-info">
          <img src={ConfirmEmail} />
        </div>
        <button className="login" onClick={() => navigate("/auth/sign-in")}>
          Continue
        </button>
      </div>
      <div className="footer">
        {/* <span className="already-have">
          Already have an account? <b>Sign in</b>
        </span> */}
        <div className="terms-privacy-policy">
          <span onClick={() => setIsTermsOfServiceOpen(true)}>
            Terms of use
          </span>
          <span onClick={() => setIsPrivacyPolicyOpen(true)}>
            Privacy Policy
          </span>
          <span>Support</span>
        </div>
      </div>
    </div>
  );
};

export default GeneralAuthCheckEmail;
