import { Link, useNavigate } from 'react-router-dom'
import NotFoundImage from '../../../../assets/404-customer.png'
import './NotFound.scss'

const CustomerNotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="admin-layout-not-found-page">
            <img src={NotFoundImage} />
            <span>Page not found!</span>
            <div className='actions'>
                <Link to={'./dashboard'} style={{ textDecoration: 'none' }}>
                    <button className='dashboard'>Return to Dashboard</button>
                </Link>
                <button onClick={() => {
                    navigate(-1)
                }}>Back to previous page</button>
            </div>
        </div>
    )
}

export default CustomerNotFound