import './ProgressBar.scss'

const ProgressBar = ({completed, color='#2A9DF4', width="164px", backgroundColor = "#D6EFFF"}) => {
    return (
        <div className="progress-bar-component" style={{width, backgroundColor}}>
            <div className="progress-bar-filler" style={{width: `${completed}%`, backgroundColor: color}}>
            </div>
        </div>
    )
}

export default ProgressBar