import AllEditors from "../../../../components/editors/allEditors/AllEditors";
import "./Editors.scss";
import { useEffect, useState } from "react";
import NewEditors from "../../../../components/editors/newEditors/NewEditors";
import { editorService } from "../../../../services/editor.service";
import Loading from "../../../loading/Loading";
import { useOutletContext } from "react-router";

const AdminEditors = () => {
  const [currentPage, setCurrentPage] = useState("All Editors");
  const [editors, setEditors] = useState([]);
  const [editorsCount, setEditorsCount] = useState(0);
  const [addQuestion, setAddQuestion, user, setUser, addEditor] =
    useOutletContext();

  useEffect(() => {
    getEditors(1);
  }, [currentPage]);

  useEffect(() => {
    getEditors(1);
  }, [addEditor]);

  const getEditors = (page) => {
    let type = "all";
    if (currentPage === "New Editors") {
      type = "new";
    }

    editorService.getAllEditors(page, type, (data) => {
      setEditors(data.editors);
      setEditorsCount(data.count);
    });
  };

  const changeAcceptance = (id, acceptance, index) => {
    editorService.changeAcceptance(id, acceptance, (data) => {
      getEditors(1);
    });
  };

  return (
    <div className="admin-layout-editors-page">
      <div className="header">
        <div className="types">
          <div
            onClick={() => setCurrentPage("All Editors")}
            className={`${currentPage === "All Editors" ? "active" : ""}`}
          >
            All Editors
          </div>
          <div
            onClick={() => setCurrentPage("New Editors")}
            className={`${currentPage === "New Editors" ? "active" : ""}`}
          >
            New Editors
          </div>
        </div>
      </div>
      {!editors || (!editors.length && <h2>No Editors to Show</h2>)}
      {editors && editors.length && currentPage === "All Editors" ? (
        <AllEditors
          getEditors={getEditors}
          editors={editors}
          count={editorsCount}
        />
      ) : null}
      {editors && editors.length && currentPage === "New Editors" ? (
        <NewEditors
          changeAcceptance={changeAcceptance}
          getEditors={getEditors}
          editors={editors}
          count={editorsCount}
        />
      ) : null}
    </div>
  );
};

export default AdminEditors;
