import "./BasicDetails.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiFillCheckCircle } from "react-icons/ai";

const BasicDetails = ({
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  order,
  user,
}) => {
  const [title, setTitle] = useState("");
  const [deliveryTime, setDeliveryTime] = useState(1);
  const [type, setType] = useState("");
  const [postLocation, setPostLocation] = useState([]);
  const [names, setNames] = useState("");
  const [videoElements, setVideoElements] = useState([]);
  const [other, setOther] = useState(false);
  const [otherText, setOtherText] = useState("");
  const [error, setError] = useState("");
  const [checkboxes, setCheckboxes] = useState([
    "Facebook",
    "YouTube",
    "Twitter",
    "LinkedIn",
    "Website",
    "Instagram",
    "Other",
  ]);

  useEffect(() => {
    if (
      order.basicDetails.postLocation &&
      order.basicDetails.postLocation.length
    ) {
      setPostLocation(order.basicDetails.postLocation);
    }

    if (order.basicDetails.type !== "") {
      setType(order.basicDetails.type);
    }

    if (order.basicDetails.names !== null) {
      setNames(order.basicDetails.names);
    }

    if (order.basicDetails.title !== null) {
      setTitle(order.basicDetails.title);
    }

    setDeliveryTime(order.basicDetails.deliveryTime);
  }, [order]);

  // const checkboxes = [
  //   "Text",
  //   "Jump Cuts",
  //   "Lower Thirds",
  //   "Transitions",
  //   "Social Media Links",
  //   "Sound Effects",
  //   "Sound Design/Improvements",
  //   "Color Correction/Color Grading",
  //   "If other, please specify",
  // ];

  const changeStep = (isStepNext) => {
    if (isStepNext) {
      if (validateFields()) {
        changeComponent("basicDetails", {
          title,
          deliveryTime,
          type,
          postLocation: postLocation.join(","),
          names: names.trim(),
          videoElements: `${videoElements.join(",")}${
            otherText.trim() !== "" ? `,${otherText}` : ""
          }`,
        });

        nextStep();
      } else {
        validate("Please check that all fields are filled");
      }
    } else {
      prevStep();
    }
  };

  const validateFields = () => {
    if (!title || title.trim() === "") {
      return false;
    }

    if (!deliveryTime || deliveryTime === 0) {
      return false;
    }

    if (!type || type.trim() === "") {
      return false;
    }

    if (!postLocation || !postLocation.length) {
      return false;
    }

    setPostLocation((postLocation) => postLocation.join(","));

    return true;
  };

  return (
    <div className="basic-details-new-order">
      <div className="inner-content">
        <div className="inputs">
          <div className="item">
            <span>Video Title</span>
            <input
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="item">
            <span>Delivery Time (in days)</span>
            {/* <input
              placeholder="24 hour delivery ($100 rush fee)"
              value={deliveryTime}
              type={"number"}
              onChange={(e) => setDeliveryTime(e.target.value)}
            /> */}
            <span className="text">
              {deliveryTime > 1
                ? `${deliveryTime - 1}-${deliveryTime} days for delivery`
                : "24-hour delivery"}
            </span>
            <div className="checkbox">
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    setDeliveryTime(1);
                  } else {
                    setDeliveryTime(user.package.deliveryTime + 1);
                  }
                }}
              />
              <span>Request a 24-hour delivery? ($100 rush fee)</span>
            </div>
          </div>
          <div className="item">
            <span>Type of video</span>
            <select value={type} onChange={(e) => setType(e.target.value)}>
              <option disabled selected value="">
                Select Type
              </option>
              <option value="Vlog">Vlog</option>
              <option value="Documentary">Documentary</option>
              <option value="Commercials & Ads">Commercials & Ads</option>
              <option value="Talking Head">Talking Head</option>
              <option value="Podcast">Podcast</option>
              <option value="Short">Short</option>
              <option value="Tiktok">Tiktok</option>
              <option value="Instagram Reels">Instagram Reels</option>
              <option value="Ad">Ad</option>
              <option value="Travel">Travel</option>
              <option value="Corporate">Corporate</option>
              <option value="Gaming">Gaming</option>
              <option value="Short Film">Short Film</option>
              <option value="Real Estate">Real Estate</option>
            </select>
          </div>
          <div className="item">
            <span>Where do you plan on posting this?</span>
            {checkboxes.map((checkbox) => {
              return (
                <div className="radio-box">
                  <input
                    id={checkbox}
                    name={checkbox}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setPostLocation([...postLocation, checkbox]);
                      } else {
                        const arr = [...postLocation].filter(
                          (post) => post !== checkbox
                        );
                        setPostLocation(arr);
                      }
                    }}
                  />
                  <label for={checkbox} className="legend">
                    {checkbox}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="item">
            <span>
              What are the names of everyone that appears in the video?
            </span>
            <input
              placeholder="Add name(s) here"
              value={names}
              onChange={(e) => setNames(e.target.value)}
            />
            <span className="commas">
              Add multiple names seperated by commas
            </span>
          </div>
        </div>
        {/* <div className="checkboxes">
          <span className="title">Video Elements</span>
          {checkboxes.map((checkbox) => {
            return (
              <div className="item">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (checkbox === "If other, please specify") {
                      setOtherText("");
                      if (e.target.checked) {
                        setOther(true);
                      } else {
                        setOther(false);
                      }
                    } else {
                      if (e.target.checked) {
                        setVideoElements([...videoElements, checkbox]);
                      } else {
                        const arr = [...videoElements].filter(
                          (item) => item !== checkbox
                        );
                        setVideoElements(arr);
                      }
                    }
                  }}
                />
                <span className="legend">{checkbox}</span>
                <AiOutlineQuestionCircle color="#979797" size={16} />
              </div>
            );
          })}
          {other ? (
            <input
              className="text-input"
              placeholder="What other editing do you need?"
              value={otherText}
              onChange={(e) => setOtherText(e.target.value)}
            />
          ) : null}
        </div> */}
      </div>
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}
        <button
          className="next"
          onClick={() => {
            changeStep(true);
          }}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default BasicDetails;
