import { useEffect, useState } from "react";
import "./PackageList.scss";
import { AiOutlineSetting } from "react-icons/ai";
import { BiPackage } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router";
import { BsCheck } from "react-icons/bs";
import { PackageManagementService } from "../../../../../services/package-management.service";

const CustomerTestPackageList = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const getPackagesAndAddOns = async () => {
      PackageManagementService.list((data) => {
        const temp = data.packages[1];
        data.packages[1] = data.packages[0];
        data.packages[0] = temp;
        setPackages(data.packages);
      });
    };

    getPackagesAndAddOns();
  }, []);

  const choosePackage = (userPackage) => {
    const obj = {
      order: userPackage,
      update: false,
    };

    obj.order.name = obj.order.title;
    obj.order.price = obj.order.price * 100;
    obj.order.nextPrice = obj.order.price;

    delete obj.order.title;
    delete obj.order.createdAt;
    delete obj.order.updatedAt;
    delete obj.order.savings;

    if (
      state &&
      state.update &&
      state.update !== null &&
      state.update === true
    ) {
      obj["update"] = true;
    }
    navigate("../add-ons", { state: obj });
  };

  const getTime = (minutes) => {
    return `${minutes >= 60 ? Math.floor(minutes / 60) : minutes} ${
      minutes >= 60 ? "hours" : "minutes"
    }`;
  };

  return (
    <div className="customer-test-layout-package-list-page">
      <div className="white-container">
        <div className="title">
          <span>Choose Package</span>
        </div>
        <div className="packages">
          {packages.map((pkg) => {
            return (
              <div className="package">
                <div className="package-header">
                  <span className="name">{pkg.title}</span>
                  <span className="videos">{pkg.videos} videos per month</span>
                </div>
                <div className="price-overall">
                  <span className="price">
                    ${pkg.price}
                    <span className="month">/mo</span>
                  </span>
                  {pkg.savings ? (
                    <span className="savings">Save ${pkg.savings}</span>
                  ) : (
                    <span style={{ visibility: "hidden" }}>Save</span>
                  )}
                </div>
                <button
                  className="choose-package"
                  onClick={() => {
                    choosePackage(pkg);
                  }}
                >
                  Choose Package
                </button>
                <div className="features">
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    {pkg.runningVideo ? (
                      <span>
                        Up to {getTime(pkg.runningVideo)} of running time per
                        video
                      </span>
                    ) : (
                      <span>Unlimited running video</span>
                    )}
                  </div>
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    {pkg.rawFootage ? (
                      <span>
                        Up to {getTime(pkg.rawFootage)} of raw provided footage
                        per video
                      </span>
                    ) : (
                      <span>Unlimited provided footage</span>
                    )}
                  </div>
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    <span>
                      {pkg.deliveryTime - 1}-{pkg.deliveryTime} days delivery
                      guaranteed
                    </span>
                  </div>
                  {pkg.thumbnails && pkg.thumbnails > 0 ? (
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      <span>{pkg.thumbnails} thumbnails</span>
                    </div>
                  ) : pkg.thumbnails === 0 ? null : (
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      <span>Unlimited thumbnails</span>
                    </div>
                  )}
                  {pkg.outro && pkg.intro && pkg.banner ? (
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      <span>
                        Free Brand Identity Pack with Intro+Outro+Banner
                      </span>
                    </div>
                  ) : (
                    <>
                      {pkg.intro && pkg.intro > 0 ? (
                        <div className="feature">
                          <BsCheck size={25} color="#2A9DF4"></BsCheck>
                          <span>{pkg.intro} free Personalized Intro</span>
                        </div>
                      ) : pkg.intro === 0 ? null : (
                        <div className="feature">
                          <BsCheck size={25} color="#2A9DF4"></BsCheck>
                          <span>Unlimited Intros</span>
                        </div>
                      )}
                      {pkg.outro ? (
                        <div className="feature">
                          <BsCheck size={25} color="#2A9DF4"></BsCheck>
                          <span>{pkg.outro} free Personalized Outro</span>
                        </div>
                      ) : pkg.outro === 0 ? null : (
                        <div className="feature">
                          <BsCheck size={25} color="#2A9DF4"></BsCheck>
                          <span>Unlimited Outros</span>
                        </div>
                      )}
                      {pkg.banner ? (
                        <div className="feature">
                          <BsCheck size={25} color="#2A9DF4"></BsCheck>
                          <span>{pkg.banner} free Personalized Banner</span>
                        </div>
                      ) : pkg.banner === 0 ? null : (
                        <div className="feature">
                          <BsCheck size={25} color="#2A9DF4"></BsCheck>
                          <span>Unlimited Banners</span>
                        </div>
                      )}
                    </>
                  )}
                  {pkg.logo ? (
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      <span>{pkg.logo} free Personalized Logo</span>
                    </div>
                  ) : pkg.logo === 0 ? null : (
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      <span>Unlimited Logos</span>
                    </div>
                  )}
                  {pkg.consultationCall ? (
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      <span>
                        {pkg.consultationCall} free 15 min Consultation Call
                      </span>
                    </div>
                  ) : pkg.consultationCall === 0 ? null : (
                    <div className="feature">
                      <BsCheck size={25} color="#2A9DF4"></BsCheck>
                      <span>Unlimited 15 min Consultation Calls</span>
                    </div>
                  )}
                  <div className="feature">
                    <BsCheck size={25} color="#2A9DF4"></BsCheck>
                    <span>Unlimited Revisions</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="actions">
          <button
            className="custom"
            onClick={() =>
              navigate("../custom-package", {
                state:
                  state &&
                  state.update &&
                  state.update !== null &&
                  state.update === true
                    ? { update: true }
                    : { update: false },
              })
            }
          >
            <AiOutlineSetting className="icon" />
            <span>Create Custom Package</span>
          </button>
          <button
            className="single"
            onClick={() =>
              navigate("../single-order", {
                state:
                  state &&
                  state.update &&
                  state.update !== null &&
                  state.update === true
                    ? { update: true }
                    : { update: false },
              })
            }
          >
            <BiPackage className="icon" />
            <span>Create Single Package</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerTestPackageList;
