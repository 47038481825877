import "./Login.scss";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useFormik } from "formik";
import { authService } from "../../../../services/auth.service";
import Cookies from "js-cookie";
import Swal from "sweetalert2/dist/sweetalert2";
import { Link } from "react-router-dom";

const AdminLogin = () => {
  const [isPasswordShown, setIsPasswordShows] = useState(false);
  const [setIsTermsOfServiceOpen, setIsPrivacyPolicyOpen] = useOutletContext();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      authService.login(
        { email: values.email, password: values.password },
        (data) => {
          Cookies.set("token", data.token);
          Swal.fire({
            title: "Success!",
            text: "Login Successful",
            icon: "success",
          }).then(() => {
            navigate(`../../${data.type}`);
          });
        }
      );
    },
  });

  return (
    <div className="auth-admin-login">
      <div className="login-box">
        <span className="title">Login to your account</span>
        <div className="login-info">
          <div className="input-box">
            <span className="input-title">Email Address</span>
            <input
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              type="email"
              placeholder="youremail@gmail.com"
            />
          </div>
          <div className="input-box">
            <span className="input-title">Password</span>
            <div className="password">
              <input
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                type={isPasswordShown ? "text" : "password"}
                placeholder="••••••••"
              />
              {isPasswordShown ? (
                <AiOutlineEye
                  onClick={() => setIsPasswordShows(!isPasswordShown)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => setIsPasswordShows(!isPasswordShown)}
                />
              )}
            </div>
          </div>
          <div className="remember-me-forgot-password">
            <div className="remember-me">
              <input type="checkbox" />
              <span>Remember me</span>
            </div>
            <Link to={"/auth/admin/recovery"} className="forgot-password">
              Forgot Password?
            </Link>
          </div>
        </div>
        <button onClick={formik.handleSubmit} className="login">
          Sign In
        </button>
      </div>
      <div className="footer">
        {/* <span className='already-have'>Already have an account? <b>Sign in</b></span> */}
        <div className="terms-privacy-policy">
          <span onClick={() => setIsTermsOfServiceOpen(true)}>
            Terms of use
          </span>
          <span onClick={() => setIsPrivacyPolicyOpen(true)}>
            Privacy Policy
          </span>
          <span>Support</span>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
