import logo from "./logo.svg";
import "./App.css";
import Router from "./Router";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyBqSZ7DKWqDd9gptpz11wXSO6y0mQcaPVs",
      authDomain: "mark-studios-616c0.firebaseapp.com",
      projectId: "mark-studios-616c0",
      storageBucket: "mark-studios-616c0.appspot.com",
      messagingSenderId: "863922433057",
      appId: "1:863922433057:web:395399334dfc51e71b8760",
    };

    firebase.initializeApp(firebaseConfig);
  }, []);

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
