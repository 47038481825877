import { useEffect, useState } from 'react'
import './Support.scss'
import { MdOutlineArrowForwardIos, MdKeyboardArrowDown } from "react-icons/md";
import Modal from 'react-modal';
import { useOutletContext } from 'react-router';
import SupportAdmin from '../../../../assets/support-admin.png'
import { AiOutlineClose, AiOutlineMore, AiOutlinePrinter } from 'react-icons/ai'
import { FiTrash2 } from 'react-icons/fi'
import { supportService } from '../../../../services/support.service';
import { useFormik } from 'formik';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.getElementById('root'));

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const AdminSupport = () => {
    const [addQuestion, setAddQuestion] = useOutletContext()
    const [currentQuestion, setCurrentQuestion] = useState('')
    const [currentQuestionTitle, setCurrentQuestionTitle] = useState('')
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(null)
    const [isQuestionShown, setIsQuestionShown] = useState(false)
    const [questions, setQuestions] = useState([])

    useEffect(() => {
        getQuestions()
    }, [])

    const getQuestions = () => {
        supportService.getAllQuestions((data) => {
            data = data.map((item) => {
                item.open = false
                return item
            })
            setQuestions(data)
        })
    }

    function closeModal() {
        setAddQuestion(false);
    }

    const questionFormik = useFormik({
        initialValues: {
            title: '',
            explanation: ''
        },
        onSubmit: values => {
            supportService.saveQuestion({
                title: values.title,
                explanation: values.explanation
            }, (data) => {
                getQuestions()
                questionFormik.resetForm()
            })
        }
    })

    return (
        <div className="admin-layout-support-page">
            <Modal
                isOpen={addQuestion}
                onRequestClose={closeModal}
                contentLabel="Admin Support Modal"
                className={getWindowDimensions().width < 450 ? "modal mobile" : "modal"}
                overlayClassName={getWindowDimensions().width < 450 ? "overlay-modal mobile" : "overlay-modal"}
            >
                <h4>Add New Question</h4>
                <div className='textInput'>
                    <span className='title-of-input'>Title</span>
                    <input id='title' name='title' onChange={questionFormik.handleChange} value={questionFormik.values.title} className='input-field' placeholder='Question' />
                </div>
                <div className='textInput'>
                    <span className='title-of-input'>Description</span>
                    <textarea id='explanation' name='explanation' onChange={questionFormik.handleChange} value={questionFormik.values.explanation} className='input-field desc' placeholder='Answer' />
                </div>
                <button onClick={() => {
                    questionFormik.handleSubmit()
                    setAddQuestion(false)
                }} style={{ width: 'fit-content', alignSelf: 'flex-end', marginTop: '1.5em', cursor: 'pointer', color: 'white', backgroundColor: '#2A9DF4', padding: '1em 2em', fontWeight: '500', border: 0, borderRadius: '25px' }}>
                    Submit
                </button>
            </Modal>
            <div className='questions'>
                {getWindowDimensions().width < 450 && <button onClick={() => {
                    setAddQuestion(true)
                }} style={{ border: 0, marginBottom: '1em', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', padding: '.5em 1em', color: 'white', backgroundColor: '#2A9DF4', borderRadius: '20px' }}>
                    Add Question
                </button>}
                {questions && questions.length ? (questions.map((question, index) => {
                    return (
                        <div className={`question ${currentQuestionNumber === index + 1 && getWindowDimensions().width > 450 ? 'active' : ''}`}>
                            <span>{index + 1} - {question.title}</span>
                            <div style={{ width: '30px', height: '30px', position: 'relative', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                <div onClick={() => {
                                    const obj = questions[index]
                                    obj.open = (!obj.open)
                                    setQuestions([
                                        ...questions.slice(0, index),
                                        obj,
                                        ...questions.slice(index + 1)
                                    ])
                                }} style={{ color: 'black', position: 'absolute', backgroundColor: '#F2F5FA', width: '30px', height: '30px', alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '1.2em', borderRadius: '7px', cursor: 'pointer' }}>
                                    <AiOutlineMore />
                                </div>
                                {question.open && <div style={{ borderRadius: '10px', backgroundColor: 'white', position: 'absolute', top: 40, right: 0, display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0 6px 40px 0 #7991AD4D', padding: '.3em' }}>
                                    <div onClick={() => {
                                        setCurrentQuestion(question.explanation)
                                        setCurrentQuestionTitle(question.title)
                                        setCurrentQuestionNumber(index + 1)
                                        if (getWindowDimensions().width < 450) {
                                            setIsQuestionShown(true)
                                        }
                                        const obj = questions[index]
                                        obj.open = false
                                        setQuestions([
                                            ...questions.slice(0, index),
                                            obj,
                                            ...questions.slice(index + 1)
                                        ])
                                    }} style={{ color: 'black', fontWeight: '600', padding: '0 .5em', marginRight: '.5em', backgroundColor: '#F4F9FD', height: '30px', flexWrap: 'nowrap', whiteSpace: 'nowrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '.8em', borderRadius: '7px', cursor: 'pointer' }}>
                                        Edit
                                    </div>
                                    <div style={{ color: 'black', backgroundColor: '#F4F9FD', width: '30px', height: '30px', alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '1.2em', borderRadius: '7px', cursor: 'pointer' }}>
                                        <AiOutlinePrinter />
                                    </div>
                                    <div onClick={() => {
                                        supportService.deleteQuestion(question.id, (data) => {
                                            getQuestions()
                                        })
                                    }} style={{ marginLeft: '.5em', backgroundColor: '#F6516023', width: '30px', height: '30px', alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '1.2em', borderRadius: '7px', cursor: 'pointer' }}>
                                        <FiTrash2 color="#E74C3C" />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    )
                })
                ) : <h2>No questions to show</h2>}
            </div>
            {getWindowDimensions().width > 450 && currentQuestionNumber !== null && <div className='explanation'>
                <div className='actions'>
                    <button onClick={() => {
                        const obj = questions[currentQuestionNumber - 1]
                        obj.open = false
                        setQuestions([
                            ...questions.slice(0, currentQuestionNumber - 1),
                            obj,
                            ...questions.slice(currentQuestionNumber - 1 + 1)
                        ])
                        setCurrentQuestionNumber(null)
                        setIsQuestionShown(false)
                    }}>
                        Cancel
                    </button>
                    <button className='save' onClick={() => {
                        const obj = questions[currentQuestionNumber - 1]
                        obj.open = false
                        setQuestions([
                            ...questions.slice(0, currentQuestionNumber - 1),
                            obj,
                            ...questions.slice(currentQuestionNumber - 1 + 1)
                        ])
                        setCurrentQuestionNumber(null)
                        setIsQuestionShown(false)
                        obj.explanation = currentQuestion
                        obj.title = currentQuestionTitle
                        supportService.saveQuestion(obj, (data) => {
                            getQuestions()
                        })
                    }}>
                        Save Changes
                    </button>
                </div>
                <input className='text input' value={currentQuestionTitle} onChange={(e) => setCurrentQuestionTitle(e.target.value)} />
                <textarea className='text' value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} />
            </div>}
            {getWindowDimensions().width < 450 && currentQuestionNumber !== null && isQuestionShown && <div className='explanation-mobile'>
                <div className="explanation-container">
                    <div className="explanation-header">
                        <span className="title">Question {currentQuestionNumber}</span>
                        <div className='actions'>
                            <button onClick={() => {
                                const obj = questions[currentQuestionNumber - 1]
                                obj.open = false
                                setQuestions([
                                    ...questions.slice(0, currentQuestionNumber - 1),
                                    obj,
                                    ...questions.slice(currentQuestionNumber - 1 + 1)
                                ])
                                setCurrentQuestionNumber(null)
                                setIsQuestionShown(false)
                            }}>
                                Cancel
                            </button>
                            <button className='save' onClick={() => {
                                const obj = questions[currentQuestionNumber - 1]
                                obj.open = false
                                setQuestions([
                                    ...questions.slice(0, currentQuestionNumber - 1),
                                    obj,
                                    ...questions.slice(currentQuestionNumber - 1 + 1)
                                ])
                                setCurrentQuestionNumber(null)
                                setIsQuestionShown(false)
                            }}>
                                Save Changes
                            </button>
                        </div>
                    </div>
                    <input className='explanation-body input' value={currentQuestionTitle} onChange={(e) => setCurrentQuestionTitle(e.target.value)} />
                    <textarea className='explanation-body' value={currentQuestion} onChange={(e) => setCurrentQuestion(e.target.value)} />
                </div>
            </div>}
        </div>
    )
}

export default AdminSupport