import { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router";
import { AiOutlineArrowLeft } from "react-icons/ai";
import "./Packages.scss";

const CustomerPackages = () => {
  const [askAdmin, setAskAdmin, setOrderTab, user] = useOutletContext();

  const navigate = useNavigate();
  const { state } = useLocation();
  const [prevPage, setPrevPage] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [prevPageState, setPrevPageState] = useState({});

  useEffect(() => {
    if (state) {
      if (state.finished) {
        if (state.order.name === "Custom") {
          setPrevPage("custom package");
          setPrevPageLink("/customer/packages/custom-package");
        } else if (state.order.name === "Single") {
          setPrevPage("/customer/packages/single-order");
          setPrevPageLink(-1);
        } else {
          setPrevPage("select package");
          setPrevPageLink("/customer/packages/select-package");
        }

        setPrevPageState({
          order: state.order ? state.order : {},
          finished: false,
          update: state.update ? state.update : false,
        });
      } else {
        setPrevPage("select package");
        setPrevPageLink("/customer/packages/select-package");
        setPrevPageState({ update: state.update ? state.update : false });
      }
    }
  }, [state]);

  return (
    <div className="customer-layout-packages-page">
      {!window.location.href.includes("select-package") &&
      !window.location.href.includes("my-package") ? (
        <div
          className="back"
          onClick={() => {
            navigate(prevPageLink, { state: prevPageState });
          }}
        >
          <AiOutlineArrowLeft />
          <span>Go to {prevPage}</span>
        </div>
      ) : null}
      <Outlet context={[user]} />
    </div>
  );
};

export default CustomerPackages;
