import "./OrderSummary.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { purchaseService } from "../../../../../services/package.service";
import Swal from "sweetalert2";
import Loading from "../../../../loading/Loading";
import moment from "moment";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";
import Visa from "../../../../../assets/visa.png";
import Master from "../../../../../assets/master.png";
import NeutralCard from "../../../../../assets/neutral-card.png";
import BgBlurred from "../../../../../assets/Bg-blurred.png";

const CustomerOrderSummary = () => {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [card, setCard] = useState({
    name: "",
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  const navigate = useNavigate();

  const changeAttribute = (attribute, value) => {
    const obj = JSON.parse(JSON.stringify(card));
    obj[attribute] = value;
    setCard(obj);
  };

  const validate = () => {
    console.log(card);
    if (!card.name) {
      return {
        success: false,
        message: "Add a valid name",
      };
    }

    if (
      card.number.replace(/ /g, "").length > 16 ||
      card.number.replace(/ /g, "").length < 15
    ) {
      return {
        success: false,
        message: "Add a valid card number",
      };
    }

    if (card.exp_month.length > 2 || card.exp_month.length === 0) {
      return {
        success: false,
        message: "Add a valid expiration month",
      };
    }

    if (card.exp_year.length !== 4 && card.exp_year.length !== 2) {
      return {
        success: false,
        message: "Add a valid expiration year",
      };
    }

    if (card.exp_year.length === 2) {
      changeAttribute("exp_year", `20${card.exp_year}`);
      return {
        success: true,
      };
    }

    if (card.cvc.length < 3) {
      return {
        success: false,
        message: "Add a valid CVV code",
      };
    }

    return {
      success: true,
    };
  };

  const purchase = () => {
    const validation = validate();
    if (!validation.success) {
      Swal.fire({
        title: "Purchase Failed!",
        text: validation.message,
        icon: "error",
      });
      setLoading(false);
      return;
    }

    const newCard = JSON.parse(JSON.stringify(card));
    if (newCard.number) {
      newCard.number = newCard.number.trim();
    }
    const newOrder = JSON.parse(JSON.stringify(state.order));
    if (newOrder.additionalPrice) {
      newOrder.additionalPrice = newOrder.additionalPrice / 100;
    }

    if (newOrder.id) {
      delete newOrder.id;
    }

    purchaseService.buyPackage(newOrder, newCard, (data) => {
      if (data && data.firstName) {
        navigate("../purchase-complete");
      } else {
        Swal.fire({
          title: "Purchase Failed!",
          text: data,
          icon: "error",
        }).then(() => {
          setLoading(false);
        });
      }
    });
  };

  const editPackage = () => {
    state.order.price = state.order.price / 100;
    if (state.order.name === "Custom") {
      navigate("../custom-package", { state: { order: state.order } });
    } else if (state.order.name === "Single") {
      navigate("../single-order", { state: { order: state.order } });
    } else {
      navigate("../select-package", { state: { order: state.order } });
    }
  };

  const formatCardNumber = (type) => {
    if (card.number && type === "blur") {
      let newCardNumber = JSON.parse(JSON.stringify(card)).number.split("");

      if (newCardNumber[0] === "3") {
        if (newCardNumber.length > 4) {
          newCardNumber.splice(4, 0, " ");
        }
        if (newCardNumber.length > 11) {
          newCardNumber.splice(11, 0, " ");
        }
      } else {
        if (newCardNumber.length > 4) {
          newCardNumber.splice(4, 0, " ");
        }
        if (newCardNumber.length > 9) {
          newCardNumber.splice(9, 0, " ");
        }
        if (newCardNumber.length > 14) {
          newCardNumber.splice(14, 0, " ");
        }
      }

      changeAttribute("number", newCardNumber.join(""));
    } else {
      let newCardNumber = JSON.parse(JSON.stringify(card)).number.split(" ");
      changeAttribute("number", newCardNumber.join(""));
    }
  };

  return state.order ? (
    <div
      className="customer-layout-order-summary-page"
      style={{ backgroundColor: "white" }}
    >
      {loading ? <LoadingUpload text="Finishing Purchase" /> : null}
      <div className="page-content">
        <img className="blurred-bg" src={BgBlurred} alt="blurred-background" />
        <div className="wrapper">
          <div className="middle-blocks">
            <div className="details">
              <span className="details-title">Payment Details</span>
              <div className="input-box">
                <span className="input-title">Card Information</span>
                <input
                  value={card.name}
                  onChange={(e) => changeAttribute("name", e.target.value)}
                  placeholder="Name on Card"
                />
                <div className="card_number">
                  <input
                    onBlur={() => formatCardNumber("blur")}
                    onFocus={() => formatCardNumber("focus")}
                    value={card.number}
                    onChange={(e) => changeAttribute("number", e.target.value)}
                    placeholder="Credit Card Number"
                    maxlength={16}
                  />
                  <img
                    alt="brand"
                    src={
                      card.number &&
                      card.number !== "" &&
                      card.number.length > 1
                        ? card.number.substring(0, 1) === "4"
                          ? Visa
                          : card.number.substring(0, 1) === "5"
                          ? Master
                          : NeutralCard
                        : NeutralCard
                    }
                  />
                </div>
                <div className="inputs">
                  <input
                    value={card.exp_month}
                    onChange={(e) =>
                      changeAttribute("exp_month", e.target.value)
                    }
                    placeholder="MM"
                    maxlength={2}
                  />
                  <input
                    value={card.exp_year}
                    onChange={(e) =>
                      changeAttribute("exp_year", e.target.value)
                    }
                    placeholder="YYYY"
                    maxlength={4}
                  />
                  <input
                    value={card.cvc}
                    onChange={(e) => changeAttribute("cvc", e.target.value)}
                    placeholder="CVV"
                    maxlength={3}
                  />
                </div>
              </div>
              <div className="actions-buttons">
                <button
                  onClick={
                    !state.update
                      ? () => {
                          setLoading(true);
                          purchase();
                        }
                      : () => {
                          setLoading(true);
                        }
                  }
                  className="complete"
                >
                  Complete Purchase
                </button>
                <button onClick={editPackage} className="cancel">
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="summary">
          <span className="summary-title">Order Summary</span>
          <div className="grey-line"></div>
          <div className="products">
            <div className="product">
              <div className="product-item">
                <span>{state.order.name} Package</span>
                <div className="price">
                  <span>
                    $
                    {(state.order.price *
                      (state.order.frequency === "year" ? 10 : 1)) /
                      100}
                  </span>
                </div>
              </div>
              <div className="product-item year">
                <span className="description">
                  {state.order.frequency === "year" ? "Annually" : "Monthly"}
                </span>
              </div>
            </div>
            <div className="product">
              <div className="product-item">
                <span>Add-Ons</span>
                <div className="price">
                  <span>${state.order.additionalPrice / 100}</span>
                </div>
              </div>
              <div className="product-item year">
                <span className="description">One Time</span>
              </div>
            </div>
          </div>
          <div className="total">
            <div className="total-price">
              <span>Total</span>
              <div className="price">
                <span>
                  $
                  {(state.order.price *
                    (state.order.frequency === "year" ? 10 : 1) +
                    state.order.additionalPrice) /
                    100}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CustomerOrderSummary;
