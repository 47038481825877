import "./Profile.scss";
import { useNavigate, useOutletContext } from "react-router";
import Youtube from "../../../../assets/youtube.png";
import Facebook from "../../../../assets/facebook.png";
import Instagram from "../../../../assets/instagram.png";
import Twitter from "../../../../assets/twitter.png";
import Tiktok from "../../../../assets/tiktok.png";
import Linkedin from "../../../../assets/linkedin.png";
import Snapchat from "../../../../assets/snapchat.png";
import Website from "../../../../assets/website.png";
import { userService } from "../../../../services/user.service";
import { useEffect, useState } from "react";
import { uploadService } from "../../../../services/upload.service";
import LoadingUpload from "../../../loadingUpload/LoadingUpload";
import Swal from "sweetalert2";

const CustomerProfile = () => {
  const navigate = useNavigate();
  const [userDisplayed, setUserDisplayed] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [askAdmin, setAskAdmin, setOrderTab, user, setUser] =
    useOutletContext();
  const [editNetwork, setEditNetwork] = useState(false);

  useEffect(() => {
    userService.getProfileDetails("editor", (data) => {
      setUserDisplayed(data.user);
    });
  }, []);

  const changeAttribute = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj[attribute] = e.target.value;
    setUserDisplayed(obj);
  };

  const changeNetwork = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    if (obj.socialMedias && obj.socialMedias.length) {
      if (
        obj.socialMedias.filter((item) => item.type === attribute) &&
        obj.socialMedias.filter((item) => item.type === attribute).length
      ) {
        obj.socialMedias.filter((item) => item.type === attribute)[0].account =
          e.target.value;
      } else {
        obj.socialMedias.push({
          type: attribute,
          account: e.target.value,
        });
      }
    } else {
      obj.socialMedias = [
        {
          type: attribute,
          account: e.target.value,
        },
      ];
    }
    setUserDisplayed(obj);
  };

  const changeImage = (attribute, data) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj[attribute] = data;
    setUserDisplayed(obj);
  };

  const changeAddress = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    if (e.target) {
      obj.billingAddress[attribute] = e.target.value;
    } else {
      obj.billingAddress[attribute] = e;
    }
    setUserDisplayed(obj);
  };

  const changeBusinessAddress = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    if (e.target) {
      obj.business.address[attribute] = e.target.value;
    } else {
      obj.business.address[attribute] = e;
    }
    setUserDisplayed(obj);
  };

  const changeBusinessAttribute = (attribute, e) => {
    const obj = JSON.parse(JSON.stringify(userDisplayed));
    obj.business[attribute] = e.target.value;
    setUserDisplayed(obj);
  };

  const networks = [
    {
      name: "Youtube",
      img: Youtube,
      id: "youtube",
    },
    {
      name: "Facebook",
      img: Facebook,
      id: "facebook",
    },
    {
      name: "Instagram",
      img: Instagram,
      id: "instagram",
    },
    {
      name: "Twitter",
      img: Twitter,
      id: "twitter",
    },
    {
      name: "Tiktok",
      img: Tiktok,
      id: "tiktok",
    },
    {
      name: "Linkedin",
      img: Linkedin,
      id: "linkedin",
    },
    {
      name: "Snapchat",
      img: Snapchat,
      id: "snapchat",
    },
    {
      name: "Personal Website",
      img: Website,
      id: "website",
    },
  ];

  return userDisplayed && userDisplayed.firstName ? (
    <div className="customer-layout-client-details-page">
      {loading ? <LoadingUpload /> : null}
      <div className="header-main">
        {edit ? (
          <button
            onClick={() => {
              userService.updateUser(userDisplayed, (data) => {
                setEdit(false);
                setUserDisplayed(data);
                userService.getProfile(
                  (data) => {
                    setUser(data);
                  },
                  (e) => {
                    setLoading(false);
                    Swal.fire({
                      title: "Error!",
                      text: "Please, login before entering your account",
                      icon: "error",
                    }).then(() => {
                      navigate("/auth/admin/sign-in");
                    });
                  }
                );
              });
            }}
            className="edit"
          >
            Save
          </button>
        ) : (
          <button onClick={() => setEdit(true)} className="edit">
            Edit
          </button>
        )}
      </div>
      <div className="profile-picture-info">
        {edit ? (
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg"
            style={{ display: "none" }}
            id={`upload-profile-picture-profile-customer`}
            onChange={(e) => {
              let file = e.target.files[0];
              setLoading(true);

              if (file) {
                let data = new FormData();
                data.append("file", file);

                uploadService.upload(data, (data) => {
                  setLoading(false);
                  changeImage("profilePicture", data);
                });
              }
            }}
          />
        ) : null}
        <label
          for="upload-profile-picture-profile-customer"
          className="image-circle"
        >
          {/* <CircularProgressbar value={60} className="circular-level-bar" strokeWidth={2} counterClockwise={true}
                        styles={buildStyles({
                            trailColor: `#F2F5FA`,
                            pathColor: '#2A9DF4',
                            backgroundColor: 'white',
                        })} /> */}
          <div className="image">
            <img
              src={
                user.profilePicture
                  ? user.profilePicture.url
                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
              }
            />
          </div>
        </label>
        <span className="name">
          {user.firstName} {user.lastName}
        </span>
        <span className="title">Customer</span>
      </div>
      <div className="mainInfo">
        <div className="name">
          <div className="item">
            <span className="title">First Name</span>
            <div className="input">
              {/* <span>Enter First Name</span> */}
              <input
                readOnly={!edit}
                value={userDisplayed.firstName}
                onChange={(e) => changeAttribute("firstName", e)}
              />
            </div>
          </div>
          <div className="item">
            <span className="title">Last Name</span>
            <div className="input">
              {/* <span>Enter Last Name</span> */}
              <input
                readOnly={!edit}
                value={userDisplayed.lastName}
                onChange={(e) => changeAttribute("lastName", e)}
              />
            </div>
          </div>
        </div>
        <div className="name">
          <div className="item">
            <span className="title">Email</span>
            <div className="input">
              {/* <span>Enter Email</span> */}
              <input
                readOnly={!edit}
                value={userDisplayed.email}
                onChange={(e) => changeAttribute("email", e)}
              />
            </div>
          </div>
          <div className="item">
            <span className="title">Phone Number</span>
            <div className="input">
              {/* <span>Enter Phone Number</span> */}
              <input
                readOnly={!edit}
                value={userDisplayed.phoneNumber}
                onChange={(e) => changeAttribute("phoneNumber", e)}
              />
            </div>
          </div>
        </div>
        {userDisplayed.business ? (
          <div className="name">
            <div className="item" style={{ marginLeft: 0 }}>
              <span className="title">Business Name</span>
              <div className="input">
                {/* <span>Enter Business Name</span> */}
                <input
                  readOnly={!edit}
                  value={userDisplayed.business.name}
                  onChange={(e) => changeBusinessAttribute("name", e)}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {userDisplayed.business ? (
        <div className="business-adress">
          <span className="title">Business Address</span>
          <div className="field">
            <div className="input">
              <span>Street</span>
              <input
                readOnly={!edit}
                value={userDisplayed.business.address.street}
                onChange={(e) => changeBusinessAddress("street", e)}
              />
            </div>
            <div className="input">
              <span>App/Suite #</span>
              <input
                readOnly={!edit}
                value={userDisplayed.business.address.complement}
                onChange={(e) => changeBusinessAddress("complement", e)}
              />
            </div>
          </div>
          <div className="field">
            <div className="input">
              <span>Country</span>
              {/* <select
                disabled={
                  businessCountries && businessCountries.length && edit
                    ? false
                    : true
                }
                onChange={(e) => {
                  setBusinessAddressCountry(e.target.value);
                  const countryName = [...businessCountries].filter(
                    (country) => country.iso2 === e.target.value
                  )[0].name;
                  changeBusinessAddress("country", countryName);
                }}
              >
                {businessCountries.map((country) => {
                  return (
                    <option
                      selected={
                        [...businessCountries].filter(
                          (country) =>
                            country.name ===
                            userDisplayed.business.address.country
                        )[0].iso2 === country.iso2
                      }
                      value={country.iso2}
                    >
                      {country.name}
                    </option>
                  );
                })}
              </select> */}
              <input
                readOnly={!edit}
                value={userDisplayed.business.address.country}
                onChange={(e) => changeBusinessAddress("country", e)}
              />
            </div>
            <div className="input">
              <span>Zip Code</span>
              <input
                readOnly={!edit}
                value={userDisplayed.business.address.zipCode}
                onChange={(e) => changeBusinessAddress("zipCode", e)}
              />
            </div>
          </div>
          <div className="field">
            <div className="input">
              <span>State</span>
              {/* <select
                disabled={
                  businessStates && businessStates.length && edit ? false : true
                }
                onChange={(e) => {
                  setBusinessAddressState(e.target.value);
                  const stateName = [...businessStates].filter(
                    (state) => state.iso2 === e.target.value
                  )[0].name;
                  changeBusinessAddress("state", stateName);
                }}
              >
                {businessStates.map((state) => {
                  return (
                    <option
                      selected={
                        [...businessStates].filter((state) => {
                          return (
                            state.name === userDisplayed.business.address.state
                          );
                        })[0].iso2 === state.iso2
                      }
                      value={state.iso2}
                    >
                      {state.name}
                    </option>
                  );
                })}
              </select> */}
              <input
                readOnly={!edit}
                value={userDisplayed.business.address.state}
                onChange={(e) => changeBusinessAddress("state", e)}
              />
            </div>
            <div className="input">
              <span>City</span>
              {/* <select
                disabled={
                  businessCities && businessCities.length && edit ? false : true
                }
                onChange={(e) => {
                  setBusinessAddressCity(e.target.value);
                  const cityName = [...businessCities].filter(
                    (city) => city.name === e.target.value
                  )[0].name;
                  changeBusinessAddress("city", cityName);
                }}
              >
                {businessCities.map((city) => {
                  return (
                    <option
                      selected={
                        [...businessCities].filter(
                          (city) =>
                            city.name === userDisplayed.business.address.city
                        )[0].name === city.name
                      }
                      value={city.name}
                    >
                      {city.name}
                    </option>
                  );
                })}
              </select> */}
              <input
                readOnly={!edit}
                value={userDisplayed.business.address.city}
                onChange={(e) => changeBusinessAddress("city", e)}
              />
            </div>
          </div>
        </div>
      ) : null}
      {userDisplayed.business ? (
        <div className="bio">
          <span className="title">Company Bio</span>
          <input
            readOnly={!edit}
            value={userDisplayed.business.bio}
            onChange={(e) => changeBusinessAttribute("bio", e)}
          />
        </div>
      ) : null}
      <div className="business-adress">
        <span className="title">Billing Address</span>
        <div className="field">
          <div className="input">
            <span>Street</span>
            <input
              readOnly={!edit}
              value={userDisplayed.billingAddress.street}
              onChange={(e) => changeAddress("street", e)}
            />
          </div>
          <div className="input">
            <span>App/Suite #</span>
            <input
              readOnly={!edit}
              value={userDisplayed.billingAddress.complement}
              onChange={(e) => changeAddress("complement", e)}
            />
          </div>
        </div>
        <div className="field">
          <div className="input">
            <span>Country</span>
            {/* <select
              disabled={
                billingCountries && billingCountries.length && edit
                  ? false
                  : true
              }
              onChange={(e) => {
                setBillingAddressCountry(e.target.value);
                const countryName = [...billingCountries].filter(
                  (country) => country.iso2 === e.target.value
                )[0].name;
                changeAddress("country", countryName);
              }}
            >
              {billingCountries.map((country) => {
                return (
                  <option
                    selected={
                      [...billingCountries].filter(
                        (country) =>
                          country.name === userDisplayed.billingAddress.country
                      )[0].iso2 === country.iso2
                    }
                    value={country.iso2}
                  >
                    {country.name}
                  </option>
                );
              })}
            </select> */}
            <input
              readOnly={!edit}
              value={userDisplayed.billingAddress.country}
              onChange={(e) => changeAddress("country", e)}
            />
          </div>
          <div className="input">
            <span>Zip Code</span>
            <input
              readOnly={!edit}
              value={userDisplayed.billingAddress.zipCode}
              onChange={(e) => changeAddress("zipCode", e)}
            />
          </div>
        </div>
        <div className="field">
          <div className="input">
            <span>State</span>
            {/* <select
              disabled={
                billingStates && billingStates.length && edit ? false : true
              }
              onChange={(e) => {
                setBillingAddressState(e.target.value);
                const stateName = [...billingStates].filter(
                  (state) => state.iso2 === e.target.value
                )[0].name;
                changeAddress("state", stateName);
              }}
            >
              {billingStates.map((state) => {
                return (
                  <option
                    selected={
                      [...billingStates].filter(
                        (state) =>
                          state.name === userDisplayed.billingAddress.state
                      )[0].iso2 === state.iso2
                    }
                    value={state.iso2}
                  >
                    {state.name}
                  </option>
                );
              })}
            </select> */}
            <input
              readOnly={!edit}
              value={userDisplayed.billingAddress.state}
              onChange={(e) => changeAddress("state", e)}
            />
          </div>
          <div className="input">
            <span>City</span>
            {/* <input
              value={userDisplayed.billingAddress.state}
              onChange={(e) => changeAddress("state", e)}
            /> */}
            {/* <select
              disabled={
                billingCities && billingCities.length && edit ? false : true
              }
              onChange={(e) => {
                setBillingAddressCity(e.target.value);
                const cityName = [...billingCities].filter(
                  (city) => city.name === e.target.value
                )[0].name;
                changeAddress("city", cityName);
              }}
            >
              {billingCities.map((city) => {
                return (
                  <option
                    selected={
                      [...billingCities].filter(
                        (city) =>
                          city.name === userDisplayed.billingAddress.city
                      )[0].name === city.name
                    }
                    value={city.name}
                  >
                    {city.name}
                  </option>
                );
              })}
            </select> */}
            <input
              readOnly={!edit}
              value={userDisplayed.billingAddress.city}
              onChange={(e) => changeAddress("city", e)}
            />
          </div>
        </div>
      </div>
      <div className="bio">
        <span className="title">Personal Bio</span>
        <input
          readOnly={!edit}
          value={userDisplayed.bio}
          onChange={(e) => changeAttribute("bio", e)}
        />
      </div>
      <div className="networking-info">
        <span className="title">Networking Info</span>
        <div className="networking-content">
          {networks.map((network) => {
            return (
              <div className="social-network">
                <div className="name-icon">
                  <img src={network.img} />
                  <span style={{ marginLeft: ".5em" }}>{network.name}</span>
                </div>
                <input
                  readOnly={!editNetwork}
                  onChange={(e) => {
                    changeNetwork(network.id, e);
                  }}
                  value={
                    userDisplayed.socialMedias.filter(
                      (media) => media.type === network.id
                    ) &&
                    userDisplayed.socialMedias.filter(
                      (media) => media.type === network.id
                    ).length
                      ? userDisplayed.socialMedias.filter(
                          (media) => media.type === network.id
                        )[0].account
                      : ""
                  }
                  className="data"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default CustomerProfile;
