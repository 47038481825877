import { useEffect, useRef, useState } from "react";
import "./SliderProgress.scss";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const SliderProgress = ({
  multiplier = 1,
  dividers = 10,
  setItem,
  item,
  items,
  maximum,
  id,
}) => {
  const [left, setLeft] = useState(-18.5);
  const [offset, setOffset] = useState(0);
  const [maxX, setMaxX] = useState(0);
  const [value, setValue] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  const bodyRef = useRef(null);

  useEffect(() => {
    if (ref !== null && bodyRef !== null) {
      var bodyRect = document.body.getBoundingClientRect(),
        elemRect = bodyRef.current.getBoundingClientRect();

      setOffset(elemRect.left);
    }
  }, [ref, bodyRef]);

  useEffect(() => {
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = bodyRef.current.getBoundingClientRect();
    setMaxX(elemRect.left + elemRect.width);
  }, [offset]);

  useEffect(() => {
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = bodyRef.current.getBoundingClientRect();
    setWidth(elemRect.width);
  }, [maxX]);

  useEffect(() => {
    if (left === -18.5 && item > 0) {
      setValue(item);
      setLeft(
        (width / (dividers - 1)) *
          items
            .map((itemValue, index) => {
              if (itemValue === item) {
                return index;
              } else {
                return "not right";
              }
            })
            .filter((itemValue) => itemValue !== "not right")[0]
      );
    }
  }, [item]);

  useEffect(() => {
    const eventDown =
      getWindowDimensions().width > 700 ? "mousedown" : "touchstart";
    const eventUp = getWindowDimensions().width > 700 ? "mouseup" : "touchend";
    ref.current.addEventListener(eventDown, mouseDown, false);
    window.addEventListener(eventUp, mouseUp, false);
    if (item > 0 && width > 0) {
      setValue(item);
      setLeft(
        (width / (dividers - 1)) *
          items
            .map((itemValue, index) => {
              if (itemValue === item) {
                return index;
              } else {
                return "not right";
              }
            })
            .filter((itemValue) => itemValue !== "not right")[0]
      );
    }
  }, [width]);

  const mouseUp = () => {
    const eventMove =
      getWindowDimensions().width > 700 ? "mousemove" : "touchmove";
    window.removeEventListener(eventMove, divMove, true);
  };

  const mouseDown = () => {
    const eventMove =
      getWindowDimensions().width > 700 ? "mousemove" : "touchmove";
    window.addEventListener(eventMove, divMove, true);
  };

  const divMove = (e) => {
    const eventX =
      getWindowDimensions().width > 700
        ? e.clientX
        : e.changedTouches[0].clientX;

    if (id && id !== null) {
      if (
        eventX < maxX - 18.5 &&
        eventX > offset - 18.5 &&
        items[
          Math.round(
            Math.round(eventX - (offset - 18.5)) / (width / (dividers - 1))
          )
        ] <= parseInt(localStorage.getItem(id))
      ) {
        setLeft(eventX - offset);

        const item =
          items[
            Math.round(
              Math.round(eventX - (offset - 18.5)) / (width / (dividers - 1))
            )
          ];

        setValue(item);
      }
    } else {
      if (eventX < maxX - 18.5 && eventX > offset - 18.5) {
        setLeft(eventX - offset);

        const item =
          items[
            Math.round(
              Math.round(eventX - (offset - 18.5)) / (width / (dividers - 1))
            )
          ];

        setValue(item);
      }
    }
  };

  useEffect(() => {
    if (value > 0) {
      setItem(value);
    }
  }, [value]);

  return (
    <div className="slider-progress-component">
      <div className="slider-track" ref={bodyRef}>
        <div
          ref={ref}
          className="slider-ball"
          style={{ left: `${left}px` }}
        ></div>
      </div>
      {getWindowDimensions().width > 1000 ? (
        <div
          className="markers"
          style={{ gridTemplateColumns: `repeat(${dividers}, 1fr)` }}
        >
          {items.map((item) => {
            return (
              <span className={`marker ${value === item ? "active" : ""}`}>
                {item}
              </span>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default SliderProgress;
