import { coreService } from "./core.service";

const list = async (callback) => {
  coreService.get("package-management/list", callback);
};

const savePackages = async (body, callback) => {
  coreService.post("package-management/save-packages", body, callback);
};

const saveAddOns = async (body, callback) => {
  coreService.post("package-management/save-add-ons", body, callback);
};

export const PackageManagementService = {
  list,
  savePackages,
  saveAddOns,
};
