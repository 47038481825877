import "./SingleOrder.scss";
import Video from "../../../../../assets/video-types/video.png";
import Thumbnail from "../../../../../assets/video-types/thumbnail.png";
import Banner from "../../../../../assets/video-types/banner.png";
import Logo from "../../../../../assets/video-types/logo.png";
import ConsultationCall from "../../../../../assets/video-types/consultation-call.png";
import Outro from "../../../../../assets/video-types/outro.png";
import Intro from "../../../../../assets/video-types/intro.png";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Loading from "../../../../loading/Loading";
import { PackageManagementService } from "../../../../../services/package-management.service";
import BgBlurred from "../../../../../assets/Bg-blurred.png";
import Time from "../../../../../assets/video-types/time.png";
import Clock from "../../../../../assets/video-types/clock.png";
import Swal from "sweetalert2";
import { CustomPackageRequestService } from "../../../../../services/customPackageRequest.service";
import { useFormik } from "formik";
import { purchaseService } from "../../../../../services/package.service";
import moment from "moment";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";
import LogoLanding from "../../../../../assets/landing/logo-landing.png";

const CustomerTestSingleOrder = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [addOns, setAddOns] = useState([]);
  const [addOnsPrice, setAddOnsPrice] = useState(0);
  const [isMessageShown, setIsMessageShown] = useState(false);
  const [order, setOrder] = useState({
    name: "Single",
    videos: 0,
    rawFootage: 0,
    runningVideo: 0,
    thumbnails: 0,
    banner: 0,
    logo: 0,
    consultationCall: 0,
    outro: 0,
    intro: 0,
    unlimitedStockFootage: false,
    price: 0,
  });
  const [step, setStep] = useState(1);
  const [thumbnailWithVideo, setThumbnailWithVideo] = useState(false);
  const [exceedError, setExceedError] = useState(false);

  useEffect(() => {
    let copyOrder;

    const getPackagesAndAddOns = async () => {
      PackageManagementService.list((data) => {
        let currPrice = 0;
        data.addOns = data.addOns.map((addOn) => {
          if (!state.order) {
            addOn.count = 0;
          } else if (
            addOn.type !== "Video" &&
            addOn.type !== "Raw Footage Time" &&
            addOn.type !== "Final Running Time"
          ) {
            addOn.count = state.order[addOn.codeId];
            currPrice = currPrice + addOn.count * addOn.cost;
          }
          return addOn;
        });

        setAddOnsPrice(currPrice);
        setAddOns(data.addOns);
      });
    };

    if (state.order) {
      copyOrder = JSON.parse(JSON.stringify(order));
      setOrder({ ...copyOrder, ...state.order });
    }

    getPackagesAndAddOns();
  }, [state]);

  const messageFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      budget: 0,
      socials: "",
      description: "",
    },
    onSubmit: (values) => {
      if (
        !values.name.trim() ||
        !values.email.trim() ||
        !values.budget ||
        !values.description.trim()
      ) {
        Swal.fire({
          title: "Ops!",
          text: "Please, add all required fields and try again",
          icon: "error",
        });
        return;
      }

      CustomPackageRequestService.saveRequest({ request: values }, () => {
        Swal.fire({
          title: "Congratulation!",
          text: "We received your message! We will get back to you within 24 hours with a quote or to discuss specifics of your project. You can leave the checkout screen.",
          icon: "success",
        }).then(() => {
          setIsMessageShown(false);
          messageFormik.resetForm();
        });
      });
    },
  });

  const changeCount = (operation, type, amount = 1) => {
    if (type === "Video") {
      const newOrder = JSON.parse(JSON.stringify(order));
      const newAddOns = [...addOns];
      const video = [...addOns]
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Video")[0];
      const rawTime = [...addOns].filter(
        (addOn) => addOn.type === "Raw Footage Time"
      )[0];
      const finalTime = [...addOns].filter(
        (addOn) => addOn.type === "Final Running Time"
      )[0];
      if (operation === "plus") {
        newOrder.price = newOrder.price + video[0].cost;
        newAddOns[video[1]].count = newAddOns[video[1]].count + amount;
        if (rawTime.count) {
          newOrder.price = newOrder.price + (rawTime.count / 10) * rawTime.cost;
        }
        if (finalTime.count) {
          newOrder.price =
            newOrder.price + (finalTime.count / 10) * finalTime.cost;
        }
      } else if (operation === "minus" && video[0].count > 0) {
        newOrder.price = newOrder.price - video[0].cost;
        newAddOns[video[1]].count = newAddOns[video[1]].count - amount;
        if (rawTime.count) {
          newOrder.price = newOrder.price - (rawTime.count / 10) * rawTime.cost;
        }
        if (finalTime.count) {
          newOrder.price =
            newOrder.price - (finalTime.count / 10) * finalTime.cost;
        }
      }

      setAddOns(newAddOns);
      setOrder(newOrder);
    } else if (type === "Raw Footage Time") {
      const newOrder = JSON.parse(JSON.stringify(order));
      const newAddOns = [...addOns];
      const rawTime = newAddOns
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Raw Footage Time")[0];
      const numVideos = newAddOns.filter((addOn) => addOn.type === "Video")[0]
        .count;
      if (operation === "plus") {
        newOrder.price = newOrder.price + rawTime[0].cost * numVideos;
        newAddOns[rawTime[1]].count = newAddOns[rawTime[1]].count + 10;
      } else if (operation === "minus" && rawTime[0].count > 0) {
        newOrder.price = newOrder.price + rawTime[0].cost * numVideos;
        newAddOns[rawTime[1]].count = newAddOns[rawTime[1]].count - 10;
      }

      setAddOns(newAddOns);
      setOrder(newOrder);
    } else if (type === "Final Running Time") {
      const newOrder = JSON.parse(JSON.stringify(order));
      const newAddOns = [...addOns];
      const finalTime = newAddOns
        .map((addOn, i) => [addOn, i])
        .filter((addOnArr) => addOnArr[0].type === "Final Running Time")[0];
      const numVideos = newAddOns.filter((addOn) => addOn.type === "Video")[0]
        .count;
      const rawTime = newAddOns.filter(
        (addOn) => addOn.type === "Raw Footage Time"
      )[0].count;
      if (operation === "plus" && finalTime[0].count + 10 <= rawTime) {
        newOrder.price = newOrder.price + finalTime[0].cost * numVideos;
        newAddOns[finalTime[1]].count = newAddOns[finalTime[1]].count + 10;
      } else if (operation === "plus" && finalTime[0].count + 10 > rawTime) {
        setExceedError(true);
        setTimeout(() => {
          setExceedError(false);
        }, 3000);
      } else if (operation === "minus" && finalTime[0].count > 0) {
        newOrder.price = newOrder.price + finalTime[0].cost * numVideos;
        newAddOns[finalTime[1]].count = newAddOns[finalTime[1]].count - 10;
      }

      setAddOns(newAddOns);
      setOrder(newOrder);
    } else {
      const newAddOns = [...addOns].map((addOn) => {
        if (addOn.type === type && operation === "plus") {
          addOn.count = addOn.count + amount;
          setAddOnsPrice((curr) => curr + addOn.cost);
        } else if (
          addOn.type === type &&
          operation === "minus" &&
          addOn.count > 0
        ) {
          addOn.count = addOn.count - amount;
          setAddOnsPrice((curr) => curr - addOn.cost);
        }

        return addOn;
      });

      setAddOns(newAddOns);
    }
  };

  const orderPackage = () => {
    const finalTime = [...addOns].filter(
      (addOn) => addOn.type === "Final Running Time"
    )[0];
    const rawTime = [...addOns].filter(
      (addOn) => addOn.type === "Raw Footage Time"
    )[0];

    if (!finalTime.count || !rawTime.count) {
      Swal.fire({
        title: "Ops!",
        text: "Please, add the Final Running Time and Minutes of Provided Footage",
        icon: "error",
      });
      return;
    }
    const obj = JSON.parse(JSON.stringify(order));
    for (const addOn of [...addOns]) {
      obj[
        `additional${addOn.codeId
          .substring(0, 1)
          .toUpperCase()}${addOn.codeId.substring(1, addOn.codeId.length)}`
      ] = addOn.count;
    }

    obj.additionalPrice = addOnsPrice * 100;
    obj.deliveryTime = 2;
    obj.price = obj.price * 100;
    obj.nextPrice = obj.price;

    let upd = false;

    if (
      state &&
      state.update &&
      state.update !== null &&
      state.update === true
    ) {
      upd = true;
    }

    navigate("../order-summary", {
      state: { order: obj, update: upd },
    });
  };

  const changeThumbnails = (add) => {
    const numVideos = [...addOns].filter((addOn) => addOn.type === "Video")[0]
      .count;

    const newAddOns = [...addOns].map((addOn) => {
      if (addOn.type === "Thumbnail") {
        if (add) {
          addOn.count = addOn.count + numVideos;
          setAddOnsPrice((curr) => curr + numVideos * addOn.cost);
        } else {
          addOn.count = addOn.count - numVideos;
          setAddOnsPrice((curr) => curr - numVideos * addOn.cost);
        }
      }

      return addOn;
    });

    setAddOns(newAddOns);
  };

  return addOns && addOns.length && order ? (
    <div className="customer-test-layout-single-order-page">
      {loading ? <LoadingUpload text="Finishing Purchase" /> : null}
      <div className="page-content">
        <img className="blurred-bg" src={BgBlurred} alt="blurred-background" />
        <div className="login-header">
          <img src={LogoLanding} alt="logo" />
          <button
            className="login-button"
            onClick={() => navigate("/auth/sign-in")}
          >
            Login
          </button>
        </div>
        <div className="package-main-content">
          <div className="wrapper">
            {isMessageShown ? (
              <div className="middle-blocks message">
                <img
                  className="blurred-bg"
                  src={BgBlurred}
                  alt="blurred-background"
                />
                <div className="message-container">
                  <div className="customer-layout-add-ons-page-block add-ons-content order-details">
                    <div className="title">
                      <span>Message Us About Your Custom Package</span>
                      <span className="subtitle">
                        Leave your details below, and we'll get back to you
                        within 24 hours with a quote or to discuss specifics of
                        your project.
                      </span>
                    </div>
                    <div className="inputs">
                      <div className="input-row">
                        <div className="input">
                          <span className="input-title">Name *</span>
                          <input
                            id="name"
                            value={messageFormik.values.name}
                            onChange={messageFormik.handleChange}
                          />
                        </div>
                        <div className="input">
                          <span className="input-title">Email *</span>
                          <input
                            id="email"
                            value={messageFormik.values.email}
                            onChange={messageFormik.handleChange}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div className="input">
                          <span className="input-title">Phone Number</span>
                          <input
                            id="phoneNumber"
                            value={messageFormik.values.phoneNumber}
                            onChange={messageFormik.handleChange}
                          />
                        </div>
                        <div className="input">
                          <span className="input-title">
                            Your Approximate Budget *
                          </span>
                          <input
                            id="budget"
                            value={messageFormik.values.budget}
                            onChange={messageFormik.handleChange}
                          />
                        </div>
                      </div>
                      <div className="input-row">
                        <div className="input">
                          <span className="input-title">Socials</span>
                          <input
                            id="socials"
                            value={messageFormik.values.socials}
                            onChange={messageFormik.handleChange}
                          />
                        </div>
                      </div>
                      <div className="input">
                        <span className="input-title">
                          Detailed Project Description *
                        </span>
                        <textarea
                          rows={4}
                          id="description"
                          value={messageFormik.values.description}
                          onChange={messageFormik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="continue-without"
                    onClick={messageFormik.handleSubmit}
                  >
                    Send Message
                  </button>
                  <button
                    className="cancel"
                    onClick={() => {
                      setIsMessageShown(false);
                    }}
                  >
                    Go back
                  </button>
                </div>
              </div>
            ) : step === 1 ? (
              <div className="middle-blocks">
                <div className="customer-layout-add-ons-page-block add-ons-header">
                  <div className="title">
                    <span>
                      <span className="number">1</span> Single Order
                    </span>
                    <button
                      onClick={() =>
                        navigate("/customer/packages/select-package")
                      }
                    >
                      Change Package
                    </button>
                  </div>
                </div>
                <div className="customer-layout-add-ons-page-block add-ons-content">
                  <div className="title">
                    <span>
                      <span className="number">2</span> Add Add-Ons
                    </span>
                    <span className="subtitle">
                      One time purchases billed monthly along with your current
                      Package.
                    </span>
                  </div>
                  <div className="add-ons">
                    {addOns
                      .filter(
                        (addOn) =>
                          addOn.type !== "Raw Footage Time" &&
                          addOn.type !== "Final Running Time"
                      )
                      .map((addOn) => {
                        return (
                          <div className="add-on">
                            <div className="left">
                              <div className="image-container">
                                {addOn.type === "Video" ? (
                                  <img src={Video} alt="add-on-symbol" />
                                ) : addOn.type === "Thumbnail" ? (
                                  <img src={Thumbnail} alt="add-on-symbol" />
                                ) : addOn.type === "Logo" ? (
                                  <img src={Logo} alt="add-on-symbol" />
                                ) : addOn.type === "Banner Design" ? (
                                  <img src={Banner} alt="add-on-symbol" />
                                ) : addOn.type === "Consultation Call" ? (
                                  <img
                                    src={ConsultationCall}
                                    alt="add-on-symbol"
                                  />
                                ) : addOn.type === "Outro" ? (
                                  <img src={Outro} alt="add-on-symbol" />
                                ) : (
                                  <img src={Intro} alt="add-on-symbol" />
                                )}
                              </div>
                              <div className="details">
                                <span className="name">{addOn.type}</span>
                                <span className="price">${addOn.cost}</span>
                              </div>
                            </div>
                            <div className="right">
                              <div
                                className="symbol"
                                onClick={() => changeCount("minus", addOn.type)}
                              >
                                -
                              </div>
                              <div className="counter">{addOn.count}</div>
                              <div
                                className="symbol"
                                onClick={() => changeCount("plus", addOn.type)}
                              >
                                +
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <button
                  className="continue-without"
                  onClick={() => {
                    const videoCount = [...addOns].filter(
                      (addOn) => addOn.type === "Video"
                    )[0].count;
                    console.log(videoCount);
                    if (videoCount) {
                      setStep(2);
                    } else {
                      Swal.fire({
                        title: "Ops!",
                        text: "Please add at least 1 video",
                        icon: "error",
                      });
                    }
                  }}
                >
                  {addOnsPrice === 0 ? "Continue without add-ons" : "Continue"}
                </button>
              </div>
            ) : (
              <div className="middle-blocks">
                <div className="customer-layout-add-ons-page-block add-ons-content order-details">
                  <div className="title">
                    <span>
                      <span className="number">3</span> Order Details
                    </span>
                    <span className="subtitle">
                      Add the details of your Single Order by entering how much
                      or many you want of each item.
                      <br />
                      <span className="green">
                        Save money by ordering more than one of each item!
                      </span>
                    </span>
                  </div>
                  <div className="add-ons">
                    {addOns
                      .filter(
                        (addOn) =>
                          addOn.type === "Raw Footage Time" ||
                          addOn.type === "Final Running Time"
                      )
                      .map((addOn) => {
                        return (
                          <div className="add-on">
                            <div className="left">
                              <div className="image-container">
                                {addOn.type === "Raw Footage Time" ? (
                                  <img src={Time} alt="add-on-symbol" />
                                ) : (
                                  <img src={Clock} alt="add-on-symbol" />
                                )}
                              </div>
                              <div className="details">
                                <span className="price">
                                  {addOn.type === "Raw Footage Time"
                                    ? "Minutes of provided footage"
                                    : "Final Running time of the video in minutes"}{" "}
                                  - ${addOn.cost} for 10 min (Per video)
                                </span>
                                <span className="description">
                                  {addOn.type === "Raw Footage Time"
                                    ? "This is the raw, recorded, & unedited footage files you are going to send us to edit for your video."
                                    : "This is the final length of the video being delivered in minutes. This cannot exceed the length of raw footage."}
                                  {exceedError &&
                                  addOn.type === "Final Running Time" ? (
                                    <span className="error">
                                      The final running time cannot exceed the
                                      raw footage time
                                    </span>
                                  ) : null}
                                </span>
                              </div>
                            </div>
                            <div className="right">
                              <div
                                className="symbol"
                                onClick={() =>
                                  changeCount("minus", addOn.type, 10)
                                }
                              >
                                -
                              </div>
                              <div className="counter">{addOn.count}</div>
                              <div
                                className="symbol"
                                onClick={() =>
                                  changeCount("plus", addOn.type, 10)
                                }
                              >
                                +
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div className="add-on">
                      <div className="left">
                        <div className="image-container">
                          <img src={Thumbnail} alt="add-on-symbol" />
                        </div>
                        <div className="details">
                          <span className="price">Thumbnail with Video</span>
                        </div>
                      </div>
                      <div className="right">
                        <input
                          type="checkbox"
                          checked={thumbnailWithVideo}
                          onChange={(e) => {
                            setThumbnailWithVideo(e.target.checked);
                            if (e.target.checked) {
                              changeThumbnails(true);
                            } else {
                              changeThumbnails(false);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="message-container">
                      <span className="message-title">
                        Need a more personalized package?
                      </span>
                      <span>
                        Send us a message and we can create a quote for your
                        unique package within 24 hours.
                      </span>
                      <button onClick={() => setIsMessageShown(true)}>
                        Message Us
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  className="continue-without"
                  onClick={() => orderPackage()}
                >
                  Continue To Checkout
                </button>
                <button
                  className="cancel"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Go back
                </button>
              </div>
            )}
          </div>
          <div className="summary">
            <span className="summary-title">Order Summary</span>
            <div className="grey-line"></div>
            <div className="products">
              <div className="product">
                <div className="product-item">
                  <span>Single Order</span>
                  <div className="price">
                    <span>${order.price > 0 ? order.price : 0}</span>
                  </div>
                </div>
              </div>
              <div className="product">
                <div className="product-item">
                  <span>Add-Ons</span>
                  <div className="price">
                    <span>${addOnsPrice}</span>
                  </div>
                </div>
                <div className="product-item year">
                  <span className="description">One Time</span>
                </div>
              </div>
            </div>
            <div className="total">
              <div className="total-price">
                <span>Total</span>
                <div className="price">
                  <span>
                    ${(order.price > 0 ? order.price : 0) + addOnsPrice}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CustomerTestSingleOrder;
