import "./Login.scss";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState, useEffect } from "react";
import { useOutletContext } from "react-router";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { authService } from "../../../../services/auth.service";
import Cookies from "js-cookie";
import Swal from "sweetalert2/dist/sweetalert2";
import { Link } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import Google from "../../../../assets/google.svg";
import BgBlurred from "../../../../assets/Bg-blurred.png";

const GeneralAuthLogin = () => {
  const [isPasswordShown, setIsPasswordShows] = useState(false);
  const [setIsTermsOfServiceOpen, setIsPrivacyPolicyOpen] = useOutletContext();
  const [rememberMe, setRememberMe] = useState(false);
  const [forgotOpen, setForgotOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("rememberMe") === true ||
      localStorage.getItem("rememberMe") === "true"
    ) {
      setRememberMe(true);
      formik.values.email = localStorage.getItem("email");
      formik.values.password = localStorage.getItem("password");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      if (rememberMe) {
        localStorage.setItem("rememberMe", true);
        localStorage.setItem("email", values.email);
        localStorage.setItem("password", values.password);
      }
      authService.login(
        { email: values.email, password: values.password },
        (data) => {
          Cookies.set("token", data.token);
          Swal.fire({
            title: "Success!",
            text: "Login Successful",
            icon: "success",
          }).then(() => {
            if (data.finished) {
              navigate(`/${data.type}`);
            } else {
              navigate(`/auth/choose-way/${data.uuid}`);
            }
          });
        }
      );
    },
  });

  const sendForgotEmail = () => {
    authService.sendResetPasswordEmail(forgotEmail, (data) => {
      Swal.fire({
        title: "Email sent!",
      }).then(() => {
        setForgotEmail("");
        setForgotOpen(false);
      });
    });
  };

  const googleLogin = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        authService.login(
          { email: result.user.email, uid: result.user.uid },
          (data) => {
            Cookies.set("token", data.token);
            Swal.fire({
              title: "Success!",
              text: "Login Successful",
              icon: "success",
            }).then(() => {
              if (data.finished) {
                navigate(`/${data.type}`);
              } else {
                navigate(`/auth/choose-way/${data.uuid}`);
              }
            });
          }
        );
      })
      .catch((e) => {
        throw Error("Something happened");
      });
  };

  return (
    <div className="auth-general-login">
      {forgotOpen ? (
        <div className="forgot-open">
          <div className="forgot-open-content">
            <span className="forgot-title">Please input your email</span>
            <input
              onChange={(e) => setForgotEmail(e.target.value)}
              placeholder="Email"
            />
            <button onClick={() => sendForgotEmail()}>Send Email</button>
          </div>
        </div>
      ) : null}
      <div className="sign-up">
        <img className="blurred-bg" src={BgBlurred} alt="blurred-background" />
        <div className="sign-up-container">
          <span className="title">Sign In</span>
          <span className="sub-title">
            Enter your information to enter your account
          </span>
          <div className="login-info">
            <div className="input-box">
              <span className="input-title">Email Address</span>
              <input
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                type="email"
                placeholder="youremail@gmail.com"
              />
            </div>
            <div className="input-box">
              <span className="input-title">Password</span>
              <div className="password">
                <input
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  type={isPasswordShown ? "text" : "password"}
                  placeholder="••••••••"
                />
                {isPasswordShown ? (
                  <AiOutlineEye
                    onClick={() => setIsPasswordShows(!isPasswordShown)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    onClick={() => setIsPasswordShows(!isPasswordShown)}
                  />
                )}
              </div>
            </div>
            <div className="remember-me-forgot-password">
              <div className="remember-me">
                <input
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  type="checkbox"
                />
                <span>Remember me</span>
              </div>
              <span
                className="forgot-password"
                onClick={() => setForgotOpen(true)}
              >
                Forgot Password?
              </span>
            </div>
          </div>
          <button className="login-button" onClick={formik.handleSubmit}>
            Sign In
          </button>
          <span className="or">Or</span>
          <div className="social">
            <span>Sign In With</span>
            <img
              onClick={() => googleLogin()}
              src={Google}
              alt="social-login"
            />
          </div>
          <Link to={"../sign-up"} className="already-have">
            Don't have an account yet? <b>Sign up</b>
          </Link>
        </div>
      </div>
      <div className="login-box">
        <span className="title">Login to your account</span>
        <div className="login-info">
          <div className="input-box">
            <span className="input-title">Email Address</span>
            <input
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              type="email"
              placeholder="youremail@gmail.com"
            />
          </div>
          <div className="input-box">
            <span className="input-title">Password</span>
            <div className="password">
              <input
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                type={isPasswordShown ? "text" : "password"}
                placeholder="••••••••"
              />
              {isPasswordShown ? (
                <AiOutlineEye
                  onClick={() => setIsPasswordShows(!isPasswordShown)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => setIsPasswordShows(!isPasswordShown)}
                />
              )}
            </div>
          </div>
          <div className="remember-me-forgot-password">
            <div className="remember-me">
              <input
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                type="checkbox"
              />
              <span>Remember me</span>
            </div>
            <span
              className="forgot-password"
              onClick={() => setForgotOpen(true)}
            >
              Forgot Password?
            </span>
          </div>
        </div>
        <button className="login" onClick={formik.handleSubmit}>
          Sign In
        </button>
      </div>
      <div className="footer">
        <Link to={"../sign-up"} className="already-have">
          Don't have an account yet? <b>Sign Up</b>
        </Link>
        <div className="terms-privacy-policy">
          <span onClick={() => setIsTermsOfServiceOpen(true)}>
            Terms of use
          </span>
          <span onClick={() => setIsPrivacyPolicyOpen(true)}>
            Privacy Policy
          </span>
          <span>Support</span>
        </div>
      </div>
    </div>
  );
};

export default GeneralAuthLogin;
