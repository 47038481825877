import { useEffect } from "react";
import "./TableRow.scss";

const TableRow = ({
  items,
  customStyle = false,
  customColor = false,
  fourth = false,
  click = () => {},
}) => {
  return (
    <div className="table-row-component">
      {items.map((item, index) => {
        return (
          <div
            key={`Row #${index + 1} of current table`}
            className={`row-container ${
              customColor && index % 2 === 0 ? "gray-row" : ""
            }`}
            style={{
              gridTemplateColumns:
                customStyle === false
                  ? `${items[0].map(() => "1fr ")}`.replaceAll(",", "")
                  : customStyle,
            }}
          >
            {item.map((element, i) => {
              return (
                <div
                  className={`row-item${fourth ? " fourth" : ""}`}
                  key={`Item #${i + 1} of row #${index + 1} of current table`}
                >
                  {element}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TableRow;
