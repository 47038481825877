import { useEffect } from "react";
import "./TableHeader.scss";

const TableHeader = ({
  titles,
  customColor = false,
  customStyle = false,
  fourth,
}) => {
  return (
    <div
      className={`table-header-component ${customColor ? "line-below" : ""}`}
      style={{
        gridTemplateColumns:
          customStyle === false
            ? `${titles.map((title) => "1fr ")}`.replaceAll(",", "")
            : customStyle,
      }}
    >
      {titles.map((title, index) => {
        return (
          <div
            className={`title-container${fourth ? " fourth" : ""}`}
            key={`Header #${index + 1} of current table`}
          >
            <span>{title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default TableHeader;
