import { coreService } from "./core.service";

const listOrders = (page, filters, callback) => {
  coreService.post(`order/list/${page}`, filters, callback);
};

const listOrdersTime = (page, time, callback) => {
  coreService.get(`order/list-time/${page}/${time}`, callback);
};

const getOverview = (callback) => {
  coreService.get(`order/overview`, callback);
};

const getOverviewCustomer = (callback) => {
  coreService.get(`order/overview-customer`, callback);
};

const getOverviewEditor = (callback) => {
  coreService.get(`order/overview-editor`, callback);
};

const getOrdersOfDay = (body, callback) => {
  coreService.post(`order/order-of-day`, body, callback);
};

const getDetails = (id, callback) => {
  coreService.get(`order/details/${id}`, callback);
};

const draft = (obj, callback) => {
  coreService.post(`order/draft`, obj, callback);
};

const create = (obj, callback) => {
  coreService.post(`order/create`, obj, callback);
};

const getReviews = (callback) => {
  coreService.get("order/reviews", callback);
};

const acceptSubmitionAdmin = (orderId, acceptance, feedback, callback) => {
  coreService.post(
    "order/accept-submition",
    { orderId, acceptance, feedback },
    callback
  );
};

const changeAcceptance = (
  editorId,
  acceptance,
  orderId,
  editorPay,
  callback
) => {
  coreService.put(
    `order/accept/${orderId}`,
    { editorId, acceptance, editorPay },
    callback
  );
};

const transferOrder = (editorId, orderId, callback) => {
  coreService.put(`order/transfer/${orderId}`, { editorId }, callback);
};

const cancelOrder = (orderId, callback) => {
  coreService.put(`order/cancel/${orderId}`, {}, callback);
};

const chatWithAdmin = (type, userId, callback) => {
  coreService.post(`order/chat`, { type, userId }, callback);
};

const completeOrder = (orderId, callback) => {
  coreService.post(`order/finish`, { orderId }, callback);
};

const addFile = (orderId, file, callback) => {
  coreService.post(`order/submit`, { orderId, file }, callback);
};

const addComment = (orderId, comment, callback) => {
  coreService.post(`order/comment`, { orderId, comment }, callback);
};

const acceptSubmition = (orderId, stars, review, callback) => {
  coreService.post(`order/review`, { orderId, stars, review }, callback);
};

const addRevision = (orderId, feedback, callback) => {
  coreService.post(`order/revision`, { orderId, feedback }, callback);
};

const getGraphs = (callback) => {
  coreService.get(`order/graphs`, callback);
};

export const orderService = {
  listOrders,
  getOverview,
  getOrdersOfDay,
  getDetails,
  getOverviewCustomer,
  create,
  getReviews,
  getOverviewEditor,
  changeAcceptance,
  transferOrder,
  cancelOrder,
  chatWithAdmin,
  completeOrder,
  addFile,
  addComment,
  acceptSubmition,
  addRevision,
  listOrdersTime,
  getGraphs,
  draft,
  acceptSubmitionAdmin,
};
