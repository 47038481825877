import './SixDigits.scss'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input'
import { useOutletContext } from 'react-router'

const AdminSixDigits = () => {
    const [time, setTime] = useState(30)
    const [setIsTermsOfServiceOpen, setIsPrivacyPolicyOpen] = useOutletContext()

    useEffect(() => {
        let interval = null;
        if (time > 0) {
            interval = setInterval(() => {
                setTime(time => time - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [time])

    return (
        <div className='auth-admin-six-digits'>
            <div className='login-box'>
                <span className='title'>Enter the 6-digits Code</span>
                <span className='subtitle'>We have sent you a verification email to <b>alice@gmail.com. Click and follow the link.</b></span>
                <div className='login-info'>
                    <ReactCodeInput className='code-input' type='number' fields={6} />
                </div>
                {time > 0 ? (
                    <span className='time'>Resend code in 00:{time > 9 ? time : `0${time}`}</span>
                ) : (
                    <button className='login' onClick={() => setTime(30)}>Resend Code</button>
                )}
                <button className='login'>Recovery</button>
                <span className='return'>Return to recovery page</span>
            </div>
            <div className='footer'>
                <span className='already-have'>Already have an account? <b>Sign in</b></span>
                <div className='terms-privacy-policy'>
                    <span onClick={() => setIsTermsOfServiceOpen(true)}>Terms of use</span>
                    <span onClick={() => setIsPrivacyPolicyOpen(true)}>Privacy Policy</span>
                    <span>Support</span>
                </div>
            </div>
        </div>
    )
}

export default AdminSixDigits