import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./AllEditors.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useNavigate } from "react-router";
import { clientService } from "../../../services/client.service";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AllEditors = ({ editors, count, getEditors }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentEditors, setCurrentEditors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getEditors(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentEditors(editors);
  }, [editors]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToPage = (index) => {
    navigate(`./${currentEditors[index].id}`);
  };

  const formatCurrency = (amount) => {
    let newAmount = "";
    let arrCount = 0;
    let amountToCheck = `${parseFloat(amount).toFixed(0)}`;
    const arr = `${amountToCheck}`.split("").reverse();
    arr.map((letter) => {
      arrCount++;
      newAmount = `${letter}${newAmount}`;
      if (arrCount !== arr.length && arrCount % 3 === 0) {
        newAmount = `,${newAmount}`;
      }
    });

    let result = `$${newAmount}.00`;

    return result;
  };

  return (
    <div className="all-editors-component">
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            customStyle=".5fr 1fr .5fr 2fr .5fr .5fr 1fr .5fr"
            titles={[
              "Photo",
              "Name",
              "Level",
              "Email",
              "Total Orders",
              "In Progress",
              "Received Money",
              "",
            ]}
          />
          <div className="editors-table">
            <TableRow
              customStyle=".5fr 1fr .5fr 2fr .5fr .5fr 1fr .5fr"
              click={goToPage}
              items={currentEditors.map((editor, index) => {
                return [
                  <div
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden",
                      height: "45px",
                      width: "45px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "45px" }}
                      src={
                        editor.profilePicture
                          ? editor.profilePicture.url
                          : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                      }
                    />
                  </div>,
                  editor.firstName ? (
                    <span
                      style={{
                        fontWeight: 500,
                        color: editor.blocked ? "red" : "black",
                      }}
                    >
                      {editor.firstName} {editor.lastName}
                      {editor.blocked ? " (Blocked)" : ""}
                    </span>
                  ) : (
                    <span
                      style={{
                        fontWeight: 500,
                        color: editor.blocked ? "red" : "black",
                      }}
                    >
                      New editor{editor.blocked ? " (Blocked)" : ""}
                    </span>
                  ),
                  <div
                    style={{
                      padding: ".5em 0",
                      width: "30%",
                      fontSize: ".8em",
                      fontWeight: 500,
                      borderRadius: "8px",
                      backgroundColor: "#D8FFEE",
                      color: "#00D097",
                    }}
                  >
                    <span>{editor.level}</span>
                  </div>,
                  <span
                    style={{
                      fontWeight: 400,
                      color: "#A0AEC7",
                      fontSize: ".8em",
                    }}
                  >
                    {editor.email}
                  </span>,
                  <div
                    style={{
                      padding: ".5em 0",
                      width: "30%",
                      fontSize: ".8em",
                      fontWeight: 500,
                      borderRadius: "8px",
                      backgroundColor: "#F6F8B6",
                      color: "#EEC800",
                    }}
                  >
                    <span>{editor.completedVideos}</span>
                  </div>,
                  <div
                    style={{
                      padding: ".5em 0",
                      width: "30%",
                      fontSize: ".8em",
                      fontWeight: 500,
                      borderRadius: "8px",
                      backgroundColor: "#FFEFD9",
                      color: "#FFB756",
                    }}
                  >
                    <span>{editor.progressVideos}</span>
                  </div>,
                  <span style={{ fontWeight: 500 }}>
                    {formatCurrency(editor.balanceHistory)}
                  </span>,
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      position: "relative",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      onClick={() => {
                        const obj = currentEditors[index];
                        obj.open = !obj.open;
                        setCurrentEditors([
                          ...currentEditors.slice(0, index),
                          obj,
                          ...currentEditors.slice(index + 1),
                        ]);
                      }}
                      style={{
                        position: "absolute",
                        backgroundColor: "#F2F5FA",
                        width: "30px",
                        height: "30px",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "1.2em",
                        borderRadius: "7px",
                        cursor: "pointer",
                      }}
                    >
                      <AiOutlineMore />
                    </div>
                    {editor.open && (
                      <div
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "white",
                          position: "absolute",
                          top: 40,
                          right: 0,
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "space-between",
                          boxShadow: "0 6px 40px 0 #7991AD4D",
                          padding: ".3em",
                        }}
                      >
                        <div
                          onClick={() => navigate(`./${editor.id}`)}
                          style={{
                            fontWeight: "600",
                            padding: "0 .5em",
                            marginRight: ".5em",
                            backgroundColor: "#F4F9FD",
                            height: "30px",
                            flexWrap: "nowrap",
                            whiteSpace: "nowrap",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: ".8em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Show More
                        </div>
                        {!editor.blocked ? (
                          <div
                            onClick={() => {
                              clientService.changeBlock(
                                editor.id,
                                true,
                                (data) => {
                                  getEditors(1);
                                }
                              );
                            }}
                            style={{
                              fontWeight: "600",
                              padding: "0 .5em",
                              marginRight: ".5em",
                              backgroundColor: "#F4F9FD",
                              height: "30px",
                              flexWrap: "nowrap",
                              whiteSpace: "nowrap",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: ".8em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            Block Editor
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              clientService.changeBlock(
                                editor.id,
                                false,
                                (data) => {
                                  getEditors(1);
                                }
                              );
                            }}
                            style={{
                              fontWeight: "600",
                              padding: "0 .5em",
                              marginRight: ".5em",
                              backgroundColor: "#F4F9FD",
                              height: "30px",
                              flexWrap: "nowrap",
                              whiteSpace: "nowrap",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: ".8em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            Unblock Editor
                          </div>
                        )}
                      </div>
                    )}
                  </div>,
                ];
              })}
            />
          </div>
        </>
      ) : (
        <>
          {currentEditors.map((editor, index) => {
            return (
              <div className="editor-mobile">
                <div className="header-mobile">
                  <div
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden",
                      height: "45px",
                      width: "45px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "45px" }}
                      src={
                        editor.profilePicture
                          ? editor.profilePicture.url
                          : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                      }
                    />
                  </div>
                  <div className="info">
                    <div className="name-email">
                      <span>
                        {editor.firstName} {editor.lastName}
                      </span>
                      <span>{editor.email}</span>
                    </div>
                    <span>{editor.balance}</span>
                  </div>
                </div>
                <div className="body-mobile">
                  <div className="item">
                    <span className="title">Level</span>
                    <span className="content-item-mobile">{editor.level}</span>
                  </div>
                  <div className="item">
                    <span className="title">
                      Orders
                      <br />
                      Done
                    </span>
                    <span className="content-item-mobile">
                      {editor.completedVideos}
                    </span>
                  </div>
                  <div className="item">
                    <span className="title">
                      Orders in
                      <br />
                      Progress
                    </span>
                    <span className="content-item-mobile">
                      {editor.progressVideos}
                    </span>
                  </div>
                  <div className="item more">
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        position: "relative",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          const obj = currentEditors[index];
                          obj.open = !obj.open;
                          setCurrentEditors([
                            ...currentEditors.slice(0, index),
                            obj,
                            ...currentEditors.slice(index + 1),
                          ]);
                        }}
                        style={{
                          position: "absolute",
                          backgroundColor: "#F2F5FA",
                          width: "30px",
                          height: "30px",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "1.2em",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                      >
                        <AiOutlineMore />
                      </div>
                      {editor.open && (
                        <div
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            position: "absolute",
                            top: 40,
                            right: 0,
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxShadow: "0 6px 40px 0 #7991AD4D",
                            padding: ".3em",
                          }}
                        >
                          <div
                            onClick={() => navigate(`./${editor.id}`)}
                            style={{
                              fontWeight: "600",
                              padding: "0 .5em",
                              marginRight: ".5em",
                              backgroundColor: "#F4F9FD",
                              height: "30px",
                              flexWrap: "nowrap",
                              whiteSpace: "nowrap",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: ".8em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            Show More
                          </div>
                          {!editor.blocked ? (
                            <div
                              onClick={() => {
                                clientService.changeBlock(
                                  editor.id,
                                  true,
                                  (data) => {
                                    getEditors(1);
                                  }
                                );
                              }}
                              style={{
                                fontWeight: "600",
                                padding: "0 .5em",
                                marginRight: ".5em",
                                backgroundColor: "#F4F9FD",
                                height: "30px",
                                flexWrap: "nowrap",
                                whiteSpace: "nowrap",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                fontSize: ".8em",
                                borderRadius: "7px",
                                cursor: "pointer",
                              }}
                            >
                              Block Editor
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                clientService.changeBlock(
                                  editor.id,
                                  false,
                                  (data) => {
                                    getEditors(1);
                                  }
                                );
                              }}
                              style={{
                                fontWeight: "600",
                                padding: "0 .5em",
                                marginRight: ".5em",
                                backgroundColor: "#F4F9FD",
                                height: "30px",
                                flexWrap: "nowrap",
                                whiteSpace: "nowrap",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                fontSize: ".8em",
                                borderRadius: "7px",
                                cursor: "pointer",
                              }}
                            >
                              Unblock Editor
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {count > currentPage * 6 ? currentPage * 6 : count} of {count}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={count < currentPage * 6 ? "disable" : "active"}
            onClick={nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div>
    </div>
  );
};

export default AllEditors;
