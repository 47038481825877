import AllEditors from "../../../../components/editors/allEditors/AllEditors"
import "./Editors.scss"
import { useState } from "react"
import { BiFilterAlt } from 'react-icons/bi'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { AiOutlineClose, AiFillInfoCircle } from 'react-icons/ai'
import Client1 from '../../../../assets/client1.png'
import Client2 from '../../../../assets/client2.png'
import Client3 from '../../../../assets/client3.png'
import Client4 from '../../../../assets/client4.png'
import Client5 from '../../../../assets/client5.png'
import NewEditors from "../../../../components/editors/newEditors/NewEditors"

const CustomerEditors = () => {
    const [currentPage, setCurrentPage] = useState('All Editors')
    const [isFiltersOpen, setIsFiltersOpen] = useState(false)
    const [isShowingMore, setIsShowingMore] = useState(false)

    const [status, setStatus] = useState([
        {
            name: 'Filter name 1',
            selected: false
        },
        {
            name: 'Filter name 2',
            selected: false
        },
        {
            name: 'Filter name 3',
            selected: false
        },
        {
            name: 'Filter name 4',
            selected: false
        },
        {
            name: 'Filter name 5',
            selected: false
        },
    ])

    const [clients, setClients] = useState([
        {
            name: 'Oscar Holloway',
            image: Client1,
            selected: false
        },
        {
            name: 'Leonard Rodriquez',
            image: Client2,
            selected: false
        },
        {
            name: 'Owen Chambers',
            image: Client3,
            selected: false
        },
        {
            name: 'Gabriel Flowers',
            image: Client4,
            selected: false
        },
        {
            name: 'Violet Robbins',
            image: Client5,
            selected: false
        },
    ])

    return (
        <div className="admin-layout-editors-page">
            {isFiltersOpen && <div className="filters">
                <div className="filters-container">
                    <div className="filters-header">
                        <span className="title">Filters</span>
                        <div className="icon" onClick={() => setIsFiltersOpen(false)}>
                            <AiOutlineClose />
                        </div>
                    </div>
                    <div className="filters-body">
                        <div className="period">
                            <span className="title">Period</span>
                            <input placeholder="Select Period" type="text" onFocus={(e) => {
                                e.currentTarget.type = "date";
                            }} onBlur={(e) => {
                                e.currentTarget.type = "text";
                                e.currentTarget.placeholder = "Select Period";
                            }} />
                        </div>
                        <div className="status">
                            <span className="title">Status</span>
                            <div className="selects">
                                {status.map((stats, index) => {
                                    return (
                                        <div className="select-item">
                                            <input type='checkbox' value={stats.selected} onChange={(e) => {
                                                const obj = stats
                                                obj.selected = e.target.checked
                                                setStatus([
                                                    ...status.slice(0, index),
                                                    obj,
                                                    ...status.slice(index + 1)
                                                ])
                                            }} />
                                            <span>{stats.name}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="clients">
                            <span className="title">Clients</span>
                            <div className="selects">
                                {(isShowingMore ? clients : clients.slice(0,4)).map((client, index) => {
                                    return (
                                        <div className="select-item">
                                            <input type='checkbox' value={client.selected} onChange={(e) => {
                                                const obj = client
                                                obj.selected = e.target.checked
                                                setClients([
                                                    ...clients.slice(0, index),
                                                    obj,
                                                    ...clients.slice(index + 1)
                                                ])
                                            }} />
                                            <div className="image">
                                                <img src={client.image}/>
                                            </div>
                                            <span>{client.name}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <span onClick={() => setIsShowingMore(!isShowingMore)} className="show-more">View more {isShowingMore ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
                        </div>
                    </div>
                    <div className="filters-footer">
                        <span><AiFillInfoCircle color="#3F8CFF"/> 10 matches found</span>
                        <button onClick={() => setIsFiltersOpen(false)}>Save Filters</button>
                    </div>
                </div>
            </div>}
            <div className="header">
                <div className="types">
                    <div
                        onClick={() => setCurrentPage('All Editors')}
                        className={`${currentPage === 'All Editors' ? 'active' : ''}`}>
                        All Editors
                    </div>
                    <div
                        onClick={() => setCurrentPage('New Editors')}
                        className={`${currentPage === 'New Editors' ? 'active' : ''}`}>
                        New Editors
                    </div>
                </div>
                <div className="icon" onClick={() => setIsFiltersOpen(true)}>
                    <BiFilterAlt />
                </div>
            </div>
            {currentPage === 'All Editors' && <AllEditors />}
            {currentPage === 'New Editors' && <NewEditors />}
        </div>
    )
}

export default CustomerEditors