import axios from "axios";

export const getCountries = async (callback) => {
  var config = {
    method: "get",
    url: "https://api.countrystatecity.in/v1/countries",
    headers: {
      "X-CSCAPI-KEY":
        "d1lSVGlRZEZEZmowQ0RRS1JtQ1NkSldYOFNCbEt6RkVqNWRtcExTOQ==",
    },
  };

  await axios(config)
    .then((data) => {
      callback(data.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getStates = async (country, callback) => {
  var config = {
    method: "get",
    url: `https://api.countrystatecity.in/v1/countries/${country}/states`,
    headers: {
      "X-CSCAPI-KEY":
        "d1lSVGlRZEZEZmowQ0RRS1JtQ1NkSldYOFNCbEt6RkVqNWRtcExTOQ==",
    },
  };

  await axios(config)
    .then((data) => {
      callback(data.data);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const getCities = async (country, state, callback) => {
  var config = {
    method: "get",
    url: `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
    headers: {
      "X-CSCAPI-KEY":
        "d1lSVGlRZEZEZmowQ0RRS1JtQ1NkSldYOFNCbEt6RkVqNWRtcExTOQ==",
    },
  };

  await axios(config)
    .then((data) => {
      callback(data.data);
    })
    .catch((e) => {
      console.log(e);
    });
};
