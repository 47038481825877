import "./Editor.scss"
import Josias from '../../../../../assets/josias.png'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { FiEdit } from "react-icons/fi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import Portfolio from '../../../../../assets/portfolio.png'
import Youtube from '../../../../../assets/youtube.png'
import Facebook from '../../../../../assets/facebook.png'
import Instagram from '../../../../../assets/instagram.png'
import Twitter from '../../../../../assets/twitter.png'
import Tiktok from '../../../../../assets/tiktok.png'
import Linkedin from '../../../../../assets/linkedin.png'
import Snapchat from '../../../../../assets/snapchat.png'
import Website from '../../../../../assets/website.png'
import { Link } from 'react-router-dom';

const CustomerEditor = () => {
    const networks = [
        {
            name: "Youtube",
            img: Youtube,
        },
        {
            name: "Facebook",
            img: Facebook,
        },
        {
            name: "Instagram",
            img: Instagram,
        },
        {
            name: "Twitter",
            img: Twitter,
        },
        {
            name: "Tiktok",
            img: Tiktok,
        },
        {
            name: "Linkedin",
            img: Linkedin,
        },
        {
            name: "Snapchat",
            img: Snapchat,
        },
        {
            name: "Personal Website",
            img: Website,
        },
    ]
    return (
        <div className='admin-layout-editor-details-page'>
            <div className='header-main'>
                <Link to="../editors" className="link-back" style={{ textDecoration: 'none' }}>
                    <AiOutlineArrowLeft size={20} color="#2A9DF4" />
                    <span>Back to Editors</span>
                </Link>
                <button className='chat'>
                    Chat with Editor
                </button>
            </div>
            <div className='profile-picture-info'>
                <div className='image-circle'>
                    <CircularProgressbar value={60} className="circular-level-bar" strokeWidth={2} counterClockwise={true}
                        styles={buildStyles({
                            trailColor: `#F2F5FA`,
                            pathColor: '#2A9DF4',
                            backgroundColor: 'white',
                        })} />,
                    <div className='image'>
                        <img src={Josias} />
                    </div>
                </div>
                <div className="profile-details">
                    <span className='name'>Josias Lunkes</span>
                    <span className='title'>Editor Level 6</span>
                    <div className='stats'>
                        <div className='item'>
                            <span className='number'>$5</span>
                            <span className='text'>per minute<br />of provided footage</span>
                        </div>
                        <div className='item'>
                            <span className='number'>14</span>
                            <span className='text'>Video<br />In Progress</span>
                        </div>
                        <div className='item'>
                            <span className='number'>56</span>
                            <span className='text'>Video<br />In Complite</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-container-editor">
                <div className="bio-network">
                    <div className="bio">
                        <span className="title">
                            Bio Info
                        </span>
                        <span className='text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus pellentesque morbi nunc, mauris. Nisl sagittis in eget mauris aliquet enim iaculis tellus aenean. Id morbi at molestie ultrices ultrices diam vulputate sit luctus. Ut varius velit at rhoncus cursus. Mauris ullamcorper viverra eu vel diam, adipiscing. Quisque scelerisque iaculis pellentesque interdum purus. Tempus cras fermentum turpis donec urna est. Nulla a cursus nam sit congue in. Sit neque pellentesque.</span>
                    </div>
                    <div className="Networking">
                        <span className="title">
                            Networking Info
                        </span>
                        <div className="networks">
                            {networks.map((network) => {
                                return (
                                    <div className='social-network'>
                                        <img src={network.img} />
                                        <span style={{ marginLeft: '.5em' }}>{network.name}</span>
                                        <div className='arrow'>
                                            <AiOutlineArrowRight />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="portfolio">
                    <span className="title">
                        Portfolio Editor
                    </span>
                    <div className="images">
                        <img src={Portfolio} />
                        <img src={Portfolio} />
                        <img src={Portfolio} />
                        <img src={Portfolio} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerEditor