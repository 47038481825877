import UploadVideos from "./steps/uploadVideos/UploadVideos";
import BasicDetails from "./steps/basicDetails/BasicDetails";
import VideoElements from "./steps/videoElements/VideoElements";
import Thumbnail from "./steps/thumbnail/Thumbnail";
import Music from "./steps/music/Music";
import Intro from "./steps/intro/Intro";
import Outro from "./steps/outro/Outro";
import ConsultationCall from "./steps/consultationCall/ConsultationCall";
import Logo from "./steps/logo/Logo";
import Banner from "./steps/banner/Banner";
import StockFootage from "./steps/stockFootage/StockFootage";
import AdditionalInstruction from "./steps/additionalInstructions/AdditionalInstructions";
import AddOns from "./steps/addOns/AddOns";
import Quote from "./steps/quote/Quote";

export const orderSteps = (
  itemsAdded = [],
  step = 0,
  changeComponent = () => {},
  validate = () => {},
  nextStep = () => {},
  prevStep = () => {},
  user,
  order = {}
) => {
  const arr = [
    {
      name: "Upload Videos",
      component: (
        <UploadVideos
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          user={user}
          order={order}
        />
      ),
      finished: false,
      id: "videos",
      default: false,
      dbName: "fileRequired",
    },
    {
      name: "Basic Details",
      component: (
        <BasicDetails
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          user={user}
          order={order}
        />
      ),
      finished: false,
      id: "videos",
      default: false,
      dbName: "basicDetails",
    },
    {
      name: "Video Elements",
      component: (
        <VideoElements
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          user={user}
          order={order}
        />
      ),
      finished: false,
      id: "videos",
      default: false,
      dbName: "videoElements",
    },
    {
      name: "Thumbnail",
      component: (
        <Thumbnail
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          user={user}
          order={order}
        />
      ),
      finished: false,
      id: "thumbnails",
      default: false,
      dbName: "thumbnail",
    },
    {
      name: "Music",
      component: (
        <Music
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          user={user}
          order={order}
        />
      ),
      finished: false,
      id: "videos",
      default: false,
      dbName: "music",
    },
    {
      name: "Personalized Intro",
      component: (
        <Intro
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          added={itemsAdded.includes("intro")}
          user={user}
          order={order}
        />
      ),
      finished: false,
      id: "intro",
      default: true,
      dbName: "intro",
    },
    {
      name: "Personalized Outro",
      component: (
        <Outro
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          added={itemsAdded.includes("outro")}
          user={user}
          order={order}
        />
      ),
      finished: false,
      id: "outro",
      default: true,
      dbName: "outro",
    },
    {
      name: "Consultation Call",
      component: (
        <ConsultationCall
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          user={user}
          order={order}
        />
      ),
      finished: false,
      id: "consultationCall",
      default: false,
      dbName: "consultationCall",
    },
    {
      name: "Logo",
      component: (
        <Logo
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          user={user}
          order={order}
        />
      ),
      finished: false,
      id: "logo",
      default: false,
      dbName: "logo",
    },
    {
      name: "Banner",
      component: (
        <Banner
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          user={user}
          order={order}
        />
      ),
      finished: false,
      id: "banner",
      default: false,
      dbName: "banner",
    },
    // {
    //   name: "Stock Footage",
    //   component: (
    //     <StockFootage
    //       changeComponent={changeComponent}
    //       validate={validate}
    //       nextStep={nextStep}
    //       prevStep={prevStep}
    //       user={user}
    //     />
    //   ),
    //   finished: false,
    //   id: "unlimitedStockFootage",
    //   default: false,
    //   dbName: "stockFootages",
    // },
    {
      name: "Additional Instructions",
      component: (
        <AdditionalInstruction
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          user={user}
          order={order}
        />
      ),
      finished: false,
      default: true,
      dbName: "additionalInstructions",
    },
    // {
    //   name: "Add-ons",
    //   component: (
    //     <AddOns
    //       changeComponent={changeComponent}
    //       validate={validate}
    //       nextStep={nextStep}
    //       prevStep={prevStep}
    //       user={user}
    //     />
    //   ),
    //   finished: false,
    //   default: true,
    //   dbName: "addOn",
    // },
    {
      name: "Review Order",
      component: (
        <Quote
          itemsAdded={itemsAdded}
          changeComponent={changeComponent}
          validate={validate}
          nextStep={nextStep}
          prevStep={prevStep}
          step={step}
          user={user}
          order={order}
        />
      ),
      finished: false,
      default: true,
      dbName: "quote",
    },
  ];
  const result = arr.filter(
    (item) => item.default || itemsAdded.includes(item.id)
  );
  return result;
};
