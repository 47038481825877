import { RiAttachment2 } from "react-icons/ri";

const download = (url) => {
  window.open(url, "_blank").focus();
};

export const createHTMLOrderPrint = (order) => {
  return [
    {
      name: "Files Required",
      shown: order.fileRequired && order.fileRequired !== null,
      item: order.fileRequired
        ? order.fileRequired.files && order.fileRequired.files.length
          ? order.fileRequired.files.map((file) => {
              return file.originalName;
            })
          : [order.fileRequired.link]
        : null,
      open: false,
    },
    {
      name: "Basic Details",
      shown: order.basicDetails && order.basicDetails !== null,
      item: order.basicDetails
        ? [
            `Title: ${
              order.basicDetails
                ? order.basicDetails.title
                : `Order #${order.number}`
            }`,
            `Delivery Date: ${order.basicDetails.deliveryTime}`,
            `Type: ${order.basicDetails.type}`,
            `Where to post: ${order.basicDetails.postLocation}`,
            `Names: ${order.basicDetails.names}`,
          ]
        : null,
      open: false,
    },
    {
      name: "Video Elements",
      shown: order.videoElements && order.videoElements !== null,
      item: order.videoElements
        ? [
            `Text: ${order.videoElements.text}`,
            `Jump Cuts: ${order.videoElements.jumpCuts}`,
            `Low Third: ${order.videoElements.lowThird}`,
            `Transition: ${order.videoElements.transition}`,
          ]
        : null,
      open: false,
    },
    {
      name: "Thumbnail",
      shown: order.thumbnail && order.thumbnail !== null,
      item: order.thumbnail ? [""] : null,
      open: false,
    },
    {
      name: "Music",
      shown: order.music && order.music !== null,
      item: order.music ? [""] : null,
      open: false,
    },
    {
      name: "Personalized Intro",
      shown: order.intro && order.intro !== null,
      item: order.intro ? [""] : null,
      open: false,
    },
    {
      name: "Personalized Outro",
      shown: order.outro && order.outro !== null,
      item: order.outro ? [""] : null,
      open: false,
    },
    {
      name: "Consultation Call",
      shown: order.consultationCall && order.consultationCall !== null,
      item: order.consultationCall ? [""] : null,
      open: false,
    },
    {
      name: "Logo",
      shown: order.logo && order.logo !== null,
      item: order.logo ? [""] : null,
      open: false,
    },
    {
      name: "Banner",
      shown: order.banner && order.banner !== null,
      item: order.banner ? [""] : null,
      open: false,
    },
    // {
    //   name: "Stock Footage",
    //   item: (
    //     <div className="item">
    //       {order.stockFootages.map((stockFootage) => {
    //         return (
    //           <div className="footage">
    //             <img src={stockFootage.image.url} />
    //             <div className="comments">
    //               {stockFootage.comments.map((comment) => {
    //                 return <span>{comment.comment}</span>;
    //               })}
    //             </div>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   ),
    //   open: false,
    // },
    {
      name: "Additional Instructions",
      shown:
        order.additionalInstructions && order.additionalInstructions !== null,
      item: order.additionalInstructions
        ? [order.additionalInstructions.somethingElse]
        : null,
      open: false,
    },
    // {
    //   name: "Add-Ons",
    //   item: (
    //     <div className="item">
    //       <div className="text-field">
    //         <span>
    //           <b>Banner: </b>
    //           {order.addOn.banner ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Unlimited Stock Footage: </b>
    //           {order.addOn.unlimitedStockFootage ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Logo: </b>
    //           {order.addOn.logo ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Outro: </b>
    //           {order.addOn.outro ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Thumbnail: </b>
    //           {order.addOn.thumbnail ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Consultation Call: </b>
    //           {order.addOn.consultationCall ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Intro: </b>
    //           {order.addOn.intro ? "Yes" : "No"}
    //         </span>
    //       </div>
    //     </div>
    //   ),
    //   open: false,
    // },
  ];
};
