const currentStep = (user, step) => {
  const steps = [
    {
      title: `Welcome ${user.firstName}!`,
      text: "To get you started, we can show you around our Dashboard where you will be placing all orders, or if you’d like to explore on your own feel free to skip the tutorial.",
    },
    {
      title: `Let’s Choose a Package`,
      text: "Click on the ‘Packages’ button on the left navigation menu highlighted in a dashed, blue box.",
    },
  ];

  if (step <= steps.length) {
    return steps[step - 1];
  } else {
    return false;
  }
};

export const onBoardingStepsPackagePlugin = {
  currentStep,
};
