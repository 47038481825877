import User1 from "../../assets/user1.png";
import ProgressBar from "../progressBar/ProgressBar";
import "./ProfileDashboard.scss";

const ProfileDashboard = ({ user, level, toNextLevel }) => {
  return (
    <div className="profile-dashboard-component">
      <div className="upper">
        <div className="image-container">
          <img src={user} />
        </div>
        <div className="name-title">
          <h5>Level {level}</h5>
          <div className="progress-bar-level">
            <ProgressBar completed={toNextLevel * 10} />
            <span>You’re doing awesome</span>
          </div>
        </div>
      </div>
      <div className="lower">
        <span>
          Complete {toNextLevel} more orders to enter Level 2<br />
          editors club
        </span>
      </div>
    </div>
  );
};

export default ProfileDashboard;
