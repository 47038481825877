import { useState, useEffect } from "react";
import "./AdditionalInstructions.scss";

const AdditionalInstruction = ({
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  user,
  order,
}) => {
  const [somethingElse, setSomethingElse] = useState("");

  useEffect(() => {
    if (order.additionalInstruction) {
      if (
        order.additionalInstruction.somethingElse &&
        order.additionalInstruction.somethingElse.length
      ) {
        setSomethingElse(order.additionalInstruction.somethingElse);
      }
    }
  }, [order]);

  const changeStep = (isStepNext) => {
    if (validateFields()) {
      changeComponent("additionalInstructions", {
        somethingElse,
      });
      if (isStepNext) {
        nextStep();
      } else {
        prevStep();
      }
    } else {
      validate("Please check that you added all required information");
    }
  };

  const validateFields = () => {
    return true;
  };

  return (
    <div className="additional-instructions-new-order">
      <div className="inner-content">
        <div className="item">
          <span className="title-item">
            Is there anything else you feel like we should know?
          </span>
          <textarea
            value={somethingElse}
            onChange={(e) => setSomethingElse(e.target.value)}
            placeholder="Type here"
          />
        </div>
      </div>
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}

        <button
          className="next"
          onClick={() => {
            changeStep(true);
          }}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default AdditionalInstruction;
