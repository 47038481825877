import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./NewEditors.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useNavigate } from "react-router";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const NewEditors = ({ editors, count, getEditors, changeAcceptance }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentEditors, setCurrentEditors] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getEditors(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentEditors(editors);
  }, [editors]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToPage = (index) => {
    navigate(`./${currentEditors[index].status}`);
  };

  return (
    <div className="new-editors-component">
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            customStyle=".3fr 1fr 1fr 1fr 1fr 1fr .5fr"
            titles={["Photo", "Name", "Email", "Skills", "Bio", "", ""]}
          />
          <div className="editors-table">
            <TableRow
              customStyle=".3fr 1fr 1fr 1fr 1fr 1fr .5fr"
              click={goToPage}
              items={currentEditors.map((editor, index) => {
                return [
                  <div
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden",
                      height: "45px",
                      width: "45px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "45px" }}
                      src={
                        editor.profilePicture
                          ? editor.profilePicture.url
                          : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                      }
                    />
                  </div>,
                  editor.firstName ? (
                    <span style={{ fontWeight: 500 }}>
                      {editor.firstName} {editor.lastName}
                    </span>
                  ) : (
                    <span style={{ fontWeight: 500 }}>New editor</span>
                  ),
                  <span
                    style={{
                      fontWeight: 400,
                      color: "#A0AEC7",
                      fontSize: ".8em",
                    }}
                  >
                    {editor.email}
                  </span>,
                  editor.firstName ? (
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: ".8em",
                        textAlign: "start",
                      }}
                    >
                      {editor.skills}
                    </span>
                  ) : (
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: ".8em",
                        textAlign: "start",
                      }}
                    >
                      -
                    </span>
                  ),
                  editor.firstName ? (
                    <span
                      style={{
                        fontWeight: 400,
                        color: "#A0AEC7",
                        fontSize: ".8em",
                        textAlign: "start",
                      }}
                    >
                      {editor.bio}
                    </span>
                  ) : (
                    <span
                      style={{
                        fontWeight: 400,
                        color: "#A0AEC7",
                        fontSize: ".8em",
                        textAlign: "start",
                      }}
                    >
                      -
                    </span>
                  ),
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      alignItems: "stretch",
                      justifyContent: "space-between",
                      width: "80%",
                    }}
                  >
                    <button
                      onClick={() => {
                        changeAcceptance(editor.id, true, index);
                      }}
                      style={{
                        cursor: "pointer",
                        padding: ".7em 1.5em",
                        fontSize: ".8em",
                        color: "white",
                        fontWeight: "500",
                        border: 0,
                        borderRadius: "20px",
                        backgroundColor: "#2A9DF4",
                        marginBottom: ".25em",
                      }}
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => {
                        changeAcceptance(editor.id, false, index);
                      }}
                      style={{
                        cursor: "pointer",
                        padding: ".7em 1.5em",
                        fontSize: ".8em",
                        color: "#DA4747",
                        fontWeight: "500",
                        border: 0,
                        borderRadius: "20px",
                        backgroundColor: "#FFE0D9",
                        marginTop: ".25em",
                      }}
                    >
                      Reject
                    </button>
                  </div>,
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      position: "relative",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      onClick={() => {
                        const obj = currentEditors[index];
                        obj.open = !obj.open;
                        setCurrentEditors([
                          ...currentEditors.slice(0, index),
                          obj,
                          ...currentEditors.slice(index + 1),
                        ]);
                      }}
                      style={{
                        position: "absolute",
                        backgroundColor: "#F2F5FA",
                        width: "30px",
                        height: "30px",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "1.2em",
                        borderRadius: "7px",
                        cursor: "pointer",
                      }}
                    >
                      <AiOutlineMore />
                    </div>
                    {editor.open && (
                      <div
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "white",
                          position: "absolute",
                          top: 40,
                          right: 0,
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "space-between",
                          boxShadow: "0 6px 40px 0 #7991AD4D",
                          padding: ".3em",
                        }}
                      >
                        <div
                          onClick={() => navigate(`./${editor.id}`)}
                          style={{
                            fontWeight: "600",
                            padding: "0 .5em",
                            marginRight: ".5em",
                            backgroundColor: "#F4F9FD",
                            height: "30px",
                            flexWrap: "nowrap",
                            whiteSpace: "nowrap",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: ".8em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Show More
                        </div>
                        <div
                          style={{
                            marginLeft: ".5em",
                            backgroundColor: "#F6516023",
                            width: "30px",
                            height: "30px",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: "1.2em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          <FiTrash2 color="#E74C3C" />
                        </div>
                      </div>
                    )}
                  </div>,
                ];
              })}
            />
          </div>
        </>
      ) : (
        <>
          {currentEditors.map((editor, index) => {
            return (
              <div className="editor-mobile">
                <div className="header-mobile">
                  <div
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden",
                      height: "45px",
                      width: "45px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "45px" }}
                      src={
                        editor.profilePicture
                          ? editor.profilePicture.url
                          : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                      }
                    />
                  </div>
                  <div className="info">
                    <div className="name-email">
                      <span>
                        {editor.firstName} {editor.lastName}
                      </span>
                      <span>{editor.email}</span>
                    </div>
                  </div>
                </div>
                <div className="body-mobile">
                  <div className="item buttons">
                    <button
                      onClick={() => {
                        changeAcceptance(editor.id, true, index);
                      }}
                      style={{
                        cursor: "pointer",
                        padding: ".7em 1.5em",
                        fontSize: ".8em",
                        color: "white",
                        fontWeight: "500",
                        border: 0,
                        borderRadius: "20px",
                        backgroundColor: "#2A9DF4",
                        marginBottom: ".25em",
                      }}
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => {
                        changeAcceptance(editor.id, false, index);
                      }}
                      style={{
                        cursor: "pointer",
                        padding: ".7em 1.5em",
                        fontSize: ".8em",
                        color: "#DA4747",
                        fontWeight: "500",
                        border: 0,
                        borderRadius: "20px",
                        backgroundColor: "#FFE0D9",
                        marginTop: ".25em",
                      }}
                    >
                      Reject
                    </button>
                  </div>
                  <div className="item more">
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        position: "relative",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          const obj = currentEditors[index];
                          obj.open = !obj.open;
                          setCurrentEditors([
                            ...currentEditors.slice(0, index),
                            obj,
                            ...currentEditors.slice(index + 1),
                          ]);
                        }}
                        style={{
                          position: "absolute",
                          backgroundColor: "#F2F5FA",
                          width: "30px",
                          height: "30px",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "1.2em",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                      >
                        <AiOutlineMore />
                      </div>
                      {editor.open && (
                        <div
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            position: "absolute",
                            top: 40,
                            right: 0,
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxShadow: "0 6px 40px 0 #7991AD4D",
                            padding: ".3em",
                          }}
                        >
                          <div
                            onClick={() => navigate(`./${editor.id}`)}
                            style={{
                              fontWeight: "600",
                              padding: "0 .5em",
                              marginRight: ".5em",
                              backgroundColor: "#F4F9FD",
                              height: "30px",
                              flexWrap: "nowrap",
                              whiteSpace: "nowrap",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: ".8em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            Show More
                          </div>
                          <div
                            style={{
                              marginLeft: ".5em",
                              backgroundColor: "#F6516023",
                              width: "30px",
                              height: "30px",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "1.2em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            <FiTrash2 color="#E74C3C" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {count > currentPage * 6 ? currentPage * 6 : count} of {count}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={count < currentPage * 6 ? "disable" : "active"}
            onClick={nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewEditors;
