import { useEffect, useState } from "react";
import { FaCloudUploadAlt, FaRegTrashAlt } from "react-icons/fa";
import { AiFillFile } from "react-icons/ai";
import ProgressBar from "../../../../../../../components/progressBar/ProgressBar";
import "./UploadVideos.scss";
import { uploadService } from "../../../../../../../services/upload.service";
import LoadingUpload from "../../../../../../loadingUpload/LoadingUpload";
import { DriveService } from "../../../../../../../services/drive.service";
// import getVideoDurationInSeconds from "get-video-duration";

const UploadVideos = ({
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  user,
  order,
}) => {
  const [status, setStatus] = useState("uploading");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tempFiles, setTempFiles] = useState([]);
  const [driveLink, setDriveLink] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [filesDuration, setFilesDuration] = useState(0);
  const [totalTimeLink, setTotalTimeLink] = useState(0);

  useEffect(() => {
    if (order.fileRequired) {
      if (order.fileRequired.files && order.fileRequired.files.length) {
        setFiles(order.fileRequired.files);
      }

      if (order.fileRequired.link !== "") {
        setDriveLink(order.fileRequired.link);
      }
    }
  }, [order]);

  const changeStep = (isStepNext) => {
    if (isStepNext) {
      if (validateFields()) {
        changeComponent("fileRequired", {
          files,
          link: driveLink,
        });

        nextStep();
      } else {
        validate("Please check that you added all required information");
      }
    } else {
      prevStep();
    }
  };

  const validateFields = () => {
    if (driveLink && driveLink.trim() !== "" && files && files.length) {
      return false;
    }

    if ((!driveLink || driveLink.trim() === "") && (!files || !files.length)) {
      return false;
    }

    return true;
  };

  return (
    <div className="upload-videos-new-order">
      {loading ? <LoadingUpload /> : null}
      <div className="inner-content">
        {!driveLink || driveLink.trim() === "" ? (
          <div className="upload">
            <input
              style={{ display: "none" }}
              type="file"
              multiple
              onChange={async (e) => {
                const arr = e.target.files;
                const filesArr = [...files];
                var vid = document.createElement("video");

                const result = await Promise.all(
                  [...arr].map(async (file) => {
                    setLoading(true);
                    var fileURL = URL.createObjectURL(file);
                    vid.src = fileURL;
                    // wait for duration to change from NaN to the actual duration
                    vid.ondurationchange = function () {
                      setFilesDuration((filesDuration) =>
                        Math.floor(filesDuration + this.duration)
                      );
                    };

                    if (file) {
                      let data = new FormData();
                      data.append("file", file);

                      await uploadService.upload(data, (data) => {
                        setLoading(false);
                        setFiles([...filesArr, data]);
                        filesArr.push(data);
                      });
                    }
                  })
                );
              }}
              id="drop-files-new-order"
            />
            <span>Please upload all the files required for this project</span>
            {(!fileNames || !fileNames.length) && (!files || !files.length) ? (
              <label for="drop-files-new-order" className="upload-box">
                <FaCloudUploadAlt size={60} color="#D9DFE9" />
                <span>Drop files here or click to upload.</span>
              </label>
            ) : null}
            {files && files.length ? (
              <div className="uploaded-box">
                <label className="upload-more" for="drop-files-new-order">
                  Upload more items
                </label>
                {filesDuration > user.package.rawFootage * 60 &&
                user.package.rawFootage !== null ? (
                  <div className="over-limit-warning">
                    <div>
                      <span className="limit-title">Over limit warning!</span>
                      <span>
                        You have surpassed your raw footage upload duration.
                        Please upload less footage or upgrade your plan.
                      </span>
                    </div>
                    <button>Upgrade plan</button>
                  </div>
                ) : null}
                <div className="files">
                  {files.map((file) => {
                    return (
                      <div className="item">
                        <AiFillFile size={60} color="#2A9DF4" />
                        <span>
                          {file.originalName.substring(0, 10)}{" "}
                          <FaRegTrashAlt
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const arr = [...files].filter(
                                (item) =>
                                  item.originalName !== file.originalName
                              );
                              setFiles(arr);
                            }}
                          />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {(!driveLink || driveLink.trim() === "") &&
        (!files || !files.length) &&
        (!fileNames || !fileNames.length) ? (
          <span className="or">Or</span>
        ) : null}
        {!files || !files.length ? (
          <div className="google-drive-folder">
            {totalTimeLink > user.package.rawFootage * 60 &&
            user.package.rawFootage !== null ? (
              <div className="over-limit-warning">
                <div>
                  <span className="limit-title">Over limit warning!</span>
                  <span>
                    You have surpassed your raw footage upload duration. Please
                    upload less footage or upgrade your plan.
                  </span>
                </div>
                <button>Upgrade plan</button>
              </div>
            ) : null}
            <span>
              Type in a Google Drive folder unrestricted link instead (Make sure
              the folder is public!)
            </span>
            <input
              onBlur={async () => {
                const totalTime = await DriveService.getTotalTime(
                  driveLink,
                  false
                );
                setTotalTimeLink(totalTime / 1000);
              }}
              value={driveLink}
              onChange={(e) => setDriveLink(e.target.value)}
              placeholder="Add link to your Google Drive"
            />
          </div>
        ) : null}
      </div>
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}
        {(files &&
          files.length &&
          (files.map((file) => file.duration).reduce((a, b) => a + b) <
            user.package.rawFootage * 60 ||
            user.package.rawFootage === null)) ||
        !files ||
        !files.length ? (
          <button
            className="next"
            onClick={() => {
              changeStep(true);
            }}
          >
            Next Step
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default UploadVideos;
