import { coreService } from "./core.service"

const getAllQuestions = (callback) => {
    coreService.get(`support/list`, callback)
}

const saveQuestion = (question, callback) => {
    coreService.post(`support/save`, question, callback)
}

const deleteQuestion = (questionId, callback) => {
    coreService.post(`support/delete/${questionId}`, {}, callback)
}

export const supportService = {
    getAllQuestions,
    saveQuestion,
    deleteQuestion
}