import { Link } from "react-router-dom";
import PurchaseSuccess from "../../../../../assets/video-types/purchase-successful.png";
import "./PurchaseSuccessful.scss";

const CustomerTestPurchaseSuccessful = () => {
  return (
    <div className="customer-layout-purchase-successful-page">
      <img src={PurchaseSuccess} />
      <span>You have successfully purchased your package.</span>
      <div className="actions">
        <Link to={"../../dashboard"} style={{ textDecoration: "none" }}>
          <button className="dashboard">Place Your First Order</button>
        </Link>
      </div>
    </div>
  );
};

export default CustomerTestPurchaseSuccessful;
