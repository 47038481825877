import "./FinishSignUp.scss";
import EmptyPhoto from "../../../../assets/auth/empty-photo.png";
import Portfolio1 from "../../../../assets/auth/portfolio-1.png";
import Portfolio2 from "../../../../assets/auth/portfolio-2.png";
import Youtube from "../../../../assets/youtube.png";
import Facebook from "../../../../assets/facebook.png";
import Instagram from "../../../../assets/instagram.png";
import Twitter from "../../../../assets/twitter.png";
import Tiktok from "../../../../assets/tiktok.png";
import Linkedin from "../../../../assets/linkedin.png";
import Snapchat from "../../../../assets/snapchat.png";
import Website from "../../../../assets/website.png";
import Cookies from "js-cookie";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlinePlus,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import Loading from "../../../loading/Loading";
import { uploadService } from "../../../../services/upload.service";
import Swal from "sweetalert2";
import { authService } from "../../../../services/auth.service";
import LoadingUpload from "../../../loadingUpload/LoadingUpload";
import { Link } from "react-router-dom";
import {
  getCities,
  getCountries,
  getStates,
} from "../../../../assets/js/location";

const GeneralAuthFinishSignUp = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isPasswordShown, setIsPasswordShows] = useState(false);
  const [setIsTermsOfServiceOpen, setIsPrivacyPolicyOpen] = useOutletContext();
  const [user, setUser] = useState({});
  const [skills, setSkills] = useState([""]);
  const [projects, setProjects] = useState([{ name: "", files: [] }]);
  const [loading, setLoading] = useState(false);
  const [billingCountries, setBillingCountries] = useState([]);
  const [billingStates, setBillingStates] = useState([]);
  const [billingCities, setBillingCities] = useState([]);
  const [businessCountries, setBusinessCountries] = useState([]);
  const [businessStates, setBusinessStates] = useState([]);
  const [businessCities, setBusinessCities] = useState([]);
  const [billingAddressCountry, setBillingAddressCountry] = useState("country");
  const [billingAddressState, setBillingAddressState] = useState("state");
  const [billingAddressCity, setBillingAddressCity] = useState("city");
  const [businessAddressCountry, setBusinessAddressCountry] =
    useState("country");
  const [businessAddressState, setBusinessAddressState] = useState("state");
  const [businessAddressCity, setBusinessAddressCity] = useState("city");
  const [notApplicable, setNotApplicable] = useState(false);

  useEffect(() => {
    getCountries((data) => {
      setBillingCountries(data);
      setBusinessCountries(data);
    });
  }, []);

  useEffect(() => {
    if (!user.billingAddress) {
      setBillingAddressCountry("US");
      setBusinessAddressCountry("US");
      const obj = {
        ...JSON.parse(JSON.stringify(user)),
        billingAddress: {
          country: "United States",
        },
      };

      if (user.type === "customer") {
        obj.business = {
          country: "United States",
        };
      }

      setUser(obj);
    }
  }, [user]);

  useEffect(() => {
    const obj = JSON.parse(JSON.stringify(user));
    if (notApplicable) {
      obj.business = null;
    } else {
      obj.business = {
        address: {
          street: "",
          state: "",
          city: "",
          country: "",
          complement: "",
          zipCode: "",
        },
      };
    }

    setUser(obj);
  }, [notApplicable]);

  useEffect(() => {
    if (billingAddressCountry !== "country") {
      setLoading(true);
      getStates(billingAddressCountry, (data) => {
        setLoading(false);
        setBillingStates(data);
        if (!data || !data.length) {
          Swal.fire({
            title: "No state found for this country",
            icon: "warning",
          });
          setBillingAddressState("state");
        }
      });
    }
  }, [billingAddressCountry]);

  useEffect(() => {
    if (
      billingAddressState !== "state" &&
      billingAddressCountry !== "country"
    ) {
      setLoading(true);
      getCities(billingAddressCountry, billingAddressState, (data) => {
        setBillingCities(data);
        setLoading(false);
        if (!data || !data.length) {
          Swal.fire({
            title: "No city found for this state",
            icon: "warning",
          });
          setBillingAddressCity("city");
        }
      });
    }
  }, [billingAddressState]);

  useEffect(() => {
    if (businessAddressCountry !== "country") {
      setLoading(true);
      getStates(businessAddressCountry, (data) => {
        setLoading(false);
        setBusinessStates(data);
        if (!data || !data.length) {
          Swal.fire({
            title: "No state found for this country",
            icon: "warning",
          });
          setBusinessAddressState("state");
        }
      });
    }
  }, [businessAddressCountry]);

  useEffect(() => {
    if (
      businessAddressState !== "state" &&
      businessAddressCountry !== "country"
    ) {
      setLoading(true);
      getCities(businessAddressCountry, businessAddressState, (data) => {
        setBusinessCities(data);
        setLoading(false);
        if (!data || !data.length) {
          Swal.fire({
            title: "No city found for this state",
            icon: "warning",
          });
          setBusinessAddressCity("city");
        }
      });
    }
  }, [businessAddressState]);

  useEffect(() => {
    state.user.business = {
      address: {
        street: "",
        state: "",
        city: "",
        country: "",
        complement: "",
        zipCode: "",
      },
    };
    setUser(state.user);
  }, [state]);

  const networks = [
    {
      name: "Youtube",
      id: "youtube",
      link: "https://youtube.com/",
      img: Youtube,
    },
    {
      name: "Facebook",
      id: "facebook",
      link: "https://facebook.com/",
      img: Facebook,
    },
    {
      name: "Instagram",
      id: "instagram",
      link: "https://instagram.com/",
      img: Instagram,
    },
    {
      name: "Twitter",
      id: "twitter",
      link: "https://twitter.com/",
      img: Twitter,
    },
    {
      name: "Tiktok",
      id: "tiktok",
      link: "https://tiktok.com/@",
      img: Tiktok,
    },
    {
      name: "Linkedin",
      id: "linkedin",
      link: "https://linkedin.com/in/",
      img: Linkedin,
    },
    {
      name: "Snapchat",
      id: "snapchat",
      link: "https://snapchap.com/add",
      img: Snapchat,
    },
    {
      name: "Personal Website",
      id: "website",
      link: "",
      img: Website,
    },
  ];

  const changeField = (field, text) => {
    if (field.includes(".") && (field.match(/\./g) || []).length === 1) {
      setUser((user) => {
        return {
          ...user,
          [field.split(".")[0]]: {
            ...user[[field.split(".")[0]]],
            [field.split(".")[1]]: text,
          },
        };
      });
    } else if (field.includes(".") && (field.match(/\./g) || []).length === 2) {
      const field2 =
        user[field.split(".")[0]] &&
        user[field.split(".")[0]][field.split(".")[1]]
          ? {
              ...user[field.split(".")[0]][field.split(".")[1]],
              [field.split(".")[2]]: text,
            }
          : {
              [field.split(".")[2]]: text,
            };

      setUser((user) => {
        return {
          ...user,
          [field.split(".")[0]]: {
            ...user[[field.split(".")[0]]],
            [field.split(".")[1]]: field2,
          },
        };
      });
    } else {
      setUser((user) => {
        return {
          ...user,
          [field]: text,
        };
      });
    }
  };

  const validate = () => {
    if (user.profilePicture === null || !user.profilePicture) {
      Swal.fire({
        title: "Error!",
        text: "Please add your profile picture",
        icon: "error",
      });
      return false;
    }

    if (user.firstName === null || user.firstName.trim === "") {
      Swal.fire({
        title: "Error!",
        text: "Please add your first name",
        icon: "error",
      });
      return false;
    }

    if (user.lastName === null || user.lastName.trim === "") {
      Swal.fire({
        title: "Error!",
        text: "Please add your last name",
        icon: "error",
      });
      return false;
    }

    if (user.email === null || user.email.trim === "") {
      Swal.fire({
        title: "Error!",
        text: "Please add your email",
        icon: "error",
      });
      return false;
    }

    if (
      user.type === "customer" &&
      (user.phoneNumber === null || user.phoneNumber === "")
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please add your phone number",
        icon: "error",
      });
      return false;
    }

    if (
      user.type === "editor" &&
      (!skills || skills.length === 0 || skills.includes(""))
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please add all your skills",
        icon: "error",
      });
      return false;
    }

    const emptyProjects = projects.filter(
      (project) =>
        project.name.trim() === "" || !project.files || !project.files.length
    );

    if (user.type === "editor") {
      user.skills = skills.join(",");
      user.projects = projects;
    }

    if (
      user.type === "customer" &&
      !notApplicable &&
      (user.business === null ||
        user.business.name === null ||
        user.business.name === "")
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please add your company name",
        icon: "error",
      });
      return false;
    }

    if (
      user.type === "customer" &&
      !notApplicable &&
      (user.business === null ||
        user.business.address === null ||
        !user.business.address ||
        !user.business.address.street ||
        user.business.address.street === null ||
        !user.business.address.complement ||
        user.business.address.complement === null ||
        !user.business.address.city ||
        user.business.address.city === null ||
        !user.business.address.state ||
        user.business.address.state === null ||
        !user.business.address.country ||
        user.business.address.country === null ||
        !user.business.address.zipCode ||
        user.business.address.zipCode === null)
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please add your company address",
        icon: "error",
      });
      return false;
    }

    if (
      user.type === "customer" &&
      !notApplicable &&
      (user.business === null ||
        user.business.bio === null ||
        user.business.bio === "")
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please add your company bio",
        icon: "error",
      });
      return false;
    }

    if (user.bio === null || user.bio.trim === "") {
      Swal.fire({
        title: "Error!",
        text: "Please add your personal bio",
        icon: "error",
      });
      return false;
    }

    if (
      user.billingAddress === null ||
      !user.billingAddress ||
      !user.billingAddress.street ||
      user.billingAddress.street === null ||
      !user.billingAddress.complement ||
      user.billingAddress.complement === null ||
      !user.billingAddress.city ||
      user.billingAddress.city === null ||
      !user.billingAddress.state ||
      user.billingAddress.state === null ||
      !user.billingAddress.country ||
      user.billingAddress.country === null ||
      !user.billingAddress.zipCode ||
      user.billingAddress.zipCode === null
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please add your billing address",
        icon: "error",
      });
      return false;
    }

    if (
      user.type === "editor" &&
      (!projects || projects.length === 0 || emptyProjects.length > 0)
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please add all your projects",
        icon: "error",
      });
      return false;
    }

    if (
      user.type === "editor" &&
      (!user.socialMedias ||
        !user.socialMedias.length ||
        !user.socialMedias.filter((item) => item.type === "paypal") ||
        !user.socialMedias.filter((item) => item.type === "paypal").length)
    ) {
      Swal.fire({
        title: "Error!",
        text: "Please add your Paypal",
        icon: "error",
      });
      return false;
    }

    if (
      user.socialMedias &&
      user.socialMedias.filter((item) => item.type === "website") &&
      user.socialMedias.filter((item) => item.type === "website").length &&
      (!user.socialMedias
        .filter((item) => item.type === "website")[0]
        .account.includes("http://") ||
        !user.socialMedias
          .filter((item) => item.type === "website")[0]
          .account.includes("https://"))
    ) {
      const obj = JSON.parse(JSON.stringify(user));
      obj.socialMedias.filter(
        (item) => item.type === "website"
      )[0].account = `https://${
        obj.socialMedias.filter((item) => item.type === "website")[0].account
      }`;
      setUser(obj);
    }

    return true;
  };

  const register = () => {
    if (validate()) {
      authService.finishSignUp(user, (data) => {
        Cookies.set("token", data.token);
        Swal.fire({
          title: "Success!",
          text:
            data.type === "customer"
              ? "Registration Complete. Redirecting you to dashboard."
              : data.accept === null
              ? "Registration Complete. Please wait for the admin to approve your account."
              : "Registration Complete. Redirecting you to dashboard.",
          icon: "success",
          timer: 3000,
        }).then(() => {
          if (data.type === "customer") {
            navigate(`/${data.type}`);
          } else {
            if (data.accept !== null) {
              navigate(`/${data.type}`);
            } else {
              navigate(`/auth/sign-in`);
            }
          }
        });
      });
    }
  };

  return user ? (
    <div className="auth-general-finish-sign-up">
      {loading ? <LoadingUpload /> : null}
      {user.type === "editor" ? (
        <div className="login-box">
          <span className="title">Sign Up to Mark Studios</span>
          <div className="login-info">
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              style={{ display: "none" }}
              id={`upload-profile-picture-editor`}
              onChange={(e) => {
                let file = e.target.files[0];
                setLoading(true);

                if (file) {
                  let data = new FormData();
                  data.append("file", file);

                  uploadService.upload(data, (data) => {
                    setLoading(false);
                    changeField("profilePicture", data);
                  });
                }
              }}
            />
            <label for="upload-profile-picture-editor">
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "100%",
                  overflow: "hidden",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "100px", cursor: "pointer" }}
                  src={
                    user.profilePicture
                      ? user.profilePicture.url
                      : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                  }
                />
              </div>
            </label>
            <div className="fillable-fields">
              <div className="input-box">
                <span className="input-title">First Name</span>
                <input
                  type="text"
                  onChange={(e) => changeField("firstName", e.target.value)}
                  placeholder="Your First Name"
                />
              </div>
              <div className="input-box">
                <span className="input-title">Last Name</span>
                <input
                  type="text"
                  onChange={(e) => changeField("lastName", e.target.value)}
                  placeholder="Your Last Name"
                />
              </div>
              <div className="input-box">
                <span className="input-title">Email Address</span>
                <input
                  disabled
                  type="email"
                  onChange={(e) => changeField("email", e.target.value)}
                  value={user.email}
                  placeholder="youremail@gmail.com"
                />
              </div>
              {/* <div className="input-box">
                <span className="input-title">Username</span>
                <input type="text" placeholder="Your Username" />
              </div> */}
              <div className="input-box multi">
                <span className="input-title">Skills</span>
                {skills.map((skill, index) => {
                  return (
                    <input
                      type="text"
                      placeholder={`Skill ${index + 1}`}
                      value={skill}
                      onChange={(e) => {
                        skill = e.target.value;
                        setSkills([
                          ...skills.slice(0, index),
                          skill,
                          ...skills.slice(index + 1),
                        ]);
                      }}
                    />
                  );
                })}
                <button
                  onClick={() => setSkills([...skills, ""])}
                  className="login"
                >
                  + Add More
                </button>
              </div>
              <div className="input-box">
                <span className="input-title">Bio</span>
                <textarea
                  type="text"
                  onChange={(e) => changeField("bio", e.target.value)}
                  placeholder="Add some description of your bio"
                />
              </div>
              <div className="input-box multi">
                <span className="input-title">Your Billing Address</span>
                <input
                  type="text"
                  onChange={(e) =>
                    changeField("billingAddress.street", e.target.value)
                  }
                  placeholder="Street"
                />
                <input
                  type="text"
                  onChange={(e) =>
                    changeField("billingAddress.complement", e.target.value)
                  }
                  placeholder="Apt/Suite #"
                />
                {/* <select
                  onChange={(e) => {
                    setBillingAddressCountry(e.target.value);
                    const countryName = [...billingCountries].filter(
                      (country) => country.iso2 === e.target.value
                    )[0].name;
                    changeField("billingAddress.country", countryName);
                  }}
                  value={billingAddressCountry}
                >
                  <option disabled value={"country"} selected>
                    Country
                  </option>
                  {billingCountries.map((country) => {
                    return <option value={country.iso2}>{country.name}</option>;
                  })}
                </select>
                <select
                  disabled={
                    billingStates && billingStates.length ? false : true
                  }
                  onChange={(e) => {
                    setBillingAddressState(e.target.value);
                    const stateName = [...billingStates].filter(
                      (state) => state.iso2 === e.target.value
                    )[0].name;
                    changeField("billingAddress.state", stateName);
                  }}
                  value={billingAddressState}
                >
                  <option disabled value={"state"} selected>
                    State
                  </option>
                  {billingStates.map((state) => {
                    return <option value={state.iso2}>{state.name}</option>;
                  })}
                </select>
                <select
                  disabled={
                    billingCities && billingCities.length ? false : true
                  }
                  onChange={(e) => {
                    setBillingAddressCity(e.target.value);
                    changeField("billingAddress.city", e.target.value);
                  }}
                  value={billingAddressCity}
                >
                  <option disabled value={"city"} selected>
                    City
                  </option>
                  {billingCities.map((city) => {
                    return <option value={city.iso2}>{city.name}</option>;
                  })}
                </select> */}
                <input
                  type="text"
                  onChange={(e) =>
                    changeField("billingAddress.country", e.target.value)
                  }
                  placeholder="Country"
                />
                <input
                  type="text"
                  onChange={(e) =>
                    changeField("billingAddress.state", e.target.value)
                  }
                  placeholder="State"
                />
                <input
                  type="text"
                  onChange={(e) =>
                    changeField("billingAddress.city", e.target.value)
                  }
                  placeholder="City"
                />
                <input
                  type="text"
                  onChange={(e) =>
                    changeField("billingAddress.zipCode", e.target.value)
                  }
                  placeholder="Zip Code"
                />
              </div>
              {projects.map((project, index) => {
                return (
                  <div className="input-box multi">
                    <span className="input-title">
                      Portfolio (Project {index + 1})
                    </span>
                    <input
                      type="text"
                      placeholder="Project Name"
                      value={project.name}
                      onChange={(e) => {
                        const obj = project;
                        obj.name = e.target.value;

                        setProjects([
                          ...projects.slice(0, index),
                          obj,
                          ...projects.slice(index + 1),
                        ]);
                      }}
                    />
                    <div className="project-photos">
                      <div className="images">
                        {project.files.map((file) => {
                          return <img src={file.url} />;
                        })}
                      </div>
                      <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        style={{ display: "none" }}
                        id={`upload-file-porfolio-${index}`}
                        onChange={(e) => {
                          let file = e.target.files[0];
                          setLoading(true);

                          if (file) {
                            let data = new FormData();
                            data.append("file", file);

                            uploadService.upload(data, (data) => {
                              const obj = project;
                              setLoading(false);
                              obj.files.push(data);

                              setProjects([
                                ...projects.slice(0, index),
                                obj,
                                ...projects.slice(index + 1),
                              ]);
                            });
                          }
                        }}
                      />
                      <label
                        for={`upload-file-porfolio-${index}`}
                        className="plus"
                      >
                        <AiOutlinePlus />
                      </label>
                    </div>
                  </div>
                );
              })}
              <button
                style={{ marginBottom: "2em" }}
                className="login"
                onClick={() => {
                  setProjects((projects) => [
                    ...projects,
                    { name: "", files: [] },
                  ]);
                }}
              >
                + Add More
              </button>
              <div className="input-box">
                <span className="input-title">Add Paypal</span>
                <input
                  type="text"
                  onChange={(e) => {
                    const obj = {
                      type: "paypal",
                      account: e.target.value,
                    };

                    let index = null;

                    if (user.socialMedias && user.socialMedias.length) {
                      user.socialMedias.map((item, i) => {
                        if (item.type === "paypal") {
                          index = i;
                        }
                      });
                    }

                    user.socialMedias && user.socialMedias.length
                      ? changeField("socialMedias", [
                          ...user.socialMedias.slice(0, index),
                          obj,
                          ...user.socialMedias.slice(index + 1),
                        ])
                      : changeField("socialMedias", [obj]);
                  }}
                  placeholder="Your Paypal Email Address"
                />
              </div>
              {networks.map((network) => {
                return (
                  <div className="input-box">
                    <span className="input-title">Add {network.name}</span>
                    <input
                      type="text"
                      onChange={(e) => {
                        const obj = {
                          type: network.id,
                          account: network.link + e.target.value,
                        };

                        let index = null;

                        if (user.socialMedias && user.socialMedias.length) {
                          user.socialMedias.map((item, i) => {
                            if (item.type === network.id) {
                              index = i;
                            }
                          });
                        }

                        user.socialMedias && user.socialMedias.length
                          ? index !== null
                            ? changeField("socialMedias", [
                                ...user.socialMedias.slice(0, index),
                                obj,
                                ...user.socialMedias.slice(index + 1),
                              ])
                            : changeField("socialMedias", [
                                ...user.socialMedias,
                                obj,
                              ])
                          : changeField("socialMedias", [obj]);
                      }}
                      placeholder={
                        network.id !== "website"
                          ? `Your ${network.name} Username`
                          : "Your Website URL"
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <button className="login" onClick={() => register()}>
            Register
          </button>
        </div>
      ) : (
        <div className="login-box">
          <span className="title">Setup your profile</span>
          <div className="login-info">
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              style={{ display: "none" }}
              id={`upload-profile-picture-editor`}
              onChange={(e) => {
                let file = e.target.files[0];
                setLoading(true);

                if (file) {
                  let data = new FormData();
                  data.append("file", file);

                  uploadService.upload(data, (data) => {
                    setLoading(false);
                    changeField("profilePicture", data);
                  });
                }
              }}
            />
            <label for="upload-profile-picture-editor">
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "100%",
                  overflow: "hidden",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "100px", cursor: "pointer" }}
                  src={
                    user.profilePicture
                      ? user.profilePicture.url
                      : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                  }
                />
              </div>
            </label>
            <div className="fillable-fields">
              <div className="input-box">
                <span className="input-title">First Name</span>
                <input
                  type="text"
                  onChange={(e) => changeField("firstName", e.target.value)}
                  placeholder="Your First Name"
                />
              </div>
              <div className="input-box">
                <span className="input-title">Last Name</span>
                <input
                  type="text"
                  placeholder="Your Last Name"
                  onChange={(e) => changeField("lastName", e.target.value)}
                />
              </div>
              <div className="input-box">
                <span className="input-title">Email Address</span>
                <input
                  disabled
                  type="email"
                  placeholder="youremail@gmail.com"
                  onChange={(e) => changeField("email", e.target.value)}
                  value={user.email}
                />
              </div>
              {/* <div className="input-box">
                <span className="input-title">Username</span>
                <input type="text" placeholder="Your Username" />
              </div> */}
              <div className="input-box">
                <span className="input-title">Phone number</span>
                <input
                  type="text"
                  placeholder="Phone Number"
                  onChange={(e) => changeField("phoneNumber", e.target.value)}
                />
              </div>
              <div className="input-box">
                <span className="input-title">Personal Bio</span>
                <textarea
                  type="text"
                  placeholder="Write about yourself"
                  onChange={(e) => changeField("bio", e.target.value)}
                />
              </div>
              <div className="input-box multi">
                <span className="input-title">Billing Address</span>
                <input
                  type="text"
                  placeholder="Street"
                  onChange={(e) =>
                    changeField("billingAddress.street", e.target.value)
                  }
                />
                <input
                  type="text"
                  placeholder="Apt/Suite #"
                  onChange={(e) =>
                    changeField("billingAddress.complement", e.target.value)
                  }
                />
                {/* <select
                  onChange={(e) => {
                    setBillingAddressCountry(e.target.value);
                    const countryName = [...billingCountries].filter(
                      (country) => country.iso2 === e.target.value
                    )[0].name;
                    changeField("billingAddress.country", countryName);
                  }}
                  value={billingAddressCountry}
                >
                  <option disabled value={"country"} selected>
                    Country
                  </option>
                  {billingCountries.map((country) => {
                    return <option value={country.iso2}>{country.name}</option>;
                  })}
                </select>
                <select
                  disabled={
                    billingStates && billingStates.length ? false : true
                  }
                  value={billingAddressState}
                  onChange={(e) => {
                    setBillingAddressState(e.target.value);
                    const stateName = [...billingStates].filter(
                      (state) => state.iso2 === e.target.value
                    )[0].name;
                    changeField("billingAddress.state", stateName);
                  }}
                >
                  <option disabled value={"state"} selected>
                    State
                  </option>
                  {billingStates.map((state) => {
                    return <option value={state.iso2}>{state.name}</option>;
                  })}
                </select>
                <select
                  disabled={
                    billingCities && billingCities.length ? false : true
                  }
                  onChange={(e) => {
                    setBillingAddressCity(e.target.value);
                    changeField("billingAddress.city", e.target.value);
                  }}
                  value={billingAddressCity}
                >
                  <option disabled value={"city"} selected>
                    City
                  </option>
                  {billingCities.map((city) => {
                    return <option value={city.iso2}>{city.name}</option>;
                  })}
                </select> */}
                <input
                  type="text"
                  placeholder="City"
                  onChange={(e) =>
                    changeField("billingAddress.city", e.target.value)
                  }
                />
                <input
                  type="text"
                  placeholder="State"
                  onChange={(e) =>
                    changeField("billingAddress.state", e.target.value)
                  }
                />
                <input
                  type="text"
                  placeholder="Country"
                  onChange={(e) =>
                    changeField("billingAddress.country", e.target.value)
                  }
                />
                <input
                  type="text"
                  placeholder="Zip Code"
                  onChange={(e) =>
                    changeField("billingAddress.zipCode", e.target.value)
                  }
                />
              </div>
              <div className="input-box checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => setNotApplicable(e.target.checked)}
                />
                <span className="input-title">I don't have a business</span>
              </div>
              {notApplicable ? null : (
                <div className="input-box">
                  <span className="input-title">Business Name</span>
                  <input
                    type="text"
                    placeholder="Business Name"
                    onChange={(e) =>
                      changeField("business.name", e.target.value)
                    }
                  />
                </div>
              )}
              {notApplicable ? null : (
                <div className="input-box checkbox">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const obj = {};
                        if (user.billingAddress.street) {
                          obj.street = user.billingAddress.street;
                        }
                        if (user.billingAddress.complement) {
                          obj.complement = user.billingAddress.complement;
                        }
                        if (user.billingAddress.country) {
                          setBusinessAddressCountry(billingAddressCountry);
                          obj.country = user.billingAddress.country;
                        }
                        if (user.billingAddress.state) {
                          setBusinessAddressState(billingAddressState);
                          obj.state = user.billingAddress.state;
                        }
                        if (user.billingAddress.city) {
                          setBusinessAddressCity(billingAddressCity);
                          obj.city = user.billingAddress.city;
                        }
                        if (user.billingAddress.zipCode) {
                          obj.zipCode = user.billingAddress.zipCode;
                        }

                        if (user.business) {
                          const newUser = JSON.parse(JSON.stringify(user));
                          newUser.business.address = obj;
                          setUser(newUser);
                        } else {
                          const newUser = JSON.parse(JSON.stringify(user));
                          newUser.business = { address: obj };
                          setUser(newUser);
                        }
                      } else {
                        changeField("business.address.street", "");
                        changeField("business.address.complement", "");
                        changeField("business.address.country", "");
                        changeField("business.address.state", "");
                        changeField("business.address.city", "");
                        changeField("business.address.zipCode", "");
                      }
                    }}
                  />
                  <span className="input-title">
                    Set business address the same as billing address
                  </span>
                </div>
              )}
              {notApplicable ||
              !user.business ||
              !user.business.address ||
              user.business.address === null ? null : (
                <div className="input-box multi">
                  <span className="input-title">Business Address</span>
                  <input
                    value={user.business.address.street}
                    type="text"
                    onChange={(e) =>
                      changeField("business.address.street", e.target.value)
                    }
                    placeholder="Street"
                  />
                  <input
                    value={user.business.address.complement}
                    type="text"
                    onChange={(e) =>
                      changeField("business.address.complement", e.target.value)
                    }
                    placeholder="Apt/Suite #"
                  />
                  {/* <select
                    value={businessAddressCountry}
                    onChange={(e) => {
                      setBusinessAddressCountry(e.target.value);
                      const countryName = [...businessCountries].filter(
                        (country) => country.iso2 === e.target.value
                      )[0].name;
                      changeField("business.address.country", countryName);
                    }}
                  >
                    <option disabled value={"country"} selected>
                      Country
                    </option>
                    {businessCountries.map((country) => {
                      return (
                        <option value={country.iso2}>{country.name}</option>
                      );
                    })}
                  </select>
                  <select
                    value={businessAddressState}
                    disabled={
                      businessStates && businessStates.length ? false : true
                    }
                    onChange={(e) => {
                      setBusinessAddressState(e.target.value);
                      const stateName = [...businessStates].filter(
                        (state) => state.iso2 === e.target.value
                      )[0].name;
                      changeField("business.address.state", stateName);
                    }}
                  >
                    <option disabled value={"state"} selected>
                      State
                    </option>
                    {businessStates.map((state) => {
                      return <option value={state.iso2}>{state.name}</option>;
                    })}
                  </select>
                  <select
                    value={businessAddressCity}
                    disabled={
                      businessCities && businessCities.length ? false : true
                    }
                    onChange={(e) => {
                      setBusinessAddressCity(e.target.value);
                      changeField("business.address.city", e.target.value);
                    }}
                  >
                    <option disabled value={"city"} selected>
                      City
                    </option>
                    {businessCities.map((city) => {
                      return <option value={city.iso2}>{city.name}</option>;
                    })}
                  </select> */}
                  <input
                    type="text"
                    onChange={(e) =>
                      changeField("business.address.city", e.target.value)
                    }
                    placeholder="City"
                  />
                  <input
                    type="text"
                    onChange={(e) =>
                      changeField("business.address.state", e.target.value)
                    }
                    placeholder="State"
                  />
                  <input
                    type="text"
                    onChange={(e) =>
                      changeField("business.address.country", e.target.value)
                    }
                    placeholder="Country"
                  />
                  <input
                    type="text"
                    value={user.business.address.zipCode}
                    onChange={(e) =>
                      changeField("business.address.zipCode", e.target.value)
                    }
                    placeholder="Zip Code"
                  />
                </div>
              )}
              {notApplicable ? null : (
                <div className="input-box">
                  <span className="input-title">Company Bio</span>
                  <textarea
                    type="text"
                    placeholder="Write about your company"
                    onChange={(e) =>
                      changeField("business.bio", e.target.value)
                    }
                  />
                </div>
              )}
              {networks.map((network) => {
                return (
                  <div className="input-box">
                    <span className="input-title">Add {network.name}</span>
                    <input
                      type="text"
                      onChange={(e) => {
                        const obj = {
                          type: network.id,
                          account: network.link + e.target.value,
                        };

                        let index = null;

                        if (user.socialMedias && user.socialMedias.length) {
                          user.socialMedias.map((item, i) => {
                            if (item.type === network.id) {
                              index = i;
                            }
                          });
                        }

                        user.socialMedias && user.socialMedias.length
                          ? index !== null
                            ? changeField("socialMedias", [
                                ...user.socialMedias.slice(0, index),
                                obj,
                                ...user.socialMedias.slice(index + 1),
                              ])
                            : changeField("socialMedias", [
                                ...user.socialMedias,
                                obj,
                              ])
                          : changeField("socialMedias", [obj]);
                      }}
                      placeholder={
                        network.id !== "website"
                          ? `Your ${network.name} Username`
                          : "Your Website URL"
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <button className="login" onClick={() => register()}>
            Register
          </button>
        </div>
      )}
      <div className="footer">
        <Link to={"/auth/sign-in"} className="already-have">
          Already have an account? <b>Sign in</b>
        </Link>
        <div className="terms-privacy-policy">
          <span onClick={() => setIsTermsOfServiceOpen(true)}>
            Terms of use
          </span>
          <span onClick={() => setIsPrivacyPolicyOpen(true)}>
            Privacy Policy
          </span>
          <span>Support</span>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default GeneralAuthFinishSignUp;
