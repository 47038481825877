import { useState, useEffect } from "react";
import "./Thumbnail.scss";
import { FaCloudUploadAlt, FaRegTrashAlt } from "react-icons/fa";
import { AiFillFile } from "react-icons/ai";
import ProgressBar from "../../../../../../../components/progressBar/ProgressBar";
import { uploadService } from "../../../../../../../services/upload.service";
import LoadingUpload from "../../../../../../loadingUpload/LoadingUpload";

const Thumbnail = ({
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  user,
  order,
}) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState("Facebook");
  const [logoStatus, setLogoStatus] = useState("waiting");
  const [extrasStatus, setExtrasStatus] = useState("waiting");
  const [otherSelected, setOtherSelected] = useState(false);
  const [postLocations, setPostLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState([
    "Facebook",
    "YouTube",
    "Twitter",
    "LinkedIn",
    "Website",
    "Instagram",
    "Other",
  ]);
  const [logo, setLogo] = useState(null);
  const [infoRequired, setInfoRequired] = useState("");
  const [avoidDescription, setAvoidDescription] = useState("");
  const [additionalFiles, setAdditionalFiles] = useState([]);

  useEffect(() => {
    if (order.thumbnail) {
      if (
        order.thumbnail.postLocations &&
        order.thumbnail.postLocations.length
      ) {
        setPostLocations(order.thumbnail.postLocations);
      }

      if (
        order.thumbnail.additionalFiles &&
        order.thumbnail.additionalFiles.length
      ) {
        setAdditionalFiles(order.thumbnail.additionalFiles);
      }

      if (
        order.thumbnail.infoRequired &&
        order.thumbnail.infoRequired !== null
      ) {
        setInfoRequired(order.thumbnail.infoRequired);
      }

      if (
        order.thumbnail.avoidDescription &&
        order.thumbnail.avoidDescription !== null
      ) {
        setAvoidDescription(order.thumbnail.avoidDescription);
      }

      if (order.thumbnail.logo && order.thumbnail.logo !== null) {
        setLogo(order.thumbnail.logo);
      }
    }
  }, [order]);

  const changeStep = (isStepNext) => {
    if (isStepNext) {
      if (validateFields()) {
        changeComponent("thumbnail", {
          postLocations: postLocations.join(","),
          logo,
          infoRequired,
          avoidDescription,
          additionalFiles,
        });

        nextStep();
      } else {
        validate("Please check that you added all required information");
      }
    } else {
      prevStep();
    }
  };

  const validateFields = () => {
    if (!postLocations || !postLocations.length) {
      return false;
    }

    if (!infoRequired || infoRequired.trim() === "") {
      return false;
    }

    if (!avoidDescription || avoidDescription.trim() === "") {
      return false;
    }

    setPostLocations((postLocations) => postLocations.join(","));

    return true;
  };

  return (
    <div className="thumbnail-new-order">
      {loading ? <LoadingUpload /> : null}
      <div className="inner-content">
        <div className="item">
          <span className="title-item">
            Where are you using this thumbnail?
          </span>
          {checkboxes.map((checkbox) => {
            return (
              <div className="radio-box">
                <input
                  id={checkbox}
                  name={checkbox}
                  type="checkbox"
                  onChange={(e) => {
                    if (checkbox === "Other") {
                      setOtherSelected(e.target.checked);
                      if (!e.target.checked) {
                        const arr = [...postLocations].filter((post) =>
                          checkbox.includes(post)
                        );
                        setPostLocations(arr);
                      }
                    } else {
                      if (e.target.checked) {
                        setPostLocations([...postLocations, checkbox]);
                      } else {
                        const arr = [...postLocations].filter(
                          (post) => post !== checkbox
                        );
                        setPostLocations(arr);
                      }
                    }
                  }}
                />
                <label for={checkbox} className="legend">
                  {checkbox}
                </label>
              </div>
            );
          })}
          {otherSelected && (
            <input
              value={[...postLocations].filter(
                (post) => !checkboxes.includes(post)
              )}
              onChange={(e) => {
                setPostLocations([...postLocations, e.target.value]);
              }}
              placeholder="Please specify"
            />
          )}
        </div>
        <div className="upload">
          <span className="upload-title">
            Please upload if you have a logo you want on the thumbnail
          </span>
          <input
            style={{ display: "none" }}
            type="file"
            onChange={async (e) => {
              const file = e.target.files[0];
              setLoading(true);

              if (file) {
                let data = new FormData();
                data.append("file", file);

                await uploadService.upload(data, (data) => {
                  setLoading(false);
                  setLogo(data);
                });
              }
            }}
            id="drop-files-logo"
          />
          {!logo || logo === null ? (
            <label className="upload-box" for="drop-files-logo">
              <FaCloudUploadAlt size={60} color="#D9DFE9" />
              <span>Drop files here or click to upload.</span>
            </label>
          ) : (
            <div className="uploaded-box">
              <div className="files">
                <div className="item">
                  <AiFillFile size={60} color="#2A9DF4" />
                  <span>
                    {logo.originalName}{" "}
                    <FaRegTrashAlt
                      onClick={() => {
                        setLogo(null);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="item">
          <span className="title-item">
            What info is required on your thumbnail?
          </span>
          <textarea
            placeholder="Add some comments"
            value={infoRequired}
            onChange={(e) => setInfoRequired(e.target.value)}
          />
        </div>
        <div className="item">
          <span className="title-item">
            Is there anything that should be avoided?
          </span>
          <textarea
            placeholder="Add some comments"
            value={avoidDescription}
            onChange={(e) => setAvoidDescription(e.target.value)}
          />
        </div>
        <div className="upload">
          <span className="upload-title">
            Do you have any images, sketches or documents that might be helpful?
          </span>
          <span className="upload-sub-title">
            E.g.Your current logo, fonts, photos, illustrations, content, layout
            ideas etc.
          </span>
          <input
            style={{ display: "none" }}
            type="file"
            multiple
            onChange={async (e) => {
              const arr = e.target.files;
              const filesArr = [...additionalFiles];

              const result = await Promise.all(
                [...arr].map(async (file) => {
                  setLoading(true);
                  if (file) {
                    let data = new FormData();
                    data.append("file", file);

                    await uploadService.upload(data, (data) => {
                      setLoading(false);
                      setAdditionalFiles([...filesArr, data]);
                      filesArr.push(data);
                    });
                  }
                })
              );
            }}
            id="drop-files-additional-files"
          />
          {!additionalFiles || !additionalFiles.length ? (
            <label for="drop-files-additional-files" className="upload-box">
              <FaCloudUploadAlt size={60} color="#D9DFE9" />
              <span>Drop files here or click to upload.</span>
            </label>
          ) : (
            <div className="uploaded-box">
              <label className="upload-more" for="drop-files-additional-files">
                Upload more items
              </label>
              <div className="files">
                {additionalFiles.map((file) => {
                  return (
                    <div className="item">
                      <AiFillFile size={60} color="#2A9DF4" />
                      <span>
                        {file.originalName.substring(0, 10)}{" "}
                        <FaRegTrashAlt
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const arr = [...additionalFiles].filter(
                              (item) => item.originalName !== file.originalName
                            );
                            setAdditionalFiles(arr);
                          }}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {extrasStatus === "uploaded" && (
            <div className="uploaded-box">
              <div className="files">
                <div className="item">
                  <AiFillFile size={60} color="#2A9DF4" />
                  <span>
                    File 1 <FaRegTrashAlt style={{ cursor: "pointer" }} />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}
        <button
          className="next"
          onClick={() => {
            changeStep(true);
          }}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default Thumbnail;
