import "./Landing.scss";
import LogoLanding from "../../assets/landing/logo-landing.png";
import BlurredBg from "../../assets/landing/bg-blurred.png";
import MadeInUSA from "../../assets/landing/made-in-usa.png";
import DashboardExample from "../../assets/landing/dashboard-example.png";
import Client1 from "../../assets/landing/client-1.png";
import Client2 from "../../assets/landing/client-2.png";
import Client3 from "../../assets/landing/client-3.png";
import Client4 from "../../assets/landing/client-4.png";
import Client5 from "../../assets/landing/client-5.png";
import Footer1 from "../../assets/landing/footer/footer-1.png";
import Footer2 from "../../assets/landing/footer/footer-2.png";
import Footer3 from "../../assets/landing/footer/footer-3.png";
import Footer4 from "../../assets/landing/footer/footer-4.png";
import Footer5 from "../../assets/landing/footer/footer-5.png";
import Footer6 from "../../assets/landing/footer/footer-6.png";
import Services1 from "../../assets/landing/services/service-1.png";
import Services2 from "../../assets/landing/services/service-2.png";
import Services3 from "../../assets/landing/services/service-3.png";
import Services4 from "../../assets/landing/services/service-4.png";
import Services5 from "../../assets/landing/services/service-5.png";
import Services6 from "../../assets/landing/services/service-6.png";
import Porfolio1 from "../../assets/landing/portfolio/portfolio-1.png";
import Porfolio2 from "../../assets/landing/portfolio/portfolio-2.png";
import Porfolio3 from "../../assets/landing/portfolio/portfolio-3.png";
import Porfolio4 from "../../assets/landing/portfolio/portfolio-4.png";
import Porfolio5 from "../../assets/landing/portfolio/portfolio-5.png";
import Porfolio6 from "../../assets/landing/portfolio/portfolio-6.png";
import Porfolio7 from "../../assets/landing/portfolio/portfolio-7.png";
import Porfolio8 from "../../assets/landing/portfolio/portfolio-8.png";
import Feature1 from "../../assets/landing/features/feature-1.png";
import Feature2 from "../../assets/landing/features/feature-2.png";
import Feature3 from "../../assets/landing/features/feature-3.png";
import Feature4 from "../../assets/landing/features/feature-4.png";
import BigFeature from "../../assets/landing/features/big-feature.png";
import SocialMedia1 from "../../assets/landing/features/social-media/facebook.png";
import SocialMedia2 from "../../assets/landing/features/social-media/instagram.png";
import SocialMedia3 from "../../assets/landing/features/social-media/youtube.png";
import SocialMedia4 from "../../assets/landing/features/social-media/vimeo.png";
import SocialMedia5 from "../../assets/landing/features/social-media/tiktok.png";
import AddOn1 from "../../assets/landing/add-ons/add-on-1.png";
import AddOn2 from "../../assets/landing/add-ons/add-on-2.png";
import AddOn3 from "../../assets/landing/add-ons/add-on-3.png";
import AddOn4 from "../../assets/landing/add-ons/add-on-4.png";
import AddOn5 from "../../assets/landing/add-ons/add-on-5.png";
import Mark from "../../assets/landing/mark-about-us.png";
import Testimonial1 from "../../assets/landing/testimonials/testimonial-1.png";
import Testimonial2 from "../../assets/landing/testimonials/testimonial-2.png";
import Testimonial3 from "../../assets/landing/testimonials/testimonial-3.png";
import MainPhoto from "../../assets/landing/main-photo.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiFillCheckCircle,
  AiFillCrown,
  AiOutlineSetting,
  AiOutlineMail,
  AiFillLinkedin,
  AiFillInstagram,
  AiFillFacebook,
} from "react-icons/ai";
import { BiPackage } from "react-icons/bi";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import Video from "../../assets/video-types/video.png";
import Time from "../../assets/video-types/time.png";
import Clock from "../../assets/video-types/clock.png";
import Thumbnail from "../../assets/video-types/thumbnail.png";
import Banner from "../../assets/video-types/banner.png";
import Logo from "../../assets/video-types/logo.png";
import ConsultationCall from "../../assets/video-types/consultation-call.png";
import Outro from "../../assets/video-types/outro.png";
import Intro from "../../assets/video-types/intro.png";
import UnlimitedStock from "../../assets/video-types/unlimited-stock.png";
import SliderProgress from "../../components/slider-progress/SliderProgress";

const Landing = () => {
  const [type, setType] = useState("Monthly");
  const [tryCustomPackage, setTryCustomPackage] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const myRefStart = useRef(null);
  const myRefAbout = useRef(null);
  const myRefServices = useRef(null);
  const myRefPricing = useRef(null);
  const myRefPortfolio = useRef(null);
  const myRefContact = useRef(null);
  const [counterVideos, setCounterVideos] = useState(1);
  const [counterRawFootage, setCounterRawFootage] = useState(1);
  const [counterFinalRunningTime, setCounterFinalRunningTime] = useState(1);
  const [counterThumbnails, setCounterThumbnails] = useState(1);
  const [counterBanner, setCounterBanner] = useState(1);
  const [counterLogo, setCounterLogo] = useState(1);
  const [counterConsultationCall, setCounterConsultationCall] = useState(1);
  const [counterOutro, setCounterOutro] = useState(1);
  const [counterIntro, setCounterIntro] = useState(1);
  const [unlimitedStock, setUnlimitedStock] = useState(false);
  const [thumbnailsWithEveryVideo, setThumnailsWithEveryVideo] =
    useState(false);

  useEffect(() => {
    window.location.replace("https://markstudioshub.com");
  });

  const items = [
    {
      title: "Videos per month",
      subTitle:
        "The amount of video projects you'd like us to edit for you per month.",
      counter: counterVideos,
      setCounter: setCounterVideos,
      visualCounter: counterVideos,
      divider: 31,
      visualText: "Videos/Month",
      image: Video,
      items: [...Array(31).keys()].map((key) => key),
    },
    {
      title: "Amount of provided footage per video",
      subTitle:
        "This is the raw recorded unedited footage files you are going to send us to edit per video. ",
      counter: counterRawFootage,
      setCounter: setCounterRawFootage,
      visualCounter: counterRawFootage,
      visualText: " Minutes/Video",
      divider: 10,
      image: Time,
      items: [0, 5, 10, 15, 20, 30, 45, 60, 90, 120],
    },
    {
      title: "Final running time per video",
      subTitle: "This is the final time of each video you order us to edit.",
      counter: counterFinalRunningTime,
      setCounter: setCounterFinalRunningTime,
      visualCounter: counterFinalRunningTime,
      divider: 13,
      visualText: "Minutes",
      image: Clock,
      items: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
    },
    {
      title: "Thumbnails",
      subTitle: false,
      counter: counterThumbnails,
      setCounter: setCounterThumbnails,
      visualCounter: counterThumbnails,
      visualText: "Thumbnails",
      image: Thumbnail,
    },
    {
      title: "Banner",
      subTitle: false,
      counter: counterBanner,
      setCounter: setCounterBanner,
      visualCounter: counterBanner,
      visualText: "Banner",
      image: Banner,
    },
    {
      title: "Logo",
      subTitle: false,
      counter: counterLogo,
      setCounter: setCounterLogo,
      visualCounter: counterLogo,
      visualText: "Logo",
      image: Logo,
    },
    {
      title: "Consultation Call",
      subTitle: false,
      counter: counterConsultationCall,
      setCounter: setCounterConsultationCall,
      visualCounter: counterConsultationCall,
      visualText: "Consultation Call",
      image: ConsultationCall,
    },
    {
      title: "Outro",
      subTitle: false,
      counter: counterOutro,
      setCounter: setCounterOutro,
      visualCounter: counterOutro,
      visualText: "Outro",
      image: Outro,
    },
    {
      title: "Intro",
      subTitle: false,
      counter: counterIntro,
      setCounter: setCounterIntro,
      visualCounter: counterIntro,
      visualText: "Intro",
      image: Intro,
    },
  ];

  const executeScroll = (ref) => {
    setIsMenuOpen(false);
    ref.current.scrollIntoView();
  };

  const packages = [
    {
      name: "Started",
      priceMonth: 199,
      priceYear: 2300,
      popular: false,
      pro: false,
      videos: 2,
      features: [
        "Up to 5 minutes of running time per video",
        "Up to 10 minutes of raw provided footage per video",
        "2-3 days delivery guaranteed",
        "2 thumbnails",
        "1 free Personalized Outro",
        "Unlimited Revisions",
      ],
    },
    {
      name: "Standard",
      priceMonth: 499,
      priceYear: 5900,
      popular: true,
      pro: false,
      videos: 4,
      features: [
        "Up to 15 minutes of running time per video",
        "Up to 30 minutes of raw provided footage per video",
        "1-2 days delivery guaranteed",
        "4 thumbnails",
        "1 free Personalized Intro",
        "1 free Personalized Outro",
        "Unlimited Revisions",
      ],
    },
    {
      name: "Pro",
      priceMonth: 1499,
      priceYear: 17900,
      popular: false,
      pro: true,
      videos: 6,
      features: [
        "Up to 1 hour of running time per video",
        "Unlimited provided footage",
        "1-2 days delivery guaranteed",
        "Unlimited thumbnails",
        "Free Brand Identity Pack with Intro+Outro+Banner",
        "Unlimited Revisions",
      ],
    },
  ];

  const services = [
    {
      name: "24-Hour Delivery",
      description:
        "Within 24 hours, we give you a final cut for approval and only make changes based on feedback from this point.",
      image: Services1,
    },
    {
      name: "Video Editing",
      description:
        "It takes a skilled hand to make a viral masterpiece out of a pile of raw clips. Let us do the favor for you.",
      image: Services2,
    },
    {
      name: "Thumbnail Design",
      description:
        "Your thumbnails make or break you – they’re your little silent previews of what’s to come.",
      image: Services3,
    },
    {
      name: "Social Media Accounts Setup",
      description:
        "Mark Studios has a track record in setting up these networks – giving you more time to do what matters most - creating content.",
      image: Services4,
    },
    {
      name: "Intro & Outro Design",
      description:
        "Leave this aspect of your media’s design to us and concentrate on what you have to say instead. We’ll help you aim at your target.",
      image: Services5,
    },
    {
      name: "Youtube Channel Growth Consultation",
      description:
        "Finding your star on the internet is all about the numbers. Mark, the owner of Mark Studios, knows how to “get it done.”",
      image: Services6,
    },
  ];

  const features = [
    {
      name: "Custom Pricing Package",
      description:
        "Select the amount of videos you want to make and get a custom package made just for you! ",
      image: Feature1,
    },
    {
      name: "In App Messenger",
      description: "Contact us through the in-app live chat with 24/7 support.",
      image: Feature2,
    },
    {
      name: "Stock Footage",
      description:
        "Add b-roll stock footage to make your videos even more amazing!",
      image: Feature3,
    },
    {
      name: "Specialized Editor",
      description:
        "All video edits include professional video editing. No more “BASIC” packages. At Mark Studios all videos get FULL video editing.",
      image: Feature4,
    },
  ];

  const porfolio = [
    Porfolio1,
    Porfolio2,
    Porfolio3,
    Porfolio4,
    Porfolio5,
    Porfolio6,
    Porfolio7,
    Porfolio8,
  ];
  const medias = [
    {
      image: SocialMedia1,
      name: "Facebook",
    },
    {
      image: SocialMedia2,
      name: "Instagram",
    },
    {
      image: SocialMedia3,
      name: "Youtube",
    },
    {
      image: SocialMedia4,
      name: "Vimeo",
    },
    {
      image: SocialMedia5,
      name: "Tiktok",
    },
  ];

  const addOns = [
    {
      image: AddOn1,
      name: "Logo",
      description: "Get a professionally designed logo for your business.",
      price: 150,
    },
    {
      image: AddOn2,
      name: "Banner",
      description:
        "Get a banner designed for your Facebook, Instagram, Website, Ad, YouTube channel and much more.",
      price: 150,
    },
    {
      image: AddOn3,
      name: "Outro",
      description:
        "An outro end screen goes at the end of your video and introduces a CTA.",
      price: 50,
    },
    {
      image: AddOn4,
      name: "Intro",
      description:
        "Help your viewers remember your brand with a custom personalized animated introduction for your videos!",
      price: 100,
    },
    {
      image: AddOn5,
      name: "Consultation Call",
      description:
        "Need help growing our online social presence? Schedule a call with Mark now! ",
      price: 250,
      special: "per hour",
    },
  ];

  const testimonials = [
    {
      name: "Vishal Photogram",
      text: "Mark is an excellent video editor. | gave him a few styles that I was interested in and he was able to produce pretty much exactly what I was looking for in less than 3 days. Great communicator and seller on Fiverr. Seriously impressed and I would recommend you go to Mark for any future video editing.",
      image: Testimonial1,
    },
    {
      name: "Lauren Kidd",
      text: "Truly truly amazing! I didn’t have a design vision for this, so gave artistic freedom to Mark and my boss is so happy with how this came out! It is very professional yet engaging and fun! Thank you!",
      image: Testimonial2,
    },
    {
      name: "Emma Kotos",
      text: "My experience with Mark was absolutely amazing! His editing skills are top tier and he was so nice and great at communicating. He also got it done very fast. Will definitely be ordering from him again",
      image: Testimonial3,
    },
  ];

  return (
    <div className="landing-page">
      {/* {isMenuOpen && (
        <div className="menu-side">
          <div className="menu-container">
            <AiOutlineClose
              onClick={() => setIsMenuOpen(false)}
              className="close"
            />
            <div className="items">
              <span onClick={() => executeScroll(myRefAbout)}>About</span>
              <span onClick={() => executeScroll(myRefServices)}>Services</span>
              <span onClick={() => executeScroll(myRefPricing)}>Pricing</span>
              <span onClick={() => executeScroll(myRefPortfolio)}>
                Portfolio
              </span>
              <span onClick={() => executeScroll(myRefContact)}>Contact</span>
              <button
                onClick={() => {
                  setIsMenuOpen(false);
                  navigate("/auth/sign-up");
                }}
              >
                Get Started
              </button>
              <button
                onClick={() => {
                  setIsMenuOpen(false);
                  navigate("/auth/sign-in");
                }}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="nav-bar">
        <div className="nav-bar-content">
          <img onClick={() => executeScroll(myRefStart)} src={LogoLanding} />
          <div className="items">
            <span onClick={() => executeScroll(myRefAbout)}>About</span>
            <span onClick={() => executeScroll(myRefServices)}>Services</span>
            <span onClick={() => executeScroll(myRefPricing)}>Pricing</span>
            <span onClick={() => executeScroll(myRefPortfolio)}>Portfolio</span>
            <span onClick={() => executeScroll(myRefContact)}>Contact</span>
            <button
              onClick={() => {
                navigate("/auth/sign-up");
              }}
            >
              Get Started
            </button>
            <button
              onClick={() => {
                navigate("/auth/sign-in");
              }}
            >
              Login
            </button>
          </div>
          <AiOutlineMenu
            onClick={() => setIsMenuOpen(true)}
            className="sidebar-mobile"
          />
        </div>
      </div>
      <img className="blurred-bg" src={BlurredBg} />
      <div className="landing-page-content">
        <span className="main-title" ref={myRefStart}>
          We Help You Create
          <br />
          Amazing Videos
        </span>
        <span className="sub-title">
          Professional On-Demand Video Editing Subscription Service
        </span>
        <div className="input-box">
          <input placeholder="Enter your e-mail here" />
          <button>Sign Up</button>
          <img src={MadeInUSA} />
        </div>
        <div className="dashboard-example">
          <div className="top-bar">
            <div
              className="circle"
              style={{ backgroundColor: "#E56868" }}
            ></div>
            <div
              className="circle"
              style={{ backgroundColor: "#E5B368" }}
            ></div>
            <div
              className="circle"
              style={{ backgroundColor: "#68E584" }}
            ></div>
          </div>
          <img src={DashboardExample} />
        </div>
        <div className="loved-by">
          <span>Loved and Trusted by Many Great Clients✨</span>
          <div className="clients">
            <img src={Client1} />
            <img src={Client2} />
            <img src={Client3} />
            <img src={Client4} />
            <img src={Client5} />
          </div>
        </div>
        <div className="our-services" ref={myRefServices}>
          <div className="text-lines">
            <div className="line" />
            <span>OUR SERVICES</span>
            <div className="line" />
          </div>
          <span className="provide">What We Provide</span>
          <div className="services">
            {services.map((service) => {
              return (
                <div className="service">
                  <img src={service.image} />
                  <span className="name">{service.name}</span>
                  <span className="description">{service.description}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="portfolio" ref={myRefPortfolio}>
          {porfolio.slice(0, 4).map((item) => {
            return <img src={item} />;
          })}
          <div className="middle-box">
            <div className="text-lines">
              <div className="line" />
              <span>OUR PORTFOLIO</span>
              <div className="line" />
            </div>
            <span className="sub-title-middle">Recent Works</span>
            <button onClick={() => navigate("../portfolio")}>See More</button>
          </div>
          {porfolio.slice(4).map((item) => {
            return <img src={item} />;
          })}
        </div>
        <div className="main-features">
          <div className="text-lines">
            <div className="line" />
            <span>FEATURES</span>
            <div className="line" />
          </div>
          <span className="provide">Main Features</span>
          <div className="features">
            {features.map((feature) => {
              return (
                <div className="feature">
                  <img src={feature.image} />
                  <span className="name">{feature.name}</span>
                  <span className="description">{feature.description}</span>
                </div>
              );
            })}
            <div className="feature big">
              <img src={BigFeature} />
              <span className="name">
                Format your content for all platforms
              </span>
              <span className="description">
                Presence in all of social medias is essential. That’s why we
                provide videos for all social media formats: square, vertical,
                horizontal (1×1, 4×5, 16×9, 9×16). You name it, we got it!
              </span>
              <div className="medias">
                {medias.map((media) => {
                  return (
                    <div className="media">
                      <img src={media.image} />
                      <span>{media.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="pricing" ref={myRefPricing}>
          <div className="text-lines">
            <div className="line" />
            <span>PRICING</span>
            <div className="line" />
          </div>
          <span className="provide">
            Your Suitable Package
            <br />
            for Your Needs
          </span>
          <div className="types">
            <div
              onClick={() => setType("Monthly")}
              className={`type ${type === "Monthly" ? "active" : ""}`}
            >
              Monthly
            </div>
            <div
              onClick={() => setType("Yearly")}
              className={`type ${type === "Yearly" ? "active" : ""}`}
            >
              Yearly
            </div>
          </div>
          <div className="packages">
            {packages.map((pkg) => {
              return (
                <div className="package">
                  {pkg.popular && (
                    <div className="popular-package">POPULAR PACKAGE</div>
                  )}
                  {pkg.pro && <AiFillCrown className="crown" />}
                  <div className="package-name">
                    <span>{pkg.name}</span>
                    <span>{pkg.videos} videos per month</span>
                  </div>
                  <span className="price">
                    ${type === "Monthly" ? pkg.priceMonth : pkg.priceYear}/
                    {type === "Monthly" ? "month" : "year"}
                  </span>
                  <div className="features">
                    {pkg.features.map((feature) => {
                      return (
                        <div className="feature">
                          <AiFillCheckCircle
                            className="icon"
                            size={25}
                            color="#2A9DF4"
                          />
                          <span>{feature}</span>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    onClick={() => {
                      navigate("../customer-test", {
                        state: {
                          order: {
                            name: "Custom",
                            videos: counterVideos,
                            rawFootage: counterRawFootage,
                            runningVideo: counterFinalRunningTime,
                            thumbnails: counterThumbnails,
                            banner: counterBanner,
                            logo: counterLogo,
                            consultationCall: counterConsultationCall,
                            outro: counterOutro,
                            intro: counterIntro,
                            unlimitedStockFootage: unlimitedStock,
                            price: 50 * 100,
                          },
                        },
                      });
                    }}
                  >
                    Choose Package
                  </button>
                </div>
              );
            })}
          </div>
          <div className="actions">
            <button
              className="custom"
              onClick={() => setTryCustomPackage(true)}
            >
              <AiOutlineSetting className="icon" />
              <span>Create Custom Package</span>
              <div className="try-me">Try Me</div>
            </button>
            <button className="single">
              <BiPackage className="icon" />
              <span>Create Custom Package</span>
            </button>
          </div>
        </div>
        {tryCustomPackage && (
          <div className="custom-package">
            <div className="items">
              {items.map((item) => {
                return (
                  <div className="item">
                    <div className="details">
                      <span className="item-title">{item.title}</span>
                      <span className="item-sub-title">{item.subTitle}</span>
                      {item.divider ? (
                        <div className="counter-slider">
                          <SliderProgress
                            setItem={item.setCounter}
                            items={item.items}
                            dividers={item.divider}
                          />
                        </div>
                      ) : (
                        <div className="counter">
                          <IoIosRemoveCircle
                            onClick={() => {
                              if (item.counter > 1) {
                                item.setCounter(item.counter - 1);
                              }
                            }}
                            size={25}
                            color="#2A9DF4"
                            className={item.counter === 1 ? "disabled" : ""}
                          />
                          <span>{item.counter}</span>
                          <IoIosAddCircle
                            onClick={() => {
                              item.setCounter(item.counter + 1);
                            }}
                            size={25}
                            color="#2A9DF4"
                          />
                        </div>
                      )}
                    </div>
                    <div className="visual">
                      <img src={item.image} />
                      <span>{item.visualText}</span>
                      <span>{item.visualCounter}</span>
                    </div>
                  </div>
                );
              })}
              <div className="item stock">
                <div className="details">
                  <span className="item-title">Stock Footage</span>
                  <div className="checkbox-box">
                    <input
                      checked={unlimitedStock}
                      onChange={(e) => setUnlimitedStock(e.target.checked)}
                      type="checkbox"
                    />
                    <span>Unlimited stock footage ($35 per month)</span>
                  </div>
                </div>
                <div className="visual">
                  <img src={UnlimitedStock} />
                  <span>Stock Footage</span>
                  <span>Unlimited</span>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="footer-text">
                <span>Total Package</span>
                <span>(Includes 7.5% Sales Tax)</span>
              </div>
              <div className="actions">
                <span>$21.50</span>
                <button
                  onClick={() => {
                    // let upd = false;
                    // if (
                    //   state &&
                    //   state.update &&
                    //   state.update !== null &&
                    //   state.update === true
                    // ) {
                    //   upd = true;
                    // }

                    navigate("../customer-test", {
                      state: {
                        order: {
                          name: "Custom",
                          videos: counterVideos,
                          rawFootage: counterRawFootage,
                          runningVideo: counterFinalRunningTime,
                          thumbnails: counterThumbnails,
                          banner: counterBanner,
                          logo: counterLogo,
                          consultationCall: counterConsultationCall,
                          outro: counterOutro,
                          intro: counterIntro,
                          unlimitedStockFootage: unlimitedStock,
                          price: 50 * 100,
                        },
                      },
                    });
                  }}
                >
                  Order Package
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="add-ons-box">
          <div className="text-lines">
            <div className="line" />
            <span>ADD ONS</span>
            <div className="line" />
          </div>
          <span className="provide">
            Get More Prime
            <br />
            Add-Ons for Your Video
          </span>
          <div className="add-ons-list">
            <div className="add-ons">
              {addOns.slice(0, 3).map((addOn) => {
                return (
                  <div className="add-on">
                    <img src={addOn.image} />
                    <div className="texts">
                      <span className="name">{addOn.name}</span>
                      <span className="description">{addOn.description}</span>
                      <div className="actions">
                        <button>Get Add On</button>
                        <div className="price">
                          <span>${addOn.price}</span>
                          {addOn.special && (
                            <span className="special">{addOn.special}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="add-ons second">
              {addOns.slice(3).map((addOn) => {
                return (
                  <div className="add-on">
                    <img src={addOn.image} />
                    <div className="texts">
                      <span className="name">{addOn.name}</span>
                      <span className="description">{addOn.description}</span>
                      <div className="actions">
                        <button>Get Add On</button>
                        <div className="price">
                          <span>${addOn.price}</span>
                          {addOn.special && (
                            <span className="special">{addOn.special}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="about-us" ref={myRefAbout}>
          <div className="text">
            <div className="text-lines">
              <div className="line" />
              <span>ABOUT US</span>
              <div className="line" />
            </div>
            <span className="provide">
              A Glance About
              <br />
              Mark Studios
            </span>
            <span className="about">
              Mark Studios LLC, founded by Mark Santos, is here to push the
              difference from mediocre to the next level. We craft creative and
              informational videos to propel your brand, while giving solutions
              for getting your content more traffic.
            </span>
          </div>
          <img src={Mark} />
        </div>
        <div className="testimonials">
          <div className="text">
            <div className="text-lines">
              <div className="line" />
              <span>TESTIMONIALS</span>
              <div className="line" />
            </div>
            <span className="provide">
              Trusted by Hundreds <br />
              of Creators & Entrepreneurs
            </span>
          </div>
        </div>
        <div className="testimonials-boxes">
          <div className="blue" />
          {testimonials.map((testimonial) => {
            return (
              <div className="testimonial">
                <img src={testimonial.image} />
                <div className="texts">
                  <span>{testimonial.text}</span>
                  <span>{testimonial.name}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="main-image">
          <img src={MainPhoto} />
        </div>
        <div className="three-simple-steps" ref={myRefContact}>
          <div className="steps-header">
            <span>
              Ready to Take Your Videos to The
              <br />
              Next Level?
            </span>
            <button>Let’s Get Started</button>
          </div>
          <span className="middle-text">3 Simple Steps to Get There</span>
          <div className="icons">
            <div className="icon">
              <img src={Footer1} />
              <span>Create an Account</span>
            </div>
            <div className="icon">
              <img src={Footer2} />
              <span>Upload Your Files</span>
            </div>
            <div className="icon">
              <img src={Footer3} />
              <span>
                Get Your Fully Edited
                <br />
                Video!
              </span>
            </div>
          </div>
        </div>
        <div className="support-you">
          <div className="black" />
          <div className="support-you-content">
            <div className="steps-header">
              <span>We Are Here to Support You</span>
            </div>
            <span className="middle-text">
              Customer support is our highest priority. We’re here to answer all
              your questions via our 24/7 Live Chat and Support line that calls
              straight into Mark’s personal phone.
            </span>
            <div className="icons">
              <div className="icon">
                <img src={Footer4} />
                <button>Tutorial Video</button>
              </div>
              <div className="icon">
                <img src={Footer5} />
                <button>Get in Touch</button>
              </div>
              <div className="icon">
                <img src={Footer6} />
                <button>Chat Us</button>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us">
          <div className="left">
            <img src={LogoLanding} />
            <span className="contact-title">Reach Us</span>
            <div className="email">
              <AiOutlineMail />
              <span>contact@markstudioshub.com</span>
            </div>
            <div className="icons">
              <AiFillFacebook size={20} />
              <AiFillInstagram size={20} />
              <AiFillLinkedin size={20} />
            </div>
            <span className="footnote">
              Copyright © 2022 | Mark Studios | All Rights Reserved
            </span>
          </div>
          <div className="right">
            <span className="contact-title">Leave a Message</span>
            <div className="inputs">
              <div className="item">
                <span>Name</span>
                <input />
              </div>
              <div className="item">
                <span>Email</span>
                <input />
              </div>
            </div>
            <div className="inputs">
              <div className="item">
                <span>Phone Number</span>
                <input />
              </div>
              <div className="item">
                <span>Instagram Account</span>
                <input />
              </div>
            </div>
            <div className="item">
              <span>Instagram Account</span>
              <input />
            </div>
            <button>Send Message</button>
            <span className="footnote-right">
              Copyright © 2022 | Mark Studios | All Rights Reserved
            </span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Landing;
