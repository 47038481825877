import "./Inbox.scss";
import { BiSearch, BiPin, BiPlus, BiLink, BiSend } from "react-icons/bi";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BsFillTrashFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { RiAttachment2 } from "react-icons/ri";
import { GoMention } from "react-icons/go";
import { GrEmoji } from "react-icons/gr";
import { useEffect, useRef, useState } from "react";
import { BiLeftArrow } from "react-icons/bi";
import io, { Socket } from "socket.io-client";
import Cookies from "js-cookie";
import { useLocation, useNavigate, useOutletContext } from "react-router";
import Swal from "sweetalert2";
import { uploadService } from "../../../../services/upload.service";
import LoadingUpload from "../../../loadingUpload/LoadingUpload";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

const CustomerInbox = () => {
  const [
    askAdminContext,
    setAskAdminContext,
    setOrderTabContext,
    userContext,
    setUserContext,
    notifications,
    setNotifications,
    notificationSocket,
  ] = useOutletContext();
  const { state } = useLocation();
  const [askAdmin, setAskAdmin, setOrderTab, user, setUser] =
    useOutletContext();
  const [socket, setSocket] = useState(null);
  const [attachment, setAttachment] = useState({});
  const [isGroupsShowing, setIsGroupsShowing] = useState(true);
  const [isDirectsShowing, setIsDirectsShowing] = useState(true);
  const [isSearchingBarShown, setIsSearchingBarShown] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isNewChatOpen, setIsNewChatOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersDisplayed, setUsersDisplayed] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupImage, setGroupImage] = useState({});
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [groupsReference, setGroupsReference] = useState([]);
  const [directsReference, setDirectsReference] = useState([]);
  const [groups, setGroups] = useState([]);
  const [directs, setDirects] = useState([]);
  const [searchChat, setSearchChat] = useState("");
  const [activeChatReference, setActiveChatReference] = useState({});
  const [activeChat, setActiveChat] = useState({});
  const [currentText, setCurrentText] = useState("");
  const [isSearchChatsShown, setIsSearchChatsShown] = useState(false);
  const [searchMessage, setSearchMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (socket && socket !== null) {
      socket.on("chat:room-deleted", (data) => {
        if (activeChat.id === data.data) {
          Swal.fire({
            title: "Chat Deleted",
            text: "This chat room was just deleted by another user",
            icon: "warning",
          }).then(() => {
            setActiveChat({});
            setActiveChatReference({});
            setIsChatOpen(false);
            socket.emit("chat:get", { user: "Luis" });
            socket.on("chat:get-return", (data) => {
              setChats(data);
            });
          });
        } else {
          socket.emit("chat:get", { user: "Luis" });
          socket.on("chat:get-return", (data) => {
            setChats(data);
          });
        }
      });
    }
  }, [activeChat]);

  useEffect(() => {
    const newSocket = io(`https://api.markstudioshub.com/chat`, {
      // const newSocket = io(`http://localhost:3001/chat`, {
      query: { token: Cookies.get("token") },
    });
    setSocket(newSocket);
    newSocket.emit("chat:get", { user: "Luis" });
    newSocket.on("chat:get-return", (data) => {
      setChats(data);
    });
    newSocket.on("chat:created", () => {
      newSocket.emit("chat:get", { user: "Luis" });
      newSocket.on("chat:get-return", (data) => {
        setChats(data);
      });
    });
    newSocket.on("chat:message-new", () => {
      newSocket.emit("chat:get", { user: "Luis" });
      newSocket.on("chat:get-return", (data) => {
        setChats(data);
      });
    });
    newSocket.on("chat:room-deleted", (data) => {
      if (activeChat.id === data.data) {
        Swal.fire({
          title: "Chat Deleted",
          text: "This chat room was just deleted by another user",
          icon: "warning",
        }).then(() => {
          setActiveChat({});
          setActiveChatReference({});
          setIsChatOpen(false);
          newSocket.emit("chat:get", { user: "Luis" });
          newSocket.on("chat:get-return", (data) => {
            setChats(data);
          });
        });
      } else {
        newSocket.emit("chat:get", { user: "Luis" });
        newSocket.on("chat:get-return", (data) => {
          setChats(data);
        });
      }
    });
    return () => {
      console.log("disconnecting");
      newSocket.emit("chat:disconnect");
      // newSocket.close()
    };
  }, []);

  useEffect(() => {
    if (socket !== null) {
      socket.on("chat:new-message", (data) => {
        const obj = { ...activeChatReference };

        if (!data.id) {
          obj.messageDays.splice(0, 0, data);
        } else {
          obj.messageDays[0] = data;
        }

        setActiveChat(obj);
        setActiveChatReference(obj);

        socket.emit("chat:get", { user: "Luis" });
        socket.on("chat:get-return", (data) => {
          setChats(data);
        });
      });
    }
  }, [activeChatReference]);

  useEffect(() => {
    const groupsArr = chats
      .filter((chat) => chat.type === "group")
      .map((group) => {
        group.messageDays = group.messageDays.sort(function (a, b) {
          var keyA = a.id,
            keyB = b.id;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        for (let item of group.messageDays) {
          item.messages = item.messages.sort(function (a, b) {
            var keyA = a.id,
              keyB = b.id;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
        }

        return group;
      });

    const directsArr = chats
      .filter((chat) => chat.type === "direct")
      .map((direct) => {
        direct.messageDays = direct.messageDays.sort(function (a, b) {
          var keyA = a.id,
            keyB = b.id;
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        for (let item of direct.messageDays) {
          item.messages = item.messages.sort(function (a, b) {
            var keyA = a.id,
              keyB = b.id;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
        }

        return direct;
      });

    if (directsArr && directsArr.length) {
      setActiveChat(directsArr[0]);
      setIsChatOpen(true);
      notificationSocket.emit("notification:read-one", {
        chatId: directsArr[0].id,
      });
    }
    setGroups(groupsArr);
    setDirects(directsArr);
    setGroupsReference(groupsArr);
    setDirectsReference(directsArr);
  }, [chats]);

  useEffect(() => {
    if (groupsReference && groupsReference.length) {
      filterGroups(searchChat);
    }

    if (directsReference && directsReference.length) {
      filterDirects(searchChat);
    }
  }, [searchChat]);

  useEffect(() => {
    if (
      activeChatReference.messageDays &&
      activeChatReference.messageDays.length
    ) {
      filterMessage(searchMessage);
    }
  }, [searchMessage]);

  useEffect(() => {
    filterDirects("");
    filterGroups("");
  }, [groupsReference, directsReference]);

  useEffect(() => {
    filterMessage("");
  }, [activeChatReference]);

  useEffect(() => {
    if (socket && socket !== null) {
      if (state && state.chat && state.chat !== null) {
        socket.emit("chat:details", { chatId: state.chat.data.id });
        socket.on("chat:details-return", (data) => {
          setActiveChat(data);
          setActiveChatReference(data);
          setSearchMessage("");
          if (getWindowDimensions().width < 1200) {
            setIsChatOpen(true);
          }
        });
      }
    }
  }, [groups]);

  const filterGroups = (text) => {
    const groupsArr = [...groupsReference].filter((group) =>
      group.name.includes(text)
    );

    setGroups(groupsArr);
  };

  const filterDirects = (text) => {
    const directsArr = [...directsReference].filter((direct) =>
      direct.name.includes(text)
    );

    setDirects(directsArr);
  };

  const filterMessage = (text) => {
    if (
      activeChatReference.messageDays &&
      activeChatReference.messageDays.length
    ) {
      const obj = JSON.parse(JSON.stringify(activeChatReference));
      obj.messageDays = obj.messageDays.map((messageDay) => {
        messageDay.messages = messageDay.messages.filter((message) => {
          if (message.message && message.message !== null) {
            return message.message.toLowerCase().includes(text.toLowerCase());
          } else {
            return message.attachments.originalName
              .toLowerCase()
              .includes(text.toLowerCase());
          }
        });

        return messageDay;
      });

      setActiveChat(obj);
    }
  };

  const filterUsers = (users, filter) => {
    const arr = users.filter((user) => {
      return (
        user.firstName.toLowerCase().includes(filter.toLowerCase()) ||
        user.lastName.toLowerCase().includes(filter.toLowerCase())
      );
    });

    setUsersDisplayed(arr);
  };

  const sendMessage = () => {
    if (currentText.trim() !== "" || attachment.url) {
      socket.emit("chat:message", {
        message: currentText,
        attachments: attachment,
        chatId: activeChat.id,
      });
      socket.on("chat:message-return", (data) => {
        const obj = { ...activeChat };

        if (!data.id) {
          obj.messageDays.push(data);
        } else {
          obj.messageDays[obj.messageDays.length - 1] = data;
        }

        setActiveChat(obj);
        setActiveChatReference(obj);
        setSearchMessage("");
        socket.emit("chat:get", { user: "Luis" });
        socket.on("chat:get-return", (data) => {
          setChats(data);
        });
        setCurrentText("");
        setAttachment({});
        setIsSearchingBarShown(false);
      });
    }
  };

  const uploadData = (e, parameter, callback) => {
    setLoading(true);
    let file = e.target.files[0];

    if (file) {
      let data = new FormData();
      data.append("file", file);

      uploadService.upload(data, (data) => {
        let file = {};
        if (data.originalName) {
          file = data;
        }

        setLoading(false);
        if (parameter) {
          // callback(data);
          setAttachment(data);
        } else {
          // callback(file);
          setAttachment(file);
        }
      });
    }
  };

  const deleteChat = () => {
    Swal.fire({
      title: "Alert!",
      text: `Are you sure you want to delete this ${
        activeChat.type === "group" ? "group" : "chat"
      }? This action is irreversible.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((isConfirm) => {
      if (isConfirm) {
        socket.emit("chat:delete", { chatId: activeChat.id });
        socket.on("chat:delete-return", (data) => {
          if (data === true) {
            Swal.fire({
              title: "Success!",
              text: "Chat successfully deleted!",
              icon: "success",
            });
            setActiveChatReference({});
            setActiveChat({});
            setIsChatOpen(false);
            socket.emit("chat:get", { user: "Luis" });
            socket.on("chat:get-return", (data) => {
              setChats(data);
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: "An error occurred while deleting the chat",
              icon: "error",
            });
          }
        });
      }
    });
  };

  return (
    <div className="customer-layout-inbox-page">
      {loading ? <LoadingUpload /> : null}
      {isNewChatOpen && (
        <div className="new-chat-open">
          <div className="new-chat-open-container">
            {searchedUsers.length > 1 ? (
              <div className="group-header">
                <div className="image-group">
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    style={{ display: "none" }}
                    id={`upload-profile-picture-group`}
                    onChange={(e) => {
                      uploadData(e, true, setGroupImage);
                    }}
                  />
                  <label for="upload-profile-picture-group">
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "100%",
                        overflow: "hidden",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "50px", cursor: "pointer" }}
                        src={
                          groupImage && groupImage.url
                            ? groupImage.url
                            : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                        }
                      />
                    </div>
                  </label>
                </div>
                <input
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  placeholder="Group Name"
                />
              </div>
            ) : null}
            <input
              placeholder="Search User"
              onChange={(e) => {
                filterUsers(users, e.target.value);
              }}
            />
            <div className="users">
              {usersDisplayed.map((userDisplayed) => {
                if (userDisplayed.id !== user.id) {
                  return (
                    <div className="user">
                      <div className="image-container">
                        <img
                          src={
                            userDisplayed.profilePicture !== null
                              ? userDisplayed.profilePicture.url
                              : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                          }
                        />
                      </div>
                      <span>
                        {userDisplayed.firstName} {userDisplayed.lastName}
                      </span>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSearchedUsers((searchedUsers) => [
                              ...searchedUsers,
                              userDisplayed,
                            ]);
                          } else {
                            const newArr = searchedUsers.filter(
                              (item) => item.id !== userDisplayed.id
                            );
                            setSearchedUsers(newArr);
                          }
                        }}
                        checked={searchedUsers
                          .map((item) => item.id)
                          .includes(userDisplayed.id)}
                      />
                    </div>
                  );
                }
              })}
            </div>
            <div className="actions">
              <button
                onClick={() => {
                  setIsNewChatOpen(false);
                }}
                className="cancel"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (searchedUsers.length === 0) {
                    Swal.fire({
                      title: "Error!",
                      text: "Please add some users",
                      icon: "error",
                    });
                  } else if (
                    searchedUsers.length > 1 &&
                    (!groupName || groupName.trim() === "")
                  ) {
                    Swal.fire({
                      title: "Error!",
                      text: "Please add a name to your group",
                      icon: "error",
                    });
                  } else if (
                    searchedUsers.length > 1 &&
                    (!groupImage || !groupImage.url)
                  ) {
                    Swal.fire({
                      title: "Error!",
                      text: "Please add an image to your group",
                      icon: "error",
                    });
                  } else {
                    const type = searchedUsers.length > 1 ? "group" : "direct";
                    let name;
                    let image = null;
                    if (type === "group") {
                      name = groupName;
                      image = groupImage;
                    } else {
                      name = `${user.id}-${searchedUsers[0].id}`;
                    }
                    const obj = {
                      type,
                      name,
                      users: [...searchedUsers, user],
                      image,
                    };

                    socket.emit("chat:create", { chat: obj });
                    socket.on("chat:create-return", (data) => {
                      setChats(data);
                    });
                    setIsNewChatOpen(false);
                  }
                }}
                className="create"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <div className="side-nav">
        <div className="header">
          <span>Conversations</span>
        </div>
        {isSearchChatsShown ? (
          <input
            className="search-chat-messages"
            value={searchChat}
            onChange={(e) => setSearchChat(e.target.value)}
          />
        ) : null}
        <div className="messages">
          {groups && groups.length && groups[0] ? (
            <>
              <div
                className="header"
                onClick={() => setIsGroupsShowing(!isGroupsShowing)}
              >
                <span>
                  {isGroupsShowing ? (
                    <IoIosArrowDown color="#2A9DF4" />
                  ) : (
                    <IoIosArrowUp color="#2A9DF4" />
                  )}
                </span>
                <span className="title">Groups</span>
              </div>
              {isGroupsShowing && (
                <div className="message-containers">
                  {groups &&
                    groups.length &&
                    groups[0] &&
                    groups.map((group) => {
                      return (
                        <div
                          className={`message ${
                            group.name === activeChat.name &&
                            getWindowDimensions().width > 1200
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            socket.emit("chat:details", { chatId: group.id });
                            socket.on("chat:details-return", (data) => {
                              notificationSocket.emit("notification:read-one", {
                                chatId: group.id,
                              });
                              notificationSocket.on(
                                "notification:read-return",
                                (data) => {
                                  setNotifications(data);
                                }
                              );
                              setActiveChat(data);
                              setActiveChatReference(data);
                              setSearchMessage("");
                              if (getWindowDimensions().width < 1200) {
                                setIsChatOpen(true);
                              }
                            });
                          }}
                        >
                          {[...notifications]
                            .map((notification) => {
                              if (notification.chatId && !notification.read) {
                                return notification.chatId;
                              } else {
                                return null;
                              }
                            })
                            .includes(group.id) ? (
                            <div className="red-dot"></div>
                          ) : null}
                          <div className="img-container">
                            <img src={group.image.url} />
                          </div>
                          <div className="texts">
                            <span>{group.name}</span>
                            {group.messageDays &&
                            group.messageDays !== null &&
                            group.messageDays.length ? (
                              <span className="text">
                                {`${
                                  group.messageDays[
                                    group.messageDays.length - 1
                                  ].messages[
                                    group.messageDays[
                                      group.messageDays.length - 1
                                    ].messages.length - 1
                                  ].from.firstName
                                } ${
                                  group.messageDays[
                                    group.messageDays.length - 1
                                  ].messages[
                                    group.messageDays[
                                      group.messageDays.length - 1
                                    ].messages.length - 1
                                  ].from.lastName
                                }`}
                                :{" "}
                                {
                                  group.messageDays[
                                    group.messageDays.length - 1
                                  ].messages[
                                    group.messageDays[
                                      group.messageDays.length - 1
                                    ].messages.length - 1
                                  ].message
                                }
                              </span>
                            ) : null}
                          </div>
                          <div className="details">
                            {group.messageDays &&
                            group.messageDays !== null &&
                            group.messageDays.length ? (
                              <span>
                                {new Date(
                                  group.messageDays[
                                    group.messageDays.length - 1
                                  ].messages[
                                    group.messageDays[
                                      group.messageDays.length - 1
                                    ].messages.length - 1
                                  ].createdAt
                                ).toLocaleDateString()}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </>
          ) : null}
        </div>
        <div className="messages">
          {directs && directs.length && directs[0] ? (
            <>
              <div
                className="header"
                onClick={() => setIsDirectsShowing(!isDirectsShowing)}
              >
                <span>
                  {isDirectsShowing ? (
                    <IoIosArrowDown color="#2A9DF4" />
                  ) : (
                    <IoIosArrowUp color="#2A9DF4" />
                  )}
                </span>
                <span className="title">Direct Messages</span>
              </div>
              {isDirectsShowing && (
                <div className="message-containers">
                  {directs &&
                    directs.length &&
                    directs[0] &&
                    directs[0].name &&
                    directs.map((direct) => {
                      return (
                        <div
                          onClick={() => {
                            socket.emit("chat:details", { chatId: direct.id });
                            socket.on("chat:details-return", (data) => {
                              console.log(socket);
                              notificationSocket.emit("notification:read-one", {
                                chatId: direct.id,
                              });
                              notificationSocket.on(
                                "notification:read-return",
                                (data) => {
                                  setNotifications(data);
                                }
                              );
                              setActiveChat(data);
                              setActiveChatReference(data);
                              setSearchMessage("");
                              if (getWindowDimensions().width < 1200) {
                                setIsChatOpen(true);
                              }
                            });
                          }}
                          className={`message ${
                            direct.name === activeChat.name &&
                            getWindowDimensions().width > 1200
                              ? "active"
                              : ""
                          }`}
                        >
                          {[...notifications]
                            .map((notification) => {
                              if (notification.chatId && !notification.read) {
                                return notification.chatId;
                              } else {
                                return null;
                              }
                            })
                            .includes(direct.id) ? (
                            <div className="red-dot"></div>
                          ) : null}
                          <div className="img-container">
                            <img
                              src={
                                direct.image
                                  ? direct.image.url
                                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                              }
                            />
                          </div>
                          <div className="texts">
                            <span>{direct.name}</span>
                            {direct.messageDays &&
                            direct.messageDays !== null &&
                            direct.messageDays.length ? (
                              <span className="text">
                                {
                                  direct.messageDays[
                                    direct.messageDays.length - 1
                                  ].messages[
                                    direct.messageDays[
                                      direct.messageDays.length - 1
                                    ].messages.length - 1
                                  ].message
                                }
                              </span>
                            ) : null}
                          </div>
                          <div className="details">
                            {direct.messageDays &&
                            direct.messageDays !== null &&
                            direct.messageDays.length ? (
                              <span>
                                {new Date(
                                  direct.messageDays[
                                    direct.messageDays.length - 1
                                  ].messages[
                                    direct.messageDays[
                                      direct.messageDays.length - 1
                                    ].messages.length - 1
                                  ].createdAt
                                ).toLocaleDateString()}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </>
          ) : null}
        </div>
      </div> */}
      {getWindowDimensions().width > 1200 && activeChat.name ? (
        <div className="content-inbox">
          <div className="header">
            <div className="main-header">
              <div className="profile">
                <div className="image">
                  <img
                    src={
                      activeChat.image
                        ? activeChat.image.url
                        : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                    }
                  />
                </div>
                <div className="details">
                  <span>{activeChat.name}</span>
                  {activeChat.users.length === 2 ? (
                    <span className="text">
                      {activeChat.otherUser.type === "editor"
                        ? `Editor Level ${activeChat.otherUser.level}`
                        : activeChat.otherUser.type === "customer"
                        ? "Customer"
                        : "Admin"}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="icons">
                <div className="icon">
                  <BiSearch onClick={() => setIsSearchingBarShown(true)} />
                </div>
                {/* <div className="icon" onClick={() => deleteChat()}>
                  <BsFillTrashFill />
                </div> */}
              </div>
            </div>
            {isSearchingBarShown && (
              <div className="searchBar">
                <div className="search">
                  <span>
                    <BiSearch size={20} />
                  </span>
                  <input
                    placeholder="Search"
                    value={searchMessage}
                    onChange={(e) => setSearchMessage(e.target.value)}
                  />
                </div>
                <div className="icons">
                  <span>
                    <AiOutlineClose
                      size={20}
                      onClick={() => {
                        setIsSearchingBarShown(false);
                        setSearchMessage("");
                      }}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="message-body-content">
            {activeChat.messageDays.map((messageDay) => {
              return (
                <div className="messageDay">
                  <div className="day">
                    {`${new Date(messageDay.createdAt).getUTCDate()}/${
                      new Date(messageDay.createdAt).getUTCMonth() + 1
                    }/${new Date(messageDay.createdAt).getUTCFullYear()}`}
                  </div>
                  {messageDay.messages.map((message) => {
                    return (
                      <div className="message">
                        <div className="image-container">
                          <img
                            src={
                              message.from.id !== user.id
                                ? message.from.profilePicture
                                  ? message.from.profilePicture.url
                                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                                : user.profilePicture
                                ? user.profilePicture.url
                                : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                            }
                          />
                        </div>
                        <div className="message-day-content">
                          <div className="header-chat">
                            <span>
                              {message.from.id !== user.id
                                ? message.from.firstName
                                : "You"}
                            </span>
                            <span className="time">
                              {new Date(message.createdAt).toLocaleDateString()}
                            </span>
                          </div>
                          <div className="text">{message.message}</div>
                          <div className="attachments">
                            {message.attachments && (
                              <div
                                className="attachment"
                                onClick={() => {
                                  window
                                    .open(message.attachments.url, "_blank")
                                    .focus();
                                }}
                              >
                                <RiAttachment2 color="#2A9DF4" size={20} />
                                <span>{message.attachments.originalName}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <AlwaysScrollToBottom />
                </div>
              );
            })}
          </div>
          <div className="footer">
            <div className="icons">
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                style={{ display: "none" }}
                id={`attach-file-chat-web`}
                onChange={(e) => {
                  uploadData(e, false, sendMessage);
                }}
              />
              <label for="attach-file-chat-web" className="icon">
                <RiAttachment2 color="#6D5DD3" />
              </label>
            </div>
            {/* <input
              value={currentText}
              onChange={(e) => setCurrentText(e.target.value)}
              placeholder="Type your message here…"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendMessage();
                }
              }}
            /> */}
            {!attachment || (attachment && !attachment.url) ? (
              <input
                value={currentText}
                onChange={(e) => setCurrentText(e.target.value)}
                placeholder="Type your message here…"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    sendMessage();
                  }
                }}
              />
            ) : (
              <div
                className="attachment"
                onClick={() => {
                  window.open(attachment.url, "_blank").focus();
                }}
              >
                <RiAttachment2 color="#2A9DF4" size={20} />
                <span>{attachment.originalName}</span>
              </div>
            )}
            <div
              className="icon send"
              onClick={() => {
                sendMessage();
              }}
            >
              <BiSend color="white" />
            </div>
          </div>
        </div>
      ) : (
        <div className="content-inbox"></div>
      )}
      {getWindowDimensions().width < 1200 && isChatOpen && (
        <div className="content-inbox-mobile">
          <div className="content-inbox-mobile-container">
            <div className="header">
              <div className="main-header">
                <BiLeftArrow
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/customer/dashboard")}
                />
                <div className="profile">
                  <div className="image">
                    <img
                      src={
                        activeChat.image
                          ? activeChat.image.url
                          : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                      }
                    />
                  </div>
                  <div className="details">
                    <span>{activeChat.name}</span>
                    {activeChat.users.length === 2 ? (
                      <span className="text">
                        {activeChat.otherUser.type === "editor"
                          ? `Editor Level ${activeChat.otherUser.level}`
                          : activeChat.otherUser.type === "customer"
                          ? "Customer"
                          : "Admin"}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="icons">
                  <div className="icon">
                    <BiSearch onClick={() => setIsSearchingBarShown(true)} />
                  </div>
                  {/* <div className="icon" onClick={() => deleteChat()}>
                    <BsFillTrashFill />
                  </div> */}
                </div>
              </div>
              {isSearchingBarShown && (
                <div className="searchBar">
                  <div className="search">
                    <span>
                      <BiSearch size={20} />
                    </span>
                    <input
                      placeholder="Search"
                      value={searchMessage}
                      onChange={(e) => setSearchMessage(e.target.value)}
                    />
                  </div>
                  <div className="icons">
                    <span>
                      <AiOutlineClose
                        size={20}
                        onClick={() => {
                          setIsSearchingBarShown(false);
                          setSearchMessage("");
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="message-body-content">
              {activeChat.messageDays.map((messageDay) => {
                return (
                  <div className="messageDay">
                    <div className="day">
                      {`${new Date(messageDay.createdAt).getUTCDate()}/${
                        new Date(messageDay.createdAt).getUTCMonth() + 1
                      }/${new Date(messageDay.createdAt).getUTCFullYear()}`}
                    </div>
                    {messageDay.messages.map((message) => {
                      return (
                        <div className="message">
                          <div className="image-container">
                            <img
                              src={
                                message.from.id !== user.id
                                  ? message.from.profilePicture
                                    ? message.from.profilePicture.url
                                    : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                                  : user.profilePicture
                                  ? user.profilePicture.url
                                  : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                              }
                            />
                          </div>
                          <div className="message-day-content">
                            <div className="header-chat">
                              <span>
                                {message.from.id !== user.id
                                  ? message.from.firstName
                                  : "You"}
                              </span>
                              <span className="time">
                                {new Date(
                                  message.createdAt
                                ).toLocaleDateString()}
                              </span>
                            </div>
                            <div className="text">{message.message}</div>
                            <div className="attachments">
                              {message.attachments && (
                                <div
                                  className="attachment"
                                  onClick={() => {
                                    window
                                      .open(message.attachments.url, "_blank")
                                      .focus();
                                  }}
                                >
                                  <RiAttachment2 color="#2A9DF4" size={20} />
                                  <span>
                                    {message.attachments.originalName}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <AlwaysScrollToBottom />
            </div>
            <div className="footer">
              <div className="icons">
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                  id={`attach-file-chat-mobile`}
                  onChange={(e) => {
                    uploadData(e, false, sendMessage);
                  }}
                />
                <label for="attach-file-chat-mobile" className="icon">
                  <RiAttachment2 color="#6D5DD3" />
                </label>
              </div>
              {!attachment || (attachment && !attachment.url) ? (
                <input
                  value={currentText}
                  onChange={(e) => setCurrentText(e.target.value)}
                  placeholder="Type your message here…"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sendMessage();
                    }
                  }}
                />
              ) : (
                <div
                  className="attachment"
                  onClick={() => {
                    window.open(attachment.url, "_blank").focus();
                  }}
                >
                  <RiAttachment2 color="#2A9DF4" size={20} />
                  <span>{attachment.originalName}</span>
                </div>
              )}
              <div
                className="icon send"
                onClick={() => {
                  sendMessage();
                }}
              >
                <BiSend color="white" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerInbox;
