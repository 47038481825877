import "./Recovery.scss";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { authService } from "../../../../services/auth.service";
import { Link } from "react-router-dom";

const AdminRecovery = () => {
  const [isPasswordShown, setIsPasswordShows] = useState(false);
  const [setIsTermsOfServiceOpen, setIsPrivacyPolicyOpen] = useOutletContext();
  const navigate = useNavigate();
  const [isRepeatPasswordShown, setIsRepeatPasswordShows] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      repeatPassword: "",
    },
    onSubmit: (values) => {
      if (
        !values.password ||
        values.password.trim() === "" ||
        !values.repeatPassword ||
        values.repeatPassword.trim() === "" ||
        values.password !== values.repeatPassword
      ) {
        Swal.fire({
          title: "Error!",
          text: "You need to enter the same non-empty password in the fields above",
          icon: "error",
        });
      } else {
        authService.resetPassword(values.email, values.password, () => {
          Swal.fire({
            title: "Password reset!",
            icon: "success",
          }).then(() => {
            navigate("/auth/admin/sign-in");
          });
        });
      }
    },
  });

  return (
    <div className="auth-admin-recovery">
      <div className="login-box">
        <span className="title">Recovery</span>
        <div className="login-info">
          <div className="input-box">
            <span className="input-title">Email Address</span>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="youremail@gmail.com"
            />
          </div>
          <div className="input-box">
            <span className="input-title">Password</span>
            <div className="password">
              <input
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                type={isPasswordShown ? "text" : "password"}
                placeholder="••••••••"
              />
              {isPasswordShown ? (
                <AiOutlineEye
                  onClick={() => setIsPasswordShows(!isPasswordShown)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() => setIsPasswordShows(!isPasswordShown)}
                />
              )}
            </div>
          </div>
          <div className="input-box">
            <span className="input-title">Password</span>
            <div className="password">
              <input
                id="repeatPassword"
                name="repeatPassword"
                onChange={formik.handleChange}
                value={formik.values.repeatPassword}
                type={isRepeatPasswordShown ? "text" : "password"}
                placeholder="••••••••"
              />
              {isRepeatPasswordShown ? (
                <AiOutlineEye
                  onClick={() =>
                    setIsRepeatPasswordShows(!isRepeatPasswordShown)
                  }
                />
              ) : (
                <AiOutlineEyeInvisible
                  onClick={() =>
                    setIsRepeatPasswordShows(!isRepeatPasswordShown)
                  }
                />
              )}
            </div>
          </div>
        </div>
        <button className="login" onClick={formik.submitForm}>
          Reset Password
        </button>
      </div>
      <div className="footer">
        <Link to={"/auth/admin/sign-in"} className="already-have">
          Already have an account? <b>Sign in</b>
        </Link>
        <div className="terms-privacy-policy">
          <span onClick={() => setIsTermsOfServiceOpen(true)}>
            Terms of use
          </span>
          <span onClick={() => setIsPrivacyPolicyOpen(true)}>
            Privacy Policy
          </span>
          <span>Support</span>
        </div>
      </div>
    </div>
  );
};

export default AdminRecovery;
