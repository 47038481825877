import "./VideoElements.scss";
import Youtube from "../../../../../../../assets/youtube.png";
import Facebook from "../../../../../../../assets/facebook.png";
import Instagram from "../../../../../../../assets/instagram.png";
import Twitter from "../../../../../../../assets/twitter.png";
import Tiktok from "../../../../../../../assets/tiktok.png";
import Linkedin from "../../../../../../../assets/linkedin.png";
import Snapchat from "../../../../../../../assets/snapchat.png";
import Website from "../../../../../../../assets/website.png";
import { useState, useEffect } from "react";

const VideoElements = ({
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  user,
  order,
}) => {
  const [text, setText] = useState("");
  const [jumpCuts, setJumpCuts] = useState("");
  const [lowThird, setLowThird] = useState("");
  const [transition, setTransition] = useState("");

  useEffect(() => {
    if (order.videoElements.text !== "") {
      setText(order.videoElements.text);
    }

    if (order.videoElements.jumpCuts !== "") {
      setJumpCuts(order.videoElements.jumpCuts);
    }

    if (order.videoElements.lowThird !== "") {
      setLowThird(order.videoElements.lowThird);
    }

    if (order.videoElements.transition !== "") {
      setTransition(order.videoElements.transition);
    }
  }, [order]);

  const changeStep = (isStepNext) => {
    if (isStepNext) {
      if (validateFields()) {
        changeComponent("videoElements", {
          text,
          jumpCuts,
          lowThird,
          transition,
        });

        nextStep();
      } else {
        validate("Please check that you added all required information");
      }
    } else {
      prevStep();
    }
  };

  const validateFields = () => {
    if (!text || text.trim() === "") {
      return false;
    }

    if (!jumpCuts || jumpCuts.trim() === "") {
      setJumpCuts(null);
    }

    if (!lowThird || lowThird.trim() === "") {
      setLowThird(null);
    }

    if (!transition || transition.trim() === "") {
      setTransition(null);
    }

    return true;
  };

  return (
    <div className="video-elements-new-order">
      <div className="inner-content">
        <div className="inputs">
          <div className="item">
            <span>What text woud you like to add to this video?</span>
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter your text"
            />
          </div>
        </div>
        <div className="inputs">
          <div className="item">
            <span>Jump Cuts Description (optional)</span>
            <textarea
              value={jumpCuts}
              onChange={(e) => setJumpCuts(e.target.value)}
              placeholder="Enter your text"
            />
          </div>
          <div className="item">
            <span>Low Third Description (optional)</span>
            <textarea
              value={lowThird}
              onChange={(e) => setLowThird(e.target.value)}
              placeholder="Enter your text"
            />
          </div>
          <div className="item">
            <span>Transition Description (optional)</span>
            <textarea
              value={transition}
              onChange={(e) => setTransition(e.target.value)}
              placeholder="Enter your text"
            />
          </div>
        </div>
      </div>
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}
        <button
          className="next"
          onClick={() => {
            changeStep(true);
          }}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default VideoElements;
