import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import "./Order.scss";
import {
  AiOutlineClose,
  AiOutlineArrowLeft,
  AiFillCalendar,
  AiOutlinePrinter,
  AiFillMinusCircle,
  AiOutlineFile,
  AiOutlinePlus,
  AiFillStar,
} from "react-icons/ai";
import { HiOutlineChat } from "react-icons/hi";
import { RiAttachment2 } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import User2 from "../../../../../assets/user2.png";
import User1 from "../../../../../assets/user1.png";
import { reference } from "../../../../../components/ordersReference";
import { orderService } from "../../../../../services/order.service";
import Loading from "../../../../loading/Loading";
import { createHTMLOrder } from "./setItems";
import { uploadService } from "../../../../../services/upload.service";
import Swal from "sweetalert2";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";
import { createHTMLOrderPrint } from "../../../../../components/setItemsPrint";
import moment from "moment";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const EditorOrder = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [orderStatusName, setOrderStatusName] = useState("");
  const [commentsShown, setCommentsShown] = useState(false);
  const [isFilesShown, setIsFilesShown] = useState(false);
  const [order, setOrder] = useState({});
  const [tableItems, setTableItems] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    orderService.getDetails(params.orderId, (order) => {
      setOrder(order);
    });
  }, [params]);

  useEffect(() => {
    if (order && order.status) {
      setOrderStatusName(
        reference.filter((item) => item.status === order.status)[0].name
      );
      setTableItems(createHTMLOrder(order));
    }
  }, [order]);

  const downloadTxtFile = () => {
    const statusName = reference.filter(
      (item) => item.status === order.status
    )[0].name;
    const tableItems = createHTMLOrderPrint(order);

    const list = [
      `Order Number: #${order.number}`,
      "Order Name: " + order.name,
      `Created at: ${moment(order.createdAt).format("DD MMMM YYYY")}`,
      `Deadline at: ${moment(order.deadline).format("DD MMMM YYYY")}`,
      "Status: " + statusName + "\n",
      "Delivery Items:",
      tableItems
        .filter((item) => item.shown)
        .map((tableItem) => {
          return `${tableItem.name} -
                    ${tableItem.item.map((item) => {
                      return `- ${item}`;
                    })}`;
        }),
    ];
    const element = document.createElement("a");
    const file = new Blob([list.join("\n")], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `invoice_${order.number}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const comments = [
    {
      image: User2,
      name: "Piter Karter",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
    {
      image: User1,
      name: "You",
      time: "12:15 AM",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper nibh tellus ante at.",
    },
  ];

  const formatCurrency = (amount) => {
    let newAmount = "";
    let arrCount = 0;
    let amountToCheck = `${parseFloat(amount).toFixed(0)}`;
    const arr = `${amountToCheck}`.split("").reverse();
    for (const letter of arr) {
      arrCount++;
      newAmount = `${letter}${newAmount}`;
      if (arrCount !== arr.length && arrCount % 3 === 0) {
        newAmount = `,${newAmount}`;
      }
    }

    let result = `R$${newAmount}.00`;

    return result;
  };

  return order && order.status && tableItems && tableItems.length ? (
    <div className="editor-layout-order-delivery-page">
      {loading ? <LoadingUpload /> : null}
      {getWindowDimensions().width < 950 && commentsShown && (
        <div className="comments-container-side">
          <div className="comments-container">
            <div className="comments-header">
              <span className="title">Comments</span>
              <div
                className="icon-comments"
                onClick={() => setCommentsShown(false)}
              >
                <AiOutlineClose />
                <AiOutlinePlus
                  color="#2a9df4"
                  size={20}
                  className="add-comment"
                  onClick={() => {
                    Swal.fire({
                      title: "Add a comment",
                      input: "text",
                      showCancelButton: true,
                      closeOnConfirm: false,
                      inputPlaceholder: "Write a comment",
                      preConfirm: (inputValue) => {
                        return inputValue;
                      },
                    }).then((inputValue) => {
                      if (inputValue.isConfirmed) {
                        if (inputValue.value === null) return false;

                        if (inputValue.value === "") {
                          Swal.showInputError("You need to write something!");
                          return false;
                        }

                        orderService.addComment(
                          params.orderId,
                          inputValue.value,
                          (data) => {
                            orderService.getDetails(params.orderId, (order) => {
                              setOrder(order);
                            });
                          }
                        );
                      }
                    });
                  }}
                />
              </div>
            </div>
            <div className="comments-body">
              {order.comments.map((comment) => {
                return (
                  <div className="comment">
                    <div className="image">
                      <img
                        src={
                          comment.user.profilePicture !== null
                            ? comment.user.profilePicture.url
                            : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                        }
                        alt="profile"
                      />
                    </div>
                    <div className="text">
                      <div className="header">
                        <span className="name">
                          {comment.user.firstName} {comment.user.lastName}
                        </span>
                        <span className="time">
                          {new Date(comment.createdAt).toLocaleDateString()}
                        </span>
                      </div>
                      <span className="comment-inside">{comment.comment}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {getWindowDimensions().width < 950 && isFilesShown && (
        <div className="files-container-side">
          <div className="files-container">
            <div className="files-header">
              <span className="title">Order Attachments</span>
              <div
                className="icon-files"
                onClick={() => setIsFilesShown(false)}
              >
                <AiOutlineClose />
              </div>
            </div>
            <div className="files-body">
              <div className="items deliveries">
                <span className="title">
                  Deliveries{" "}
                  {order.deliverables.length > 1
                    ? ` (${order.deliverables.length})`
                    : ""}
                </span>
                <div className="files">
                  {order.deliverables.map((delivery) => {
                    return (
                      <div
                        className="item"
                        onClick={() => {
                          window.open(delivery.url, "_blank").focus();
                        }}
                      >
                        <div className="icon">
                          <RiAttachment2 />
                        </div>
                        <div className="specs">
                          <span>{delivery.originalName}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="link-back-container">
        <Link
          to="../orders"
          className="link-back"
          style={{ textDecoration: "none" }}
        >
          <AiOutlineArrowLeft size={20} color="#2A9DF4" />
          <span>Back to My Orders</span>
        </Link>
        {getWindowDimensions().width < 950 && (
          <div className="icons">
            <div
              className="icon"
              onClick={() => setIsFilesShown(!isFilesShown)}
            >
              <AiOutlineFile />
            </div>
            <div
              className="icon"
              onClick={() => setCommentsShown(!commentsShown)}
            >
              <HiOutlineChat
                color={comments && comments.length ? "#00D097" : "black"}
              />
            </div>
          </div>
        )}
      </div>
      <div className="order-container">
        <div className="details" style={{ flex: !commentsShown ? 8 : 5 }}>
          <div className="delivery-header">
            <div className="title-dates">
              <span className="order-number">#{order.number}</span>
              <span className="order-name">
                {order.basicDetails
                  ? order.basicDetails.title
                  : `Order #${order.number}`}
              </span>
              <div className="dates">
                <div className="date">
                  <AiFillCalendar size={20} color="#00D097" />
                  <span style={{ color: "#00D097" }}>
                    Created {new Date(order.createdAt).toLocaleDateString()}
                  </span>
                </div>
                <div className="date">
                  <AiFillCalendar size={20} color="#DA4747" />
                  <span style={{ color: "#DA4747" }}>
                    Deadline{" "}
                    {new Date(parseInt(order.deadline)).toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>
            <div className="actions">
              {params.orderId !== "revision" && (
                <div className="action">
                  <span className="title">Price</span>
                  <div style={{ color: "#EEC800", backgroundColor: "#F6F8B6" }}>
                    {formatCurrency(order.editorPay)}
                  </div>
                </div>
              )}
              <div className="action">
                <span className="title">Status</span>
                <div className={order.status}>
                  <span>{orderStatusName}</span>
                </div>
              </div>
              {params.orderId === "revision" && (
                <div
                  onClick={() => downloadTxtFile()}
                  className="action"
                  style={{ cursor: "pointer" }}
                >
                  <AiOutlinePrinter size={20} />
                  <span className="print">Print Invoice</span>
                </div>
              )}
            </div>
          </div>
          {order.status === "revision" &&
          order.revisionNotes &&
          order.revisionNotes !== null &&
          order.revisionNotes.length ? (
            <div className="revision-notes">
              <span className="revision-title">Revision Instructions</span>
              <span className="revision-text">
                {order.revisionNotes[order.revisionNotes.length - 1].note}
              </span>
            </div>
          ) : null}
          {order.status === "completed" && order.review && order.stars ? (
            <div className="review-notes">
              <span className="review-title">Review</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {new Array(order.stars).fill().map((_) => (
                  <AiFillStar color="#F1C40F" />
                ))}
                {new Array(5 - order.stars).fill().map((_) => (
                  <AiFillStar color="#E0E0E0" />
                ))}
              </div>
              <span className="review-text">{order.review}</span>
            </div>
          ) : null}
          <div className="order-instructions">
            {tableItems
              .filter((item) => item.shown)
              .map((tableItem, index) => {
                return (
                  <div className="sub-instruction">
                    <div className="header">
                      <span className="main-title">{tableItem.name}</span>
                      {tableItem.open ? (
                        <AiFillMinusCircle
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const obj = tableItems[index];
                            obj.open = !obj.open;
                            setTableItems([
                              ...tableItems.slice(0, index),
                              obj,
                              ...tableItems.slice(index + 1),
                            ]);
                          }}
                          size={20}
                          color="#2A9DF4"
                        />
                      ) : (
                        <IoMdAddCircle
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const obj = tableItems[index];
                            obj.open = !obj.open;
                            setTableItems([
                              ...tableItems.slice(0, index),
                              obj,
                              ...tableItems.slice(index + 1),
                            ]);
                          }}
                          size={20}
                          color="#2A9DF4"
                        />
                      )}
                    </div>
                    {tableItem.open && tableItem.item}
                  </div>
                );
              })}
          </div>
          {order.status !== "completed" &&
            order.status !== "cancelled" &&
            order.status !== "pending" &&
            order.status !== "submitted" &&
            order.status !== "draft" && (
              <div className="footer">
                <button
                  onClick={() => {
                    orderService.chatWithAdmin("admin", null, (data) => {
                      navigate("../inbox", { state: { chat: data } });
                    });
                  }}
                >
                  Chat with Admin
                </button>
                <input
                  style={{ display: "none" }}
                  type="file"
                  onChange={async (e) => {
                    const file = e.target.files[0];
                    setLoading(true);

                    if (file) {
                      let data = new FormData();
                      data.append("file", file);

                      await uploadService.upload(data, (data) => {
                        setLoading(false);
                        orderService.addFile(params.orderId, data, (data) => {
                          orderService.getDetails(params.orderId, (order) => {
                            setOrder(order);
                          });
                        });
                      });
                    }
                  }}
                  id="drop-files-add-delivery"
                />
                <label for="drop-files-add-delivery">Submit File</label>
                {order.deliverables &&
                order.deliverables.length &&
                order.fileSubmitted ? (
                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "Success",
                        text: "Congratulations! You just submitted this order. Please wait until a admin approves it.",
                        icon: "success",
                      }).then(() => {
                        orderService.completeOrder(order.id, (data) => {
                          orderService.getDetails(params.orderId, (order) => {
                            setOrder(order);
                          });
                        });
                      });
                    }}
                  >
                    Complete
                  </button>
                ) : null}
              </div>
            )}
        </div>
        {getWindowDimensions().width > 950 && (
          <div className="files-container">
            <div className="files-header">
              <div>Order attachments</div>
              <div className="icons">
                {getWindowDimensions().width > 950 && (
                  <div
                    className="icon"
                    onClick={() => setCommentsShown(!commentsShown)}
                  >
                    <HiOutlineChat
                      color={comments && comments.length ? "#00D097" : "black"}
                    />
                  </div>
                )}
                {/* <div className="icon">
                                    <AiOutlineDownload />
                                </div> */}
              </div>
            </div>
            <div className="items deliveries">
              <span className="title">
                Deliveries{" "}
                {order.deliverables > 1 ? ` (${order.deliverables})` : ""}
              </span>
              <div className="files">
                {order.deliverables.map((delivery) => {
                  return (
                    <div
                      className="item"
                      onClick={() => {
                        window.open(delivery.url, "_blank").focus();
                      }}
                    >
                      <div className="icon">
                        <RiAttachment2 />
                      </div>
                      <div className="specs">
                        <span>{delivery.originalName}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {commentsShown && getWindowDimensions().width > 950 && (
          <div className="comments-container">
            <div
              className="comments-back"
              onClick={() => setCommentsShown(false)}
            >
              <AiOutlineArrowLeft size={20} color="#2A9DF4" />
              <span>Close Comments</span>
            </div>
            <div className="comments">
              <div className="comments-header">
                <span className="title">Comments</span>
                <AiOutlinePlus
                  color="#2a9df4"
                  size={20}
                  className="add-comment"
                  onClick={() => {
                    Swal.fire({
                      title: "Add a comment",
                      input: "text",
                      showCancelButton: true,
                      closeOnConfirm: false,
                      inputPlaceholder: "Write a comment",
                      preConfirm: (inputValue) => {
                        return inputValue;
                      },
                    }).then((inputValue) => {
                      if (inputValue.isConfirmed) {
                        if (inputValue.value === null) return false;

                        if (inputValue.value === "") {
                          Swal.showInputError("You need to write something!");
                          return false;
                        }

                        orderService.addComment(
                          params.orderId,
                          inputValue.value,
                          (data) => {
                            orderService.getDetails(params.orderId, (order) => {
                              setOrder(order);
                            });
                          }
                        );
                      }
                    });
                  }}
                />
              </div>
              <div className="comments-content">
                {order.comments.map((comment) => {
                  return (
                    <div className="comment">
                      <div className="image">
                        <img
                          src={
                            comment.user.profilePicture !== null
                              ? comment.user.profilePicture.url
                              : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                          }
                          alt="profile"
                        />
                      </div>
                      <div className="text">
                        <div className="header">
                          <span className="name">
                            {comment.user.firstName} {comment.user.lastName}
                          </span>
                          <span className="time">
                            {new Date(comment.createdAt).toLocaleDateString()}
                          </span>
                        </div>
                        <span className="comment-inside">
                          {comment.comment}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default EditorOrder;
