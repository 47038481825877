import "./LoadingUpload.scss";

const LoadingUpload = ({ text = "Uploading" }) => {
  return (
    <div className="loading-container-upload">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span>{text}</span>
    </div>
  );
};

export default LoadingUpload;
