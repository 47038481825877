import { useEffect } from "react";
import { useNavigate } from "react-router";
import { orderService } from "../../../../../../../services/order.service";
import "./Finish.scss";

const Finish = ({ order }) => {
  const navigate = useNavigate();

  useEffect(() => {
    orderService.create(order, () => {});
  }, []);

  return (
    <div className="finish-new-order">
      <span className="main-title">Thank you for your order</span>
      <span className="main-sub-title">
        We will start editing your video within the next 12 hours.
      </span>
      <button onClick={() => navigate("../orders")}>Ok</button>
    </div>
  );
};

export default Finish;
