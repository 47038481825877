import './Layout.scss'
import Circle1 from '../../assets/auth/circle-1.png'
import Circle2 from '../../assets/auth/circle-2.png'
import Circle3 from '../../assets/auth/circle-3.png'
import Circle4 from '../../assets/auth/circle-4.png'
import Path from '../../assets/auth/path.png'
import PointsDetail1 from '../../assets/auth/points-detail.png'
import PointsDetail2 from '../../assets/auth/points-detail-2.png'
import LogoWhite from '../../assets/logo-white.png'
import Logo from '../../assets/logo.png'
import { Outlet } from 'react-router'
import { useState } from 'react'
import Modal from 'react-modal/lib/components/Modal'
import { AiOutlineArrowLeft } from 'react-icons/ai'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const AuthLayout = () => {
    const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false)
    const [isTermsOfServiceOpen, setIsTermsOfServiceOpen] = useState(false)
    return (
        <div className="auth-layout-main-container">
            <Modal
                isOpen={isTermsOfServiceOpen}
                onRequestClose={() => setIsTermsOfServiceOpen(false)}
                contentLabel="Terms Of Service"
                className="modal-auth"
                overlayClassName="overlay-modal-auth"
            >
                <img src={Logo} />
                <div className='back' onClick={() => setIsTermsOfServiceOpen(false)}>
                    <AiOutlineArrowLeft color='#2A9DF4' size={15} />
                    <span>Back</span>
                </div>
                <span className='title'>
                    Terms of Service
                </span>
                <div className='section'>
                    <span className='title'>
                        Last Revised: December 16, 2013
                    </span>
                    <span className='text'>
                        Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions contain legal obligations, please read them carefully.
                    </span>
                </div>
                <div className='section'>
                    <span className='title'>
                        1. YOUR AGREEMENT<br /><span className='subtitle'>By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.</span>
                    </span>
                    <span className='text'>
                        PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.
                    </span>
                </div>
                <div className='section'>
                    <span className='title'>
                        2. PRIVACY<br /><span className='subtitle'>Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.</span>
                    </span>
                </div>
                <div className='section'>
                    <span className='title'>
                        3. LINKED SITES<br /><span className='subtitle'>This Site may contain links to other independent third-party Web sites ("Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible for and does not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.</span>
                    </span>
                </div>
                <div className='section'>
                    <span className='title'>
                        4. FORWARD LOOKING STATEMENTS<br /><span className='subtitle'>All materials reproduced on this site speak as of the original date of publication or filing. The fact that a document is available on this site does not mean that the information contained in such document has not been modified or superseded by events or by a subsequent document or filing. We have no duty or policy to update any information or statements contained on this site and, therefore, such information or statements should not be relied upon as being current as of the date you access this site.</span>
                    </span>
                </div>
            </Modal>
            <Modal
                isOpen={isPrivacyPolicyOpen}
                onRequestClose={() => setIsPrivacyPolicyOpen(false)}
                contentLabel="Privacy Policy"
                className="modal-auth"
                overlayClassName="overlay-modal-auth"
            >
                <img src={Logo} />
                <div className='back' onClick={() => setIsPrivacyPolicyOpen(false)}>
                    <AiOutlineArrowLeft color='#2A9DF4' size={15} />
                    <span>Back</span>
                </div>
                <span className='title'>
                    Privacy Policy
                </span>
                <div className='section'>
                    <span className='title'>
                        Last Revised: December 16, 2013
                    </span>
                    <span className='text'>
                        This privacy policy ("policy") will help you understand how [name] ("us", "we", "our") uses and protects the data you provide to us when you visit and use [website] ("website", "service").<br />We reserve the right to change this policy at any given time, of which you will be promptly updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page.
                    </span>
                </div>
                <div className='section'>
                    <span className='title'>
                        What User Data We Collect<br /><span className='subtitle'>When you visit the website, we may collect the following data:</span>
                    </span>
                    <ul>
                        <li className='text'>
                            Your IP address.
                        </li>
                        <li className='text'>
                            Your contact information and email address.
                        </li>
                        <li className='text'>
                            Other information such as interests and preferences.
                        </li>
                        <li className='text'>
                            Data profile regarding your online behavior on our website.
                        </li>
                    </ul>
                </div>
                <div className='section'>
                    <span className='title'>
                        Why We Collect Your Data<br /><span className='subtitle'>We are collecting your data for several reasons:</span>
                    </span>
                    <ul>
                        <li className='text'>
                            To better understand your needs.
                        </li>
                        <li className='text'>
                            To improve our services and products.
                        </li>
                        <li className='text'>
                            To send you promotional emails containing the information we think you will find interesting.
                        </li>
                        <li className='text'>
                            To contact you to fill out surveys and participate in other types of market research.
                        </li>
                        <li className='text'>
                            To customize our website according to your online behavior and personal preferences.
                        </li>
                    </ul>
                </div>
                <div className='section'>
                    <span className='title'>
                        Safeguarding and Securing the Data<br /><span className='subtitle'>We are committed to securing your data and keeping it confidential. [name] has done all in its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online.</span>
                    </span>
                </div>
                <div className='section'>
                    <span className='title'>
                        Our Cookie Policy<br /><span className='subtitle'>Once you agree to allow our website to use cookies, you also agree to use the data it collects regarding your online behavior (analyze web traffic, web pages you spend the most time on, and websites you visit).<br /><br />The data we collect by using cookies is used to customize our website to your needs. After we use the data for statistical analysis, the data is completely removed from our systems.<br /><br />Please note that cookies don't allow us to gain control of your computer in any way. They are strictly used to monitor which pages you find useful and which you do not so that we can provide a better experience for you.<br /><br />If you want to disable cookies, you can do it by accessing the settings of your internet browser. (Provide links for cookie settings for major internet browsers).</span>
                    </span>
                </div>
                <div className='section'>
                    <span className='title'>
                        Links to Other Websites<br /><span className='subtitle'>Our website contains links that lead to other websites. If you click on these links [name] is not held responsible for your data and privacy protection. Visiting those websites is not governed by this privacy policy agreement. Make sure to read the privacy policy documentation of the website you go to from our website.</span>
                    </span>
                </div>
                <div className='section'>
                    <span className='title'>
                        Restricting the Collection of your Personal Data<br /><span className='subtitle'>At some point, you might wish to restrict the use and collection of your personal data. You can achieve this by doing the following:<br /><br />When you are filling the forms on the website, make sure to check if there is a box which you can leave unchecked, if you don't want to disclose your personal information.<br /><br />If you have already agreed to share your information with us, feel free to contact us via email and we will be more than happy to change this for you.<br /><br />[name] will not lease, sell or distribute your personal information to any third parties, unless we have your permission. We might do so if the law forces us. Your personal information will be used when we need to send you promotional materials if you agree to this privacy policy.</span>
                    </span>
                </div>
            </Modal>
            {getWindowDimensions().width > 450 ?
                <div className="panel">
                    <div className='art'>
                        <img src={PointsDetail1} className="points-details-1" />
                        <img src={Circle1} className="circle-1" />
                        <img src={Circle2} className="circle-2" />
                        <img src={Circle3} className="circle-3" />
                        <img src={Circle4} className="circle-4" />
                        <img src={PointsDetail2} className="points-details-2" />
                        <img src={Path} className="path" />
                    </div>
                    <div className='data'>
                        <span className='upper-title'>Professional Video Editing Services</span>
                        <img src={LogoWhite} />
                        <span className='lower-title'>We Help You Create Amazing Videos!</span>
                        <span className='description'>Get your custom quote in 5 minutes!</span>
                    </div>
                </div> :
                <div className='mobile-panel'>
                    <img src={LogoWhite} />
                </div>}
            <div className="auth-content">
                <Outlet context={[setIsTermsOfServiceOpen, setIsPrivacyPolicyOpen]} />
            </div>
        </div>
    )
}

export default AuthLayout