import { coreService } from "./core.service";

const buyPackage = (userPackage, card, callback) => {
  coreService.post(
    `package/buy`,
    { package: userPackage, card },
    callback,
    undefined,
    undefined,
    true
  );
};

const cancelPackage = (userPackage, callback) => {
  coreService.post(`package/cancel`, userPackage, callback);
};

const deleteCard = (cardId, callback) => {
  coreService.post(`package/delete-card`, { cardId }, callback);
};

const changeDefaultCard = (cardId, callback) => {
  coreService.post(`package/change-default`, { cardId }, callback);
};

const addCard = (card, callback) => {
  coreService.post(`package/add-card`, card, callback);
};

const changeNextPackage = (userPackage, callback) => {
  coreService.post(
    `package/change-next-package`,
    userPackage,
    callback,
    undefined,
    undefined,
    true
  );
};

const buyAddOn = (userPackage, callback) => {
  coreService.post("package/buy-add-ons", userPackage, callback);
};

export const purchaseService = {
  buyPackage,
  cancelPackage,
  deleteCard,
  addCard,
  changeDefaultCard,
  changeNextPackage,
  buyAddOn,
};
