import { useEffect, useState } from "react";
import "./Slider.scss";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const Slider = ({ images }) => {
  const [page, setPage] = useState(0);
  const [currentImage, setCurrentImage] = useState({});

  useEffect(() => {
    setCurrentImage(images[page]);
  }, [page]);

  const prevImage = () => {
    if (page === 0) {
      setPage(images.length - 1);
    } else {
      setPage((page) => page - 1);
    }
  };

  const nextImage = () => {
    if (page === images.length - 1) {
      setPage(0);
    } else {
      setPage((page) => page + 1);
    }
  };

  return (
    <div className="slider-component">
      <AiOutlineArrowLeft
        onClick={() => prevImage()}
        size={20}
        className="arrow"
      />
      <img src={currentImage.url} />
      <AiOutlineArrowRight
        onClick={() => nextImage()}
        size={20}
        className="arrow"
      />
    </div>
  );
};

export default Slider;
