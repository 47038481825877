import TableHeader from "../../table-header/TableHeader";
import TableRow from "../../table-row/TableRow";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./NewOrders.scss";
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiFillStar,
  AiOutlineMore,
  AiOutlinePrinter,
} from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { orderService } from "../../../services/order.service";
import { reference } from "../../ordersReference";
import { createHTMLOrderPrint } from "../../setItemsPrint";
import moment from "moment";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const NewOrders = ({
  orders,
  getOrders,
  ordersCount,
  user,
  allUsers,
  setOrders,
  setOrdersCount,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [acceptOrder, setAcceptOrder] = useState(false);
  const [acceptOrderId, setAcceptOrderId] = useState(false);
  const [choseEditorId, setChoseEditorId] = useState(0);
  const [usersDisplayed, setUsersDisplayed] = useState([]);
  const [traferring, setTransferring] = useState(false);
  const [editorPay, setEditorPay] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getOrders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    searchUsers("");
  }, [allUsers]);

  const searchUsers = (search) => {
    const arr = [...allUsers];
    setUsersDisplayed(
      arr.filter(
        (user) =>
          user.firstName.includes(search) || user.lastName.includes(search)
      )
    );
  };

  useEffect(() => {
    setCurrentOrders(orders);
  }, [orders]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const formatTime = (time) => {
    const newTime = new Date(time).getTime();
    const now = new Date().getTime();

    const minutes = (newTime - now) / 60000;

    if (Math.abs(minutes) > 1440) {
      return `${Math.floor(minutes / 1440)} ${
        Math.floor(Math.abs(minutes) / 1440) > 1 ? "days" : "day"
      } left`;
    } else {
      if (Math.abs(minutes) > 60) {
        return `${Math.floor(minutes / 60)} ${
          Math.floor(Math.abs(minutes) / 60) > 1 ? "hours" : "hour"
        } left`;
      } else {
        return `${minutes} ${
          Math.abs(minutes) > 1 ? "minutes" : "minute"
        } left`;
      }
    }
  };

  const formatCurrency = (amount) => {
    let newAmount = "";
    let arrCount = 0;
    let amountToCheck = `${parseFloat(amount).toFixed(0)}`;
    const arr = `${amountToCheck}`.split("").reverse();
    arr.map((letter) => {
      arrCount++;
      newAmount = `${letter}${newAmount}`;
      if (arrCount !== arr.length && arrCount % 3 === 0) {
        newAmount = `,${newAmount}`;
      }
    });

    let result = `$${newAmount}.00`;

    return result;
  };

  const downloadTxtFile = (order) => {
    orderService.getDetails(order.id, (order) => {
      const statusName = reference.filter(
        (item) => item.status === order.status
      )[0].name;
      const tableItems = createHTMLOrderPrint(order);

      const list = [
        `Order Number: #${order.number}`,
        "Order Name: " + order.name,
        `Created at: ${moment(order.createdAt).format("DD MMMM YYYY")}`,
        `Deadline at: ${moment(order.deadline).format("DD MMMM YYYY")}`,
        "Status: " + statusName + "\n",
        "Delivery Items:",
        tableItems.map((tableItem, index) => {
          if (tableItem.shown) {
            return `\n\n${tableItem.name}:${tableItem.item.map((item) => {
              return `\n- ${item}`;
            })}`;
          } else {
            return "\n";
          }
        }),
      ];
      const element = document.createElement("a");
      const file = new Blob([list.join("\n")], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `invoice_${order.number}.txt`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    });
  };

  const updateOrders = (page) => {
    const filters = { admin: true };
    filters.type = "new";

    orderService.listOrders(page, filters, (data) => {
      setOrders(data.orders);
      setOrdersCount(data.count);
    });
  };

  return (
    <div className="new-orders-component">
      {acceptOrder ? (
        <div className="editors-modal">
          <div className="editors-modal-component">
            <span className="title">Choose your editor</span>
            <input
              onChange={(e) => {
                searchUsers(e.target.value);
              }}
              placeholder="Editor's Name"
            />
            <input
              onChange={(e) => {
                setEditorPay(e.target.value);
              }}
              type="number"
              placeholder="Editor's Pay"
            />
            <div className="editors">
              {usersDisplayed.map((user) => {
                return (
                  <div
                    className={`editor ${
                      user.id === choseEditorId ? "active" : ""
                    }`}
                    onClick={() => setChoseEditorId(user.id)}
                  >
                    <div className="image-container">
                      <img
                        src={
                          user.profilePicture
                            ? user.profilePicture.url
                            : "https://mark-studios.s3.amazonaws.com/g8p4k5pI0ot5JFS6Zt2Snd1zhzYnASQo_no-picture.png"
                        }
                      />
                    </div>
                    <span>
                      {user.firstName} {user.lastName}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="actions">
              <button
                className="cancel"
                onClick={() => {
                  setAcceptOrder(false);
                  setAcceptOrderId(0);
                  setChoseEditorId(0);
                  setEditorPay(0);
                  setTransferring(false);
                }}
              >
                Cancel
              </button>
              <button
                className="create"
                onClick={() => {
                  if (
                    choseEditorId &&
                    choseEditorId !== null &&
                    choseEditorId !== 0 &&
                    editorPay > 0
                  ) {
                    !traferring
                      ? orderService.changeAcceptance(
                          choseEditorId,
                          true,
                          acceptOrderId,
                          editorPay,
                          (data) => {
                            Swal.fire({
                              title: "Order accepted successfully",
                              icon: "success",
                            }).then(() => {
                              updateOrders(1);
                              setAcceptOrder(false);
                              setAcceptOrderId(0);
                              setChoseEditorId(0);
                              setEditorPay(0);
                            });
                          }
                        )
                      : orderService.transferOrder(
                          choseEditorId,
                          acceptOrderId,
                          (data) => {
                            Swal.fire({
                              title: "Order transfered successfully",
                              icon: "success",
                            }).then(() => {
                              updateOrders(1);
                              setAcceptOrder(false);
                              setAcceptOrderId(0);
                              setChoseEditorId(0);
                              setTransferring(false);
                            });
                          }
                        );
                  } else {
                    Swal.fire({
                      title: "Error!",
                      text: "Please, choose an editor and add their payment",
                      icon: "error",
                    });
                  }
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {getWindowDimensions().width > 450 ? (
        <>
          <TableHeader
            titles={[
              "Name",
              "Order Number",
              "Estimate Time",
              "Status",
              "Order Cost",
              "",
            ]}
          />
          <div className="order-table">
            <TableRow
              items={currentOrders.map((order, index) => {
                return [
                  <span
                    style={{ fontWeight: 500 }}
                    onClick={() => navigate(`./${order.id}`)}
                  >
                    {order.basicDetails
                      ? order.basicDetails.title
                      : `Order #${order.number}`}
                  </span>,
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <button
                      onClick={() => {
                        setAcceptOrderId(order.id);
                        setAcceptOrder(true);
                      }}
                      style={{
                        cursor: "pointer",
                        padding: ".7em 1.5em",
                        fontSize: ".8em",
                        color: "white",
                        fontWeight: "500",
                        border: 0,
                        borderRadius: "20px",
                        backgroundColor: "#2A9DF4",
                      }}
                    >
                      Accept
                    </button>
                    <button
                      onClick={() =>
                        Swal.fire({
                          title: "Warning!",
                          text: "Are you sure you want to deny this order?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Confirm",
                          cancelButtonText: "Cancel",
                        }).then((response) => {
                          if (response.isConfirm) {
                            orderService.changeAcceptance(
                              null,
                              false,
                              order.id,
                              (data) => {
                                Swal.fire({
                                  title: "Order declined successfully",
                                  icon: "success",
                                }).then(() => {
                                  updateOrders(1);
                                });
                              }
                            );
                          }
                        })
                      }
                      style={{
                        cursor: "pointer",
                        padding: ".7em 1.5em",
                        fontSize: ".8em",
                        color: "white",
                        fontWeight: "500",
                        border: 0,
                        borderRadius: "20px",
                        backgroundColor: "#E74C3C",
                      }}
                    >
                      Deny
                    </button>
                  </div>,
                  // <div style={{ backgroundColor: '#D8FFEE', color: '#00D097', width: '30px', height: '30px', alignContent: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex', fontSize: '.8em', borderRadius: '7px', cursor: 'pointer' }}>
                  //     {order.offers}
                  // </div>,
                  <span>{formatTime(order.deadline)}</span>,
                  <div className="status">
                    <span>New</span>
                  </div>,
                  <span>{formatCurrency(order.editorPay)}</span>,
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      position: "relative",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      onClick={() => {
                        const obj = currentOrders[index];
                        obj.open = !obj.open;
                        setCurrentOrders([
                          ...currentOrders.slice(0, index),
                          obj,
                          ...currentOrders.slice(index + 1),
                        ]);
                      }}
                      style={{
                        position: "absolute",
                        backgroundColor: "#F2F5FA",
                        width: "30px",
                        height: "30px",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "1.2em",
                        borderRadius: "7px",
                        cursor: "pointer",
                      }}
                    >
                      <AiOutlineMore />
                    </div>
                    {order.open && (
                      <div
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "white",
                          position: "absolute",
                          top: 40,
                          right: 0,
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "space-between",
                          boxShadow: "0 6px 40px 0 #7991AD4D",
                          padding: ".3em",
                        }}
                      >
                        <div
                          onClick={() => navigate(`./${order.id}`)}
                          style={{
                            fontWeight: "600",
                            padding: "0 .5em",
                            marginRight: ".5em",
                            backgroundColor: "#F4F9FD",
                            height: "30px",
                            flexWrap: "nowrap",
                            whiteSpace: "nowrap",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: ".8em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          Show More
                        </div>
                        <div
                          onClick={() => downloadTxtFile(order)}
                          style={{
                            backgroundColor: "#F4F9FD",
                            width: "30px",
                            height: "30px",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: "1.2em",
                            borderRadius: "7px",
                            cursor: "pointer",
                          }}
                        >
                          <AiOutlinePrinter />
                        </div>
                        {user.type === "admin" ? (
                          <div
                            onClick={() => {
                              orderService.cancelOrder(order.id, (data) => {
                                updateOrders(1);
                              });
                            }}
                            style={{
                              marginLeft: ".5em",
                              backgroundColor: "#F6516023",
                              width: "30px",
                              height: "30px",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "1.2em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            <FiTrash2 color="#E74C3C" />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>,
                ];
              })}
            />
          </div>
        </>
      ) : (
        <>
          <>
            {currentOrders.map((order, index) => {
              return (
                <div className="order-mobile">
                  <div className="header-mobile">
                    <div className="status">
                      <span>New</span>
                    </div>
                    <div className="details">
                      <span
                        className="name"
                        onClick={() => navigate(`./${order.id}`)}
                      >
                        {order.basicDetails
                          ? order.basicDetails.title
                          : `Order #${order.number}`}
                      </span>
                      <span className="number">#{order.number}</span>
                    </div>
                  </div>
                  <div className="body-mobile">
                    <span>${order.editorPay}</span>
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        position: "relative",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        onClick={() => {
                          const obj = currentOrders[index];
                          obj.open = !obj.open;
                          setCurrentOrders([
                            ...currentOrders.slice(0, index),
                            obj,
                            ...currentOrders.slice(index + 1),
                          ]);
                        }}
                        style={{
                          position: "absolute",
                          backgroundColor: "#F2F5FA",
                          width: "30px",
                          height: "30px",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "1.2em",
                          borderRadius: "7px",
                          cursor: "pointer",
                        }}
                      >
                        <AiOutlineMore />
                      </div>
                      {order.open && (
                        <div
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "white",
                            position: "absolute",
                            top: 40,
                            right: 0,
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "space-between",
                            boxShadow: "0 6px 40px 0 #7991AD4D",
                            padding: ".3em",
                          }}
                        >
                          <div
                            onClick={() => navigate(`./${order.id}`)}
                            style={{
                              fontWeight: "600",
                              padding: "0 .5em",
                              marginRight: ".5em",
                              backgroundColor: "#F4F9FD",
                              height: "30px",
                              flexWrap: "nowrap",
                              whiteSpace: "nowrap",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: ".8em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            Show More
                          </div>
                          <div
                            onClick={() => downloadTxtFile(order)}
                            style={{
                              backgroundColor: "#F4F9FD",
                              width: "30px",
                              height: "30px",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "1.2em",
                              borderRadius: "7px",
                              cursor: "pointer",
                            }}
                          >
                            <AiOutlinePrinter />
                          </div>
                          {user.type === "admin" ? (
                            <div
                              onClick={() => {
                                orderService.cancelOrder(order.id, (data) => {
                                  updateOrders(1);
                                });
                              }}
                              style={{
                                marginLeft: ".5em",
                                backgroundColor: "#F6516023",
                                width: "30px",
                                height: "30px",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                fontSize: "1.2em",
                                borderRadius: "7px",
                                cursor: "pointer",
                              }}
                            >
                              <FiTrash2 color="#E74C3C" />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </>
      )}
      <div className="footer">
        <div className="pages">
          <span>
            {(currentPage - 1) * 6 + 1} -{" "}
            {ordersCount > currentPage * 6 ? currentPage * 6 : ordersCount} of{" "}
            {ordersCount}
          </span>
        </div>
        <div className="controllers">
          <span
            className={currentPage === 1 ? "disable" : "active"}
            onClick={previousPage}
          >
            <AiOutlineArrowLeft />
          </span>
          <span
            className={ordersCount < currentPage * 6 ? "disable" : "active"}
            onClick={nextPage}
          >
            <AiOutlineArrowRight />
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewOrders;
