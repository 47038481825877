import { orderSteps } from "../../newOrderSteps";
import { IoIosAddCircle, IoMdAddCircle } from "react-icons/io";
import { FaRegTrashAlt } from "react-icons/fa";
import "./Quote.scss";
import { useEffect, useState } from "react";
import { orderService } from "../../../../../../../services/order.service";
import { createHTMLOrder } from "../../../order/setItems";
import { AiFillMinusCircle } from "react-icons/ai";

const Quote = ({
  itemsAdded,
  changeComponent,
  validate,
  nextStep,
  prevStep,
  step,
  user,
  order,
}) => {
  const [steps, setSteps] = useState([]);
  const [tableItems, setTableItems] = useState();

  useEffect(() => {
    setTableItems(createHTMLOrder(order));
  }, [order]);

  useEffect(() => {
    setSteps(
      orderSteps(itemsAdded).map((item) => {
        item.open = false;
        return item;
      })
    );
  }, []);
  const changeStep = (isStepNext) => {
    if (isStepNext) {
      nextStep();
    } else {
      prevStep();
    }
  };

  return tableItems && tableItems.length ? (
    <div className="quote-new-order">
      <div className="overview">
        {/* {steps.map((step) => {
          return (
            <div className="overview-item">
              <span>{step.name}</span>
              <div className="actions">
                <IoIosAddCircle color="#2A9DF4" className="add" size={25} />
                <FaRegTrashAlt size={20} />
              </div>
            </div>
          );
        })} */}
        {tableItems.map((tableItem, index) => {
          if (tableItem.shown) {
            return (
              <div className="sub-instruction">
                <div className="header">
                  <span className="main-title">{tableItem.name}</span>
                  {tableItem.open ? (
                    <AiFillMinusCircle
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const obj = tableItems[index];
                        obj.open = !obj.open;
                        setTableItems([
                          ...tableItems.slice(0, index),
                          obj,
                          ...tableItems.slice(index + 1),
                        ]);
                      }}
                      size={20}
                      color="#2A9DF4"
                    />
                  ) : (
                    <IoMdAddCircle
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const obj = tableItems[index];
                        obj.open = !obj.open;
                        setTableItems([
                          ...tableItems.slice(0, index),
                          obj,
                          ...tableItems.slice(index + 1),
                        ]);
                      }}
                      size={20}
                      color="#2A9DF4"
                    />
                  )}
                </div>
                {tableItem.open && tableItem.item}
              </div>
            );
          }
        })}
      </div>
      {order.basicDetails && order.basicDetails.deliveryTime === 1 ? (
        <span>
          You requested a 24-hour delivery time. By clicking place order your
          default card will be charged.
        </span>
      ) : null}
      <div className="footer">
        {step > 0 ? (
          <button
            className="prev"
            onClick={() => {
              changeStep(false);
            }}
          >
            Prev Step
          </button>
        ) : null}
        <button
          className="next"
          onClick={() => {
            changeStep(true);
          }}
        >
          Place Order
        </button>
      </div>
    </div>
  ) : null;
};

export default Quote;
