import { Route, Routes } from "react-router-dom"
import routesList from "./routesList"

const Router = () => {
    return (
        <Routes>
            {routesList.map((route) => {
                return (
                    <Route path={route.path} element={route.element} key={route.key}>
                        {route.subRoutes.map((subRoute) => {
                            if (subRoute.subRoutes) {
                                return (
                                    <Route element={subRoute.element} path={subRoute.path} key={subRoute.key}>
                                        {subRoute.subRoutes.map((subSubRoute) => {
                                            return (
                                                <Route element={subSubRoute.element} path={subSubRoute.path} key={subSubRoute.key} />
                                            )
                                        })}
                                    </Route>
                                )
                            } else {
                                return (
                                    <Route element={subRoute.element} path={subRoute.path} key={subRoute.key} />
                                )
                            }
                        })}
                    </Route>
                )
            })}
        </Routes>
    )
}

export default Router