import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { authService } from "../../../../services/auth.service";
import "./ChooseWay.scss";

const GeneralAuthChooseWay = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  useEffect(() => {
    authService.getUserByUuid(params.uuid, (data) => {
      setUser(data);
    });
  }, [params]);

  useEffect(() => {
    if (user.type && user.type !== null) {
      navigate("../finish-sign-up", { state: { user } });
    }
  }, [user]);

  // const chooseOption = (type) => {
  //   setUser((user) => {
  //     return { ...user, type };
  //   });
  // };

  const [setIsTermsOfServiceOpen, setIsPrivacyPolicyOpen] = useOutletContext();
  return (
    <div className="auth-general-six-digits">
      <div className="login-box">
        {/* <span className="title">Chose how you want to register</span>
        <button className="login" onClick={() => chooseOption("customer")}>
          Customer
        </button>
        <button className="editor" onClick={() => chooseOption("editor")}>
          Editor
        </button> */}
      </div>
      <div className="footer">
        <span className="already-have">
          Already have an account? <b>Sign in</b>
        </span>
        <div className="terms-privacy-policy">
          <span onClick={() => setIsTermsOfServiceOpen(true)}>
            Terms of use
          </span>
          <span onClick={() => setIsPrivacyPolicyOpen(true)}>
            Privacy Policy
          </span>
          <span>Support</span>
        </div>
      </div>
    </div>
  );
};

export default GeneralAuthChooseWay;
