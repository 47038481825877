import { RiAttachment2 } from "react-icons/ri";

const download = (url) => {
  window.open(url, "_blank").focus();
};

export const createHTMLOrder = (order) => {
  return [
    {
      name: "Files Required",
      shown: order.fileRequired && order.fileRequired !== null,
      item: order.fileRequired ? (
        <div className="item">
          <div className="files">
            <span className="title">Files</span>
            {order.fileRequired ? (
              order.fileRequired.files &&
              order.fileRequired.files !== null &&
              order.fileRequired.files.length ? (
                order.fileRequired.files.map((file) => {
                  return (
                    <div className="file">
                      <div
                        onClick={() => download(file.url)}
                        className="file-content"
                      >
                        <RiAttachment2 />
                        <span>{file.originalName}</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-field">
                  <span>
                    <b>Drive Link: </b>
                    {order.fileRequired.link}
                  </span>
                </div>
              )
            ) : null}
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Basic Details",
      shown: order.basicDetails && order.basicDetails !== null,
      item: order.basicDetails ? (
        <div className="item">
          <div className="text-field">
            <span>
              <b>Title: </b>
              {order.basicDetails
                ? order.basicDetails.title
                : `Order #${order.number}`}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Delivery Day: </b>
              {order.basicDetails.deliveryTime}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Video Type: </b>
              {order.basicDetails.type}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Where it will be posted: </b>
              {order.basicDetails.postLocation}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Names of people: </b>
              {order.basicDetails.names}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Video Elements: </b>
              {order.basicDetails.videoElements}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Video Elements",
      shown: order.videoElements && order.videoElements !== null,
      item: order.videoElements ? (
        <div className="item">
          <div className="text-field">
            <span>
              <b>Text: </b>
              {order.videoElements.text}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Jump Cuts: </b>
              {order.videoElements.jumpCuts}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Low Third: </b>
              {order.videoElements.lowThird}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Transition: </b>
              {order.videoElements.transition}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Thumbnail",
      shown: order.thumbnail && order.thumbnail !== null,
      item: order.thumbnail ? (
        <div className="item">
          <div className="text-field">
            <span>
              <b>Where it will be posted: </b>
              {order.thumbnail.location}
            </span>
          </div>
          {order.thumbnail.logo ? (
            <div className="file single">
              <span className="title">Logo</span>
              <div
                onClick={() => download(order.thumbnail.logo.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span>{order.thumbnail.logo.originalName}</span>
              </div>
            </div>
          ) : null}
          <div className="text-field">
            <span>
              <b>Required Information: </b>
              {order.thumbnail.infoRequired}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>What to avoid: </b>
              {order.thumbnail.avoidDescription}
            </span>
          </div>
          {order.thumbnail.additionalFiles &&
          order.thumbnail.additionalFiles.length ? (
            <div className="files">
              <span className="title">Additional Files</span>
              {order.thumbnail.additionalFiles.map((file) => {
                return (
                  <div className="file">
                    <div
                      onClick={() => download(file.url)}
                      className="file-content"
                    >
                      <RiAttachment2 />
                      <span>{file.originalName}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Music",
      shown: order.music && order.music !== null,
      item: order.music ? (
        <div className="item">
          {order.music.music ? (
            <div className="file single">
              <span className="title">Music File</span>
              <div
                onClick={() => download(order.music.music.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span>{order.music.music.originalName}</span>
              </div>
            </div>
          ) : null}
          <div className="text-field">
            <span>
              <b>Let the studio decides: </b>
              {order.music.studioDecides ? "Yes" : "No"}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Comments: </b>
              {order.music.comments}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Personalized Intro",
      shown: order.intro && order.intro !== null,
      item: order.intro ? (
        <div className="item">
          {order.intro.intro ? (
            <div className="file single">
              <span className="title">Intro File</span>
              <div
                onClick={() => download(order.intro.intro.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span>{order.intro.intro.originalName}</span>
              </div>
            </div>
          ) : null}
          {order.intro.logo ? (
            <div className="file single">
              <span className="title">Logo File</span>
              <div
                onClick={() => download(order.intro.logo.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span>{order.intro.logo.originalName}</span>
              </div>
            </div>
          ) : null}
          {order.intro.music ? (
            <div className="file single">
              <span className="title">Music File</span>
              <div
                onClick={() => download(order.intro.music.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span>{order.intro.music.originalName}</span>
              </div>
            </div>
          ) : null}
          <div className="text-field">
            <span>
              <b>Let the studio decides the music: </b>
              {order.intro.studioDecides ? "Yes" : "No"}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Comments: </b>
              {order.intro.comments}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Personalized Outro",
      shown: order.outro && order.outro !== null,
      item: order.outro ? (
        <div className="item">
          {order.outro.outro ? (
            <div className="file single">
              <span className="title">Outro File</span>
              <div
                onClick={() => download(order.outro.outro.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span>{order.outro.outro.originalName}</span>
              </div>
            </div>
          ) : null}
          {order.outro.logo ? (
            <div className="file single">
              <span className="title">Logo File</span>
              <div
                onClick={() => download(order.outro.logo.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span>{order.outro.logo.originalName}</span>
              </div>
            </div>
          ) : null}
          {order.outro.music ? (
            <div className="file single">
              <span className="title">Music File</span>
              <div
                onClick={() => download(order.outro.music.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span>{order.outro.music.originalName}</span>
              </div>
            </div>
          ) : null}
          <div className="text-field">
            <span>
              <b>Let the studio decides the music: </b>
              {order.outro.studioDecides ? "Yes" : "No"}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Comments: </b>
              {order.outro.comments}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Consultation Call",
      shown: order.consultationCall && order.consultationCall !== null,
      item: order.consultationCall ? (
        <div className="item">
          <div className="text-field">
            <span>
              <b>Discussion Topic: </b>
              {order.consultationCall.discussionTopic}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Concerns: </b>
              {order.consultationCall.concerns}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Goal: </b>
              {order.consultationCall.goal}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Logo",
      shown: order.logo && order.logo !== null,
      item: order.logo ? (
        <div className="item">
          {order.logo.colors.map((color, index) => {
            return (
              <div className="text-field">
                <span>
                  <b>Color {index + 1}: </b>
                  {color.color}
                </span>
              </div>
            );
          })}
          <div className="text-field">
            <span>
              <b>Text on logo: </b>
              {order.logo.text}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Slogan: </b>
              {order.logo.slogan}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Description: </b>
              {order.logo.description}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>Industry: </b>
              {order.logo.industry}
            </span>
          </div>
          {order.logo.additionalFiles && order.logo.additionalFiles.length ? (
            <div className="files">
              <span className="title">Additional Files</span>
              {order.logo.additionalFiles.map((file) => {
                return (
                  <div className="file">
                    <div
                      onClick={() => download(file.url)}
                      className="file-content"
                    >
                      <RiAttachment2 />
                      <span>{file.originalName}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null,
      open: false,
    },
    {
      name: "Banner",
      shown: order.banner && order.banner !== null,
      item: order.banner ? (
        <div className="item">
          <div className="text-field">
            <span>
              <b>Where it will be posted: </b>
              {order.banner.location}
            </span>
          </div>
          {order.banner.logo ? (
            <div className="file single">
              <span className="title">Logo</span>
              <div
                onClick={() => download(order.banner.logo.url)}
                className="file-content"
              >
                <RiAttachment2 />
                <span>{order.banner.logo.originalName}</span>
              </div>
            </div>
          ) : null}
          <div className="text-field">
            <span>
              <b>Required Information: </b>
              {order.banner.infoRequired}
            </span>
          </div>
          <div className="text-field">
            <span>
              <b>What to avoid: </b>
              {order.banner.avoidDescription}
            </span>
          </div>
          {order.banner.additionalFiles &&
          order.banner.additionalFiles.length ? (
            <div className="files">
              <span className="title">Additional Files</span>
              {order.banner.additionalFiles.map((file) => {
                return (
                  <div className="file">
                    <div
                      onClick={() => download(file.url)}
                      className="file-content"
                    >
                      <RiAttachment2 />
                      <span>{file.originalName}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : null,
      open: false,
    },
    // {
    //   name: "Stock Footage",
    //   item: (
    //     <div className="item">
    //       {order.stockFootages.map((stockFootage) => {
    //         return (
    //           <div className="footage">
    //             <img src={stockFootage.image.url} />
    //             <div className="comments">
    //               {stockFootage.comments.map((comment) => {
    //                 return <span>{comment.comment}</span>;
    //               })}
    //             </div>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   ),
    //   open: false,
    // },
    {
      name: "Additional Instructions",
      shown:
        order.additionalInstructions && order.additionalInstructions !== null,
      item: order.additionalInstructions ? (
        <div className="item">
          <div className="text-field">
            <span>
              <b>Additional Instructions: </b>
              {order.additionalInstructions.somethingElse}
            </span>
          </div>
        </div>
      ) : null,
      open: false,
    },
    // {
    //   name: "Add-Ons",
    //   item: (
    //     <div className="item">
    //       <div className="text-field">
    //         <span>
    //           <b>Banner: </b>
    //           {order.addOn.banner ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Unlimited Stock Footage: </b>
    //           {order.addOn.unlimitedStockFootage ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Logo: </b>
    //           {order.addOn.logo ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Outro: </b>
    //           {order.addOn.outro ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Thumbnail: </b>
    //           {order.addOn.thumbnail ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Consultation Call: </b>
    //           {order.addOn.consultationCall ? "Yes" : "No"}
    //         </span>
    //       </div>
    //       <div className="text-field">
    //         <span>
    //           <b>Intro: </b>
    //           {order.addOn.intro ? "Yes" : "No"}
    //         </span>
    //       </div>
    //     </div>
    //   ),
    //   open: false,
    // },
  ];
};
