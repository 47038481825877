import { useEffect, useState } from "react";
import "./Support.scss";
import { MdOutlineArrowForwardIos, MdKeyboardArrowDown } from "react-icons/md";
import Modal from "react-modal";
import { useNavigate, useOutletContext } from "react-router";
import SupportAdmin from "../../../../assets/support-admin.png";
import { AiOutlineClose } from "react-icons/ai";
import { supportService } from "../../../../services/support.service";
import { orderService } from "../../../../services/order.service";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.getElementById("root"));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const EditorSupport = () => {
  const [askAdmin, setAskAdmin] = useOutletContext();
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
  const [isQuestionShown, setIsQuestionShown] = useState(false);
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = () => {
    supportService.getAllQuestions((data) => {
      data = data.map((item) => {
        item.open = false;
        return item;
      });
      setQuestions(data);
    });
  };

  function closeModal() {
    setAskAdmin(false);
  }

  useEffect(() => {
    if (questions && questions.length) {
      setCurrentQuestion(questions[0].explanation);
    }
  }, [questions]);

  return (
    <div className="editor-layout-support-page">
      <Modal
        isOpen={askAdmin}
        onRequestClose={closeModal}
        contentLabel="Admin Support Modal"
        className={getWindowDimensions().width < 450 ? "modal mobile" : "modal"}
        overlayClassName={
          getWindowDimensions().width < 450
            ? "overlay-modal mobile"
            : "overlay-modal"
        }
      >
        <h4>Need some help?</h4>
        <img src={SupportAdmin} alt="support" />
        <span className="subtitle">
          Describe your question and our specialists will answer you within 24
          hours
        </span>
        <div className="textInput">
          <span className="title-of-input">Request Subject</span>
          <div className="input-field">
            <span>Technical Difficulties</span>
            <span>
              <MdKeyboardArrowDown />
            </span>
          </div>
        </div>
        <div className="textInput">
          <span className="title-of-input">Description</span>
          <div className="input-field">
            <span style={{ paddingBottom: "3em" }}>
              Add some description of the request
            </span>
          </div>
        </div>
        <button
          onClick={() => setAskAdmin(false)}
          style={{
            width: "fit-content",
            alignSelf: "flex-end",
            marginTop: "1.5em",
            cursor: "pointer",
            color: "white",
            backgroundColor: "#2A9DF4",
            padding: "1em 2em",
            fontWeight: "500",
            border: 0,
            borderRadius: "25px",
          }}
        >
          Submit
        </button>
      </Modal>
      <div className="questions">
        {getWindowDimensions().width < 450 && (
          <button
            onClick={() => {
              orderService.chatWithAdmin("admin", null, (data) => {
                navigate("../inbox", { state: { chat: data } });
              });
            }}
            style={{
              border: 0,
              marginBottom: "1em",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              padding: ".5em 1em",
              color: "white",
              backgroundColor: "#2A9DF4",
              borderRadius: "20px",
            }}
          >
            Ask to Admin
          </button>
        )}
        {questions.map((question, index) => {
          return (
            <div
              className={`question ${
                currentQuestionNumber === index + 1 &&
                getWindowDimensions().width > 450
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                setCurrentQuestion(question.explanation);
                setCurrentQuestionNumber(index + 1);
                if (getWindowDimensions().width < 450) {
                  setIsQuestionShown(true);
                }
              }}
            >
              <span>
                {index + 1} - {question.title}
              </span>
              <span>
                <MdOutlineArrowForwardIos />
              </span>
            </div>
          );
        })}
      </div>
      {getWindowDimensions().width > 450 && (
        <div className="explanation">{currentQuestion}</div>
      )}
      {getWindowDimensions().width < 450 && isQuestionShown && (
        <div className="explanation-mobile">
          <div className="explanation-container">
            <div className="explanation-header">
              <span className="title">Question {currentQuestionNumber}</span>
              <div className="icon" onClick={() => setIsQuestionShown(false)}>
                <AiOutlineClose />
              </div>
            </div>
            <div className="explanation-body">{currentQuestion}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditorSupport;
