import { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import Swal from "sweetalert2";
import EarningComponents from "../../../../components/earnings/EarningsComponent";
import { orderService } from "../../../../services/order.service";
import { userService } from "../../../../services/user.service";
import "./Earnings.scss";

const EditorEarnings = () => {
  const [currentPage, setCurrentPage] = useState("This Year");
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);
  /*eslint no-unused-vars: "off"*/
  const [askAdmin, setAskAdmin, user] = useOutletContext();
  const [displayedUser, setDisplayedUser] = useState({});

  useEffect(() => {
    setDisplayedUser(user);
  }, [user]);

  /*eslint react-hooks/exhaustive-deps: "off"*/
  useEffect(() => {
    getOrders(1);
  }, [currentPage]);

  const getOrders = (page) => {
    let time = "year";

    if (currentPage === "This Month") {
      time = "month";
    } else if (currentPage === "This Week") {
      time = "week";
    }

    orderService.listOrdersTime(page, time, (data) => {
      setOrders(data.orders);
      setOrdersCount(data.count);
      setTotalProgress(data.totalProgress);
    });
  };

  const formatCurrency = (amount) => {
    let newAmount = "";
    let arrCount = 0;
    let amountToCheck = `${parseFloat(amount).toFixed(0)}`;
    const arr = `${amountToCheck}`.split("").reverse();
    for (const letter of arr) {
      arrCount++;
      newAmount = `${letter}${newAmount}`;
      if (arrCount !== arr.length && arrCount % 3 === 0) {
        newAmount = `,${newAmount}`;
      }
    }

    let result = `R$${newAmount}.00`;

    return result;
  };

  return (
    <div className="editor-layout-earnings-page">
      <div className="header">
        <div className="overall">
          <div className="amount">
            <span>Available for Withdrawal</span>
            <span className="dollars">
              {formatCurrency(displayedUser.balance)}
            </span>
          </div>
          <div className="amount">
            <span>Orders in Progress</span>
            <span className="dollars">{formatCurrency(totalProgress)}</span>
          </div>
          <div className="actions">
            <span>Choose withdrawal method</span>
            <div className="buttons">
              <button
                className="paypal"
                onClick={() => {
                  if (displayedUser.balance > 100) {
                    userService.withdraw((data) => {
                      setDisplayedUser(data);
                      Swal.fire({
                        title: "Withdraw initiated!",
                        text: "Your withdraw started, please wait at most 2 business days to receive your money on your Paypal account",
                        icon: "success",
                      });
                    });
                  } else {
                    Swal.fire({
                      title: "Error!",
                      text: "You need at least R$100 to withdraw",
                      icon: "error",
                    });
                  }
                }}
              >
                Paypal
              </button>
              {/* <button className="wire">Book Wire</button> */}
            </div>
          </div>
        </div>
        <div className="types">
          <div
            onClick={() => setCurrentPage("This Year")}
            className={`${currentPage === "This Year" ? "active" : ""}`}
          >
            This Year
          </div>
          <div
            onClick={() => setCurrentPage("This Month")}
            className={`${currentPage === "This Month" ? "active" : ""}`}
          >
            This Month
          </div>
          <div
            onClick={() => setCurrentPage("This Week")}
            className={`${currentPage === "This Week" ? "active" : ""}`}
          >
            This Week
          </div>
        </div>
      </div>
      <EarningComponents
        ordersCount={ordersCount}
        getOrders={getOrders}
        orders={orders}
      />
    </div>
  );
};

export default EditorEarnings;
