import "./AddOns.scss";
import Video from "../../../../../assets/video-types/video.png";
import Thumbnail from "../../../../../assets/video-types/thumbnail.png";
import Banner from "../../../../../assets/video-types/banner.png";
import Logo from "../../../../../assets/video-types/logo.png";
import ConsultationCall from "../../../../../assets/video-types/consultation-call.png";
import Outro from "../../../../../assets/video-types/outro.png";
import Intro from "../../../../../assets/video-types/intro.png";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Loading from "../../../../loading/Loading";
import BgBlurred from "../../../../../assets/Bg-blurred.png";
import { PackageManagementService } from "../../../../../services/package-management.service";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";
import LogoLanding from "../../../../../assets/landing/logo-landing.png";

const CustomerTestAddOns = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addOns, setAddOns] = useState([]);
  const [frequency, setFrequency] = useState("month");
  const { state } = useLocation();
  const [addOnsPrice, setAddOnsPrice] = useState(0);

  useEffect(() => {
    const getPackagesAndAddOns = async () => {
      PackageManagementService.list((data) => {
        data.addOns = data.addOns.map((addOn) => {
          addOn.count = 0;
          return addOn;
        });

        setAddOns(data.addOns);
      });
    };

    getPackagesAndAddOns();
  }, [state]);

  useEffect(() => {
    let currPrice = addOnsPrice;

    for (const addOn in addOns) {
      if (addOn.count) {
        currPrice = currPrice + addOn.cost * addOn.count;
      }
    }

    setAddOnsPrice(currPrice);
  }, [addOns, addOnsPrice]);

  const finishOrder = () => {
    for (const addOn of [...addOns]) {
      state.order[
        `additional${addOn.codeId
          .substring(0, 1)
          .toUpperCase()}${addOn.codeId.substring(1, addOn.codeId.length)}`
      ] = addOn.count;
    }
    state.order.additionalPrice = addOnsPrice * 100;
    state.order.type = frequency;

    if (frequency !== "month") {
      state.order.price = state.order.price * 10;
    }

    navigate("../order-summary", { state: state });
  };

  const changeCount = (operation, type) => {
    const newAddOns = [...addOns].map((addOn) => {
      if (addOn.type === type && operation === "plus") {
        addOn.count++;
        setAddOnsPrice((curr) => curr + addOn.cost);
      } else if (
        addOn.type === type &&
        operation === "minus" &&
        addOn.count > 0
      ) {
        addOn.count--;
        setAddOnsPrice((curr) => curr - addOn.cost);
      }

      return addOn;
    });

    setAddOns(newAddOns);
  };

  return addOns && addOns.length ? (
    <div className="customer-test-layout-add-ons-page">
      {loading ? <LoadingUpload text="Finishing Purchase" /> : null}
      <div className="page-content">
        <img className="blurred-bg" src={BgBlurred} alt="blurred-background" />
        <div className="login-header">
          <img src={LogoLanding} alt="logo" />
          <button
            className="login-button"
            onClick={() => navigate("/auth/sign-in")}
          >
            Login
          </button>
        </div>
        <div className="package-main-content">
          <div className="wrapper">
            <div className="middle-blocks">
              <div className="customer-layout-add-ons-page-block add-ons-header">
                <div className="title">
                  <span>
                    <span className="number">1</span> {state.order.name} Package
                  </span>
                  <button
                    onClick={() =>
                      navigate("/customer-test/packages/select-package")
                    }
                  >
                    Change Package
                  </button>
                </div>
                <div className="buttons">
                  <button
                    onClick={() => setFrequency("month")}
                    className={
                      frequency === "month" ? "frequency-selected" : ""
                    }
                  >
                    <span>Pay Monthly</span>
                  </button>
                  <button
                    onClick={() => setFrequency("year")}
                    className={frequency === "year" ? "frequency-selected" : ""}
                  >
                    <span>Pay Annually</span>
                    <span className="promotion">
                      12 months for the price of 10
                    </span>
                  </button>
                </div>
              </div>
              <div className="customer-layout-add-ons-page-block add-ons-content">
                <div className="title">
                  <span>
                    <span className="number">2</span> Add Add-Ons
                  </span>
                  <span className="subtitle">
                    One time purchases billed monthly along with your current
                    Package.
                  </span>
                </div>
                <div className="add-ons">
                  {addOns
                    .filter(
                      (addOn) =>
                        addOn.type !== "Raw Footage Time" &&
                        addOn.type !== "Final Running Time" &&
                        addOn.type !== "Video"
                    )
                    .map((addOn) => {
                      return (
                        <div className="add-on">
                          <div className="left">
                            <div className="image-container">
                              {addOn.type === "Video" ? (
                                <img src={Video} alt="add-on-symbol" />
                              ) : addOn.type === "Thumbnail" ? (
                                <img src={Thumbnail} alt="add-on-symbol" />
                              ) : addOn.type === "Logo" ? (
                                <img src={Logo} alt="add-on-symbol" />
                              ) : addOn.type === "Banner Design" ? (
                                <img src={Banner} alt="add-on-symbol" />
                              ) : addOn.type === "Consultation Call" ? (
                                <img
                                  src={ConsultationCall}
                                  alt="add-on-symbol"
                                />
                              ) : addOn.type === "Outro" ? (
                                <img src={Outro} alt="add-on-symbol" />
                              ) : (
                                <img src={Intro} alt="add-on-symbol" />
                              )}
                            </div>
                            <div className="details">
                              <span className="name">{addOn.type}</span>
                              <span className="price">${addOn.cost}</span>
                            </div>
                          </div>
                          <div className="right">
                            <div
                              className="symbol"
                              onClick={() => changeCount("minus", addOn.type)}
                            >
                              -
                            </div>
                            <div className="counter">{addOn.count}</div>
                            <div
                              className="symbol"
                              onClick={() => changeCount("plus", addOn.type)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <button
                className="continue-without"
                onClick={() => finishOrder()}
              >
                {addOnsPrice === 0 ? "Continue without add-ons" : "Continue"}
              </button>
            </div>
          </div>
          <div className="summary">
            <span className="summary-title">Order Summary</span>
            <div className="grey-line"></div>
            <div className="products">
              <div className="product">
                <div className="product-item">
                  <span>{state.order.name} Package</span>
                  <div
                    className={frequency === "year" ? "price year" : "price"}
                  >
                    {frequency === "year" ? (
                      <div className="cross-year"></div>
                    ) : null}
                    <span>
                      $
                      {(state.order.price / 100) *
                        (frequency === "year" ? 12 : 1)}
                    </span>
                  </div>
                </div>
                {frequency === "year" ? (
                  <div className="product-item year">
                    <span className="description">Annually</span>
                    <span className="price">
                      ${(state.order.price / 100) * 10}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="product">
                <div className="product-item">
                  <span>Add-Ons</span>
                  <div className="price">
                    <span>${addOnsPrice}</span>
                  </div>
                </div>
                <div className="product-item year">
                  <span className="description">One Time</span>
                </div>
              </div>
            </div>
            <div className="total">
              <div className="total-price">
                <span>Total</span>
                <div className={frequency === "year" ? "price year" : "price"}>
                  {frequency === "year" ? (
                    <div className="cross-year"></div>
                  ) : null}
                  <span>
                    $
                    {(state.order.price / 100) *
                      (frequency === "year" ? 12 : 1) +
                      addOnsPrice}
                  </span>
                </div>
              </div>
              {frequency === "year" ? (
                <div className="savings">
                  <span className="stating">Annual purchase</span>
                  <div className="savings-price">
                    <span className="stating">
                      Saving ${(state.order.price / 100) * 2} yearly
                    </span>
                    <span className="final-price">
                      ${(state.order.price / 100) * 10 + addOnsPrice}
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CustomerTestAddOns;
