import { coreService } from "./core.service"

const getAllClients = (page, type = 'all', callback) => {
    coreService.get(`client/list/${type}/${page}`, callback)
}

const getClientDetails = (clientId, callback) => {
    coreService.get(`client/details/${clientId}`, callback)
}

const changeBlock = (clientId, block, callback) => {
    coreService.post(`client/change-block/${clientId}`, {block}, callback)
}

export const clientService = {
    getAllClients,
    getClientDetails,
    changeBlock
}