import "./YourPackage.scss";
import { AiFillCheckCircle, AiOutlineCalendar } from "react-icons/ai";
import { useEffect, useState } from "react";
import Visa from "../../../../../assets/visa.svg";
import Master from "../../../../../assets/master.svg";
import NeutralCard from "../../../../../assets/neutral-card.png";
import { useNavigate } from "react-router";
import Thumbnail from "../../../../../assets/video-types/thumbnail.png";
import Banner from "../../../../../assets/video-types/banner.png";
import Logo from "../../../../../assets/video-types/logo.png";
import ConsultationCall from "../../../../../assets/video-types/consultation-call.png";
import Outro from "../../../../../assets/video-types/outro.png";
import Intro from "../../../../../assets/video-types/intro.png";
import { userService } from "../../../../../services/user.service";
import Loading from "../../../../loading/Loading";
import moment from "moment";
import { purchaseService } from "../../../../../services/package.service";
import Swal from "sweetalert2";
import { PackageManagementService } from "../../../../../services/package-management.service";
import LoadingUpload from "../../../../loadingUpload/LoadingUpload";

const CustomerYourPackage = () => {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState("Credit Card");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [features1, setFeatures1] = useState({});
  const [features2, setFeatures2] = useState({});
  const [featuresDetails, setFeaturesDetails] = useState({});
  const [isNewCardActive, setIsNewCardActive] = useState(false);
  const [cardEditingDetails, setCardEditingDetails] = useState(false);
  const [isPurchaseAddOnsActive, setIsPurchaseAddOnsActive] = useState(false);
  const [seeDetails, setSeeDetails] = useState(false);
  const [addOns, setAddOns] = useState([]);
  const [card, setCard] = useState({
    name: "",
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  const [addOnsPrice, setAddOnsPrice] = useState(0);
  const [isManagePackageOpen, setIsManagePackageOpen] = useState(false);

  useEffect(() => {
    userService.getProfile(
      (data) => {
        getUser(data);
      },
      (e) => {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Please, login before entering your account",
          icon: "error",
        }).then(() => {
          navigate("/auth/admin/sign-in");
        });
      }
    );
  }, []);

  const getUser = (data) => {
    const obj = JSON.parse(JSON.stringify(data));

    if (user.stripeInfo && user.stripeInfo !== null) {
      obj.stripeInfo.cards = obj.stripeInfo.cards.map((card) => {
        card.selected = false;
        return card;
      });
    }

    setUser(obj);
  };

  const cancelPackage = () => {
    Swal.fire({
      title: "Warning!",
      html:
        "You are about to cancel your current package " +
        `'<b>${user.package.name} Package</b>'. ` +
        "Are you sure you want to continue?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2A9DF4",
      cancelButtonColor: "#FFFFFF",
      confirmButtonText: "Yes, Cancel Package",
      cancelButtonText: "No, Don’t Cancel Package",
      customClass: {
        cancelButton: "cancel-button-modal",
        confirmButton: "confirm-button-modal",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        purchaseService.cancelPackage(user.package, (data) => {
          const obj = JSON.parse(JSON.stringify(user));
          obj.package = data;
          getUser(obj);
          Swal.fire({
            title: "Success!",
            text: "Your package has been cancelled",
            icon: "success",
          });
        });
      }
    });
  };

  const getPackagesAndAddOns = async () => {
    let myPkg;
    await PackageManagementService.list((data) => {
      data.addOns = data.addOns.map((addOn) => {
        addOn.count = 0;
        return addOn;
      });

      setAddOns(data.addOns);
      myPkg = data.packages.filter((pkg) => pkg.title === user.package.name)[0];

      const arr1 = [];
      const arr2 = [];

      if (user.package.rawFootage === null) {
        arr1.push(`Unlimited minutes of raw provided footage per video`);
      }

      if (user.package.rawFootage > 0) {
        arr1.push(
          `Up to ${user.package.rawFootage} minutes of raw provided footage per video`
        );
      }

      if (user.package.runningVideo > 0) {
        arr1.push(
          `Up to ${user.package.runningVideo} minutes of running time per video`
        );
      }

      arr1.push(
        `${user.package.deliveryTime}-${
          user.package.deliveryTime + 1
        } days delivery guaranteed`
      );

      if (user.package.unlimitedStockFootage) {
        arr1.push("Unlimited Stock Footage");
      }

      arr1.push(`Unlimited Revisions`);

      if (user.package.thumbnails === null) {
        arr1.push(`Unlimited Thumbnails`);
      }

      let newAddOnsPrice = user.package.additionalPrice;

      if (user.package.thumbnails > 0) {
        arr1.push(
          `${user.package.thumbnails} Thumbnail${
            user.package.thumbnails === 1 ? "" : "s"
          }`
        );
      }

      if (user.package.banner > 0) {
        arr1.push(
          `${user.package.banner} Banner${user.package.banner === 1 ? "" : "s"}`
        );
      }

      if (user.package.logo > 0) {
        arr1.push(
          `${user.package.logo} Logo${user.package.logo === 1 ? "" : "s"}`
        );
      }

      if (user.package.consultationCall > 0) {
        arr1.push(
          `${user.package.consultationCall} Consultation Call${
            user.package.consultationCall === 1 ? "" : "s"
          }`
        );
      }

      if (user.package.intro > 0) {
        arr1.push(
          `${user.package.intro} Intro${user.package.intro === 1 ? "" : "s"}`
        );
      }

      if (user.package.outro > 0) {
        arr1.push(
          `${user.package.outro} Outro${user.package.outro === 1 ? "" : "s"}`
        );
      }

      if (user.package.additionalThumbnails > 0) {
        arr2.push(
          `${user.package.additionalThumbnails} Thumbnail${
            user.package.additionalThumbnails === 1 ? "" : "s"
          }`
        );

        // const obj = [...data.addOns].filter(
        //   (addOn) => addOn.codeId === "thumbnails"
        // )[0];
        // newAddOnsPrice =
        //   newAddOnsPrice + obj.cost * user.package.additionalThumbnails;
      }

      if (user.package.additionalBanner > 0) {
        arr2.push(
          `${user.package.additionalBanner} Banner${
            user.package.additionalBanner === 1 ? "" : "s"
          }`
        );

        // const obj = [...data.addOns].filter(
        //   (addOn) => addOn.codeId === "banner"
        // )[0];
        // newAddOnsPrice =
        //   newAddOnsPrice + obj.cost * user.package.additionalBanner;
      }

      if (user.package.additionalLogo > 0) {
        arr2.push(
          `${user.package.additionalLogo} Logo${
            user.package.additionalLogo === 1 ? "" : "s"
          }`
        );

        // const obj = [...data.addOns].filter(
        //   (addOn) => addOn.codeId === "logo"
        // )[0];
        // newAddOnsPrice =
        //   newAddOnsPrice + obj.cost * user.package.additionalLogo;
      }

      if (user.package.additionalConsultationCall > 0) {
        arr2.push(
          `${user.package.additionalConsultationCall} Consultation Call${
            user.package.additionalConsultationCall === 1 ? "" : "s"
          }`
        );

        // const obj = [...data.addOns].filter(
        //   (addOn) => addOn.codeId === "consultationCall"
        // )[0];
        // newAddOnsPrice =
        //   newAddOnsPrice + obj.cost * user.package.additionalConsultationCall;
      }

      if (user.package.additionalIntro > 0) {
        arr2.push(
          `${user.package.additionalIntro} Intro${
            user.package.additionalIntro === 1 ? "" : "s"
          }`
        );

        // const obj = [...data.addOns].filter(
        //   (addOn) => addOn.codeId === "intro"
        // )[0];
        // newAddOnsPrice =
        //   newAddOnsPrice + obj.cost * user.package.additionalIntro;
      }

      if (user.package.additionalOutro > 0) {
        arr2.push(
          `${user.package.additionalOutro} Outro${
            user.package.additionalOutro === 1 ? "" : "s"
          }`
        );

        // const obj = [...data.addOns].filter(
        //   (addOn) => addOn.codeId === "outro"
        // )[0];
        // newAddOnsPrice =
        //   newAddOnsPrice + obj.cost * user.package.additionalOutro;
      }

      setAddOnsPrice(newAddOnsPrice);

      if (user.package.nextPackage) {
        const arrDetails = [];

        if (user.package.nextPackage.rawFootage === null) {
          arrDetails.push(
            `Unlimited minutes of raw provided footage per video`
          );
        }

        if (user.package.nextPackage.rawFootage > 0) {
          arrDetails.push(
            `Up to ${user.package.nextPackage.rawFootage} minutes of raw provided footage per video`
          );
        }

        if (user.package.nextPackage.runningVideo > 0) {
          arrDetails.push(
            `Up to ${user.package.nextPackage.runningVideo} minutes of running time per video`
          );
        }

        arrDetails.push(
          `${user.package.nextPackage.deliveryTime}-${
            user.package.nextPackage.deliveryTime + 1
          } days delivery guaranteed`
        );

        if (user.package.nextPackage.unlimitedStockFootage) {
          arrDetails.push("Unlimited Stock Footage");
        }

        arrDetails.push(`Unlimited Revisions`);

        if (user.package.nextPackage.thumbnails === null) {
          arrDetails.push(`Unlimited Thumbnails`);
        }

        if (user.package.nextPackage.thumbnails > 0) {
          arrDetails.push(
            `${user.package.nextPackage.thumbnails} Thumbnail${
              user.package.nextPackage.thumbnails === 1 ? "" : "s"
            }`
          );
        }

        if (user.package.nextPackage.banner > 0) {
          arrDetails.push(
            `${user.package.nextPackage.banner} Banner${
              user.package.nextPackage.banner === 1 ? "" : "s"
            }`
          );
        }

        if (user.package.nextPackage.logo > 0) {
          arrDetails.push(
            `${user.package.nextPackage.logo} Logo${
              user.package.nextPackage.logo === 1 ? "" : "s"
            }`
          );
        }

        if (user.package.nextPackage.consultationCall > 0) {
          arrDetails.push(
            `${user.package.nextPackage.consultationCall} Consultation Call${
              user.package.nextPackage.consultationCall === 1 ? "" : "s"
            }`
          );
        }

        if (user.package.nextPackage.intro > 0) {
          arrDetails.push(
            `${user.package.nextPackage.intro} Personalized Intro${
              user.package.nextPackage.intro === 1 ? "" : "s"
            }`
          );
        }

        if (user.package.nextPackage.outro > 0) {
          arrDetails.push(
            `${user.package.nextPackage.outro} Personalized Outro${
              user.package.nextPackage.outro === 1 ? "" : "s"
            }`
          );
        }

        setFeaturesDetails(arrDetails);
      }

      setFeatures1(arr1);
      setFeatures2(arr2);
    });
  };

  useEffect(() => {
    if (user.firstName && (!user.package || user.package === null)) {
      navigate("../select-package");
    } else if (user.package) {
      getPackagesAndAddOns();
    }
  }, [user]);

  const changeCount = (operation, type, amount = 1) => {
    const newAddOns = [...addOns].map((addOn) => {
      if (addOn.type === type && operation === "plus") {
        addOn.count = addOn.count + amount;
      } else if (
        addOn.type === type &&
        operation === "minus" &&
        addOn.count > 0
      ) {
        addOn.count = addOn.count - amount;
      }

      return addOn;
    });

    setAddOns(newAddOns);
  };

  const changeAttribute = (attribute, value) => {
    const obj = JSON.parse(JSON.stringify(card));
    obj[attribute] = value;
    setCard(obj);
  };

  const validate = () => {
    card.exp_month = `${card.exp_month}`;
    card.exp_year = `${card.exp_year}`;

    if (!card.name) {
      return {
        success: false,
        message: "Add a valid name",
      };
    }

    if (
      card.number.replace(/ /g, "").length > 16 ||
      card.number.replace(/ /g, "").length < 15 ||
      isNaN(card.number.replace(/ /g, ""))
    ) {
      return {
        success: false,
        message: "Add a valid card number",
      };
    }

    if (card.exp_month.length > 2 || card.exp_month.length === 0) {
      return {
        success: false,
        message: "Add a valid expiration month",
      };
    }

    if (card.exp_year.length !== 4 && card.exp_year.length !== 2) {
      return {
        success: false,
        message: "Add a valid expiration year",
      };
    }

    if (card.exp_year.length === 2) {
      changeAttribute("exp_year", `20${card.exp_year}`);
      return {
        success: true,
      };
    }

    if (card.cvc.length < 3 || isNaN(card.cvc)) {
      return {
        success: false,
        message: "Add a valid CVV code",
      };
    }

    return {
      success: true,
    };
  };

  const purchase = () => {
    setLoading(true);
    const validation = validate();
    if (!validation.success) {
      Swal.fire({
        title: "Purchase Failed!",
        text: validation.message,
        icon: "error",
      });
      setLoading(false);
      return;
    }

    const newCard = JSON.parse(JSON.stringify(card));
    if (newCard.number) {
      newCard.number = newCard.number.trim();
    }

    purchaseService.addCard(newCard, (data) => {
      if (data && data.id) {
        setLoading(false);
        Swal.fire({
          title: "Card Added Successfully!",
          icon: "success",
        }).then((data) => {
          console.log("ois");
          userService.getProfile(
            (data) => {
              getUser(data);
              setIsNewCardActive(false);
              setCard({
                name: "",
                number: "",
                exp_month: "",
                exp_year: "",
                cvc: "",
              });
            },
            (e) => {
              setLoading(false);
              Swal.fire({
                title: "Error!",
                text: "Please, login before entering your account",
                icon: "error",
              }).then(() => {
                navigate("/auth/admin/sign-in");
              });
            }
          );
        });
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong with your purchase, please try again later",
          icon: "error",
        });
      }
    });
  };

  const removeCard = async (card) => {
    purchaseService.deleteCard(card.id, (data) => {
      Swal.fire({
        title: "Card Deleted Successfully!",
        icon: "success",
      }).then((data) => {
        userService.getProfile(
          (data) => {
            getUser(data);
          },
          (e) => {
            setLoading(false);
            Swal.fire({
              title: "Error!",
              text: "Please, login before entering your account",
              icon: "error",
            }).then(() => {
              navigate("/auth/admin/sign-in");
            });
          }
        );
      });
    });
  };

  const updateDefaultCard = async () => {
    const newDefault = user.stripeInfo.cards.filter(
      (card) => card.selected === true
    )[0];

    purchaseService.changeDefaultCard(newDefault.id, (data) => {
      Swal.fire({
        title: "New Default Card Set Successfully!",
        icon: "success",
      }).then((data) => {
        userService.getProfile(
          (data) => {
            getUser(data);
          },
          (e) => {
            setLoading(false);
            Swal.fire({
              title: "Error!",
              text: "Please, login before entering your account",
              icon: "error",
            }).then(() => {
              navigate("/auth/admin/sign-in");
            });
          }
        );
      });
    });
  };

  const updatePackage = () => {
    const order = JSON.parse(JSON.stringify(user.package));

    if (user.package.name === "Custom") {
      navigate("../custom-package", { state: { order, update: true } });
    } else if (user.package.name === "Single") {
      navigate("../single-order", { state: { order, update: true } });
    } else {
      navigate("../select-package", { state: { order, update: true } });
    }
  };

  const buyAddOns = async () => {
    let moreThanOne = false;
    const obj = {};
    for (const addOn of [...addOns]) {
      if (addOn.count > 0) {
        moreThanOne = true;
      }
      obj[addOn.codeId] = addOn.count;
    }

    if (!moreThanOne) {
      Swal.fire({
        title: "Ops!",
        text: "Please, add at least one add-on",
        icon: "error",
      });
      return;
    }

    setLoading(true);

    purchaseService.buyAddOn(obj, (data) => {
      setLoading(false);
      if (data && data.firstName) {
        Swal.fire({
          title: "Add-Ons successfully added!!",
          icon: "success",
        }).then(() => {
          console.log(data);
          setIsPurchaseAddOnsActive(false);
          const curr = JSON.parse(JSON.stringify(user));
          curr.package = data.package;
          setUser(curr);
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "Something went wrong with your purchase, please try again later",
          icon: "error",
        });
      }
    });
  };

  const formatCardNumber = (type) => {
    if (card.number && type === "blur") {
      let newCardNumber = JSON.parse(JSON.stringify(card)).number.split("");

      if (newCardNumber[0] === "3") {
        if (newCardNumber.length > 4) {
          newCardNumber.splice(4, 0, " ");
        }
        if (newCardNumber.length > 11) {
          newCardNumber.splice(11, 0, " ");
        }
      } else {
        if (newCardNumber.length > 4) {
          newCardNumber.splice(4, 0, " ");
        }
        if (newCardNumber.length > 9) {
          newCardNumber.splice(9, 0, " ");
        }
        if (newCardNumber.length > 14) {
          newCardNumber.splice(14, 0, " ");
        }
      }

      changeAttribute("number", newCardNumber.join(""));
    } else {
      let newCardNumber = JSON.parse(JSON.stringify(card)).number.split(" ");
      changeAttribute("number", newCardNumber.join(""));
    }
  };

  return user && user.package ? (
    <div className="customer-layout-your-package-page">
      {loading ? <LoadingUpload text="Finishing Purchase" /> : null}
      {isManagePackageOpen ? (
        <div className="manage-package-container">
          <div className="manage-package">
            <div className="manage-package-title">
              <span>Manage Package</span>
              <span
                className="close"
                onClick={() => setIsManagePackageOpen(false)}
              >
                close
              </span>
            </div>
            <div className="manage-package-content">
              <div className="left">
                <span className="content-title">Your Package</span>
                <div className="package-details">
                  <span className="package-title">
                    {user.package.name} Package
                  </span>
                  <ul className="package-features">
                    <li className="package-feature">
                      <span>
                        ${user.package.price / 100}/
                        {user.package.type === "monthly" ? "mo" : "year"}
                      </span>
                    </li>
                    <li className="package-feature">
                      <span>{user.package.videos} Videos</span>
                    </li>
                    {features1 &&
                      features1.length &&
                      features1.map((feature) => {
                        return (
                          <li className="package-feature">
                            <span>{feature}</span>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="right">
                <span className="content-title">Available Actions</span>
                <div className="available-actions">
                  <div className="available-action">
                    <div className="text">
                      <span className="action-title">Find a Better Plan</span>
                      <span className="action-description">
                        Let us help you find the right plan for your needs, and
                        make the switch quick and easy.
                      </span>
                    </div>
                    <button onClick={() => updatePackage()}>
                      Change Package
                    </button>
                  </div>
                  {user.package.active ? (
                    <div className="available-action">
                      <div className="text">
                        <span className="action-title">End Your Service</span>
                        <span className="action-description">
                          Sometimes you just need to call it quits. We get it.
                        </span>
                      </div>
                      <button
                        className="cancel"
                        onClick={() => cancelPackage()}
                      >
                        Cancel Package
                      </button>
                    </div>
                  ) : (
                    <div className="available-action">
                      <div className="text">
                        <span className="action-title">Package Cancelled</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isPurchaseAddOnsActive ? (
        <div className="add-add-ons-container">
          <div className="add-add-ons">
            <div className="add-add-ons-header">
              <span>Add Add-Ons</span>
              <span
                className="close"
                onClick={() => {
                  setIsPurchaseAddOnsActive(false);
                  const newAddOns = [...addOns].map((addOn) => {
                    addOn.count = 0;
                    return addOn;
                  });

                  setAddOns(newAddOns);
                }}
              >
                close
              </span>
            </div>
            <span className="description">
              One time purchases billed monthly along with your current Package.
            </span>
            <div className="add-ons">
              {addOns
                .filter(
                  (addOn) =>
                    addOn.type !== "Video" &&
                    addOn.type !== "Raw Footage Time" &&
                    addOn.type !== "Final Running Time"
                )
                .map((addOn) => {
                  return (
                    <div className="add-on">
                      <div className="left">
                        <div className="image-container">
                          {addOn.type === "Thumbnail" ? (
                            <img src={Thumbnail} alt="add-on-symbol" />
                          ) : addOn.type === "Logo" ? (
                            <img src={Logo} alt="add-on-symbol" />
                          ) : addOn.type === "Banner Design" ? (
                            <img src={Banner} alt="add-on-symbol" />
                          ) : addOn.type === "Consultation Call" ? (
                            <img src={ConsultationCall} alt="add-on-symbol" />
                          ) : addOn.type === "Outro" ? (
                            <img src={Outro} alt="add-on-symbol" />
                          ) : (
                            <img src={Intro} alt="add-on-symbol" />
                          )}
                        </div>
                        <div className="details">
                          <span className="name">{addOn.type}</span>
                          <span className="price">${addOn.cost}</span>
                        </div>
                      </div>
                      <div className="right">
                        <div
                          className="symbol"
                          onClick={() => changeCount("minus", addOn.type)}
                        >
                          -
                        </div>
                        <div className="counter">{addOn.count}</div>
                        <div
                          className="symbol"
                          onClick={() => changeCount("plus", addOn.type)}
                        >
                          +
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <button onClick={() => buyAddOns()}>Add</button>
          </div>
        </div>
      ) : null}
      {isNewCardActive ? (
        <div className="add-new-card">
          <div className="card-details">
            <span
              className="close"
              onClick={() => {
                setIsNewCardActive(false);
                setCard({
                  name: "",
                  number: "",
                  exp_month: "",
                  exp_year: "",
                  cvc: "",
                });
              }}
            >
              close
            </span>
            <span className="details-title">Payment Methods</span>
            <div className="input-box">
              <span className="input-title">Card Information</span>
              <div className="input-field">
                <span>Name on Card</span>
                <input
                  value={card.name}
                  onChange={(e) => changeAttribute("name", e.target.value)}
                  placeholder="Name on Card"
                />
              </div>
              <div className="input-field">
                <span>Credit Card Number</span>
                <input
                  value={card.number}
                  maxLength={16}
                  onBlur={() => formatCardNumber("blur")}
                  onFocus={() => formatCardNumber("focus")}
                  onChange={(e) => changeAttribute("number", e.target.value)}
                  placeholder="Credit Card Number"
                />
              </div>
              <div className="inputs">
                <div className="input-field">
                  <span>Expiration Month</span>
                  <input
                    maxLength={2}
                    value={card.exp_month}
                    onChange={(e) =>
                      changeAttribute("exp_month", e.target.value)
                    }
                    placeholder="MM"
                  />
                </div>
                <div className="input-field">
                  <span>Expiration Year</span>
                  <input
                    maxLength={4}
                    value={card.exp_year}
                    onChange={(e) =>
                      changeAttribute("exp_year", e.target.value)
                    }
                    placeholder="YYYY"
                  />
                </div>
              </div>
              <div className="input-field">
                <span>CVV</span>
                <input
                  maxLength={3}
                  value={card.cvc}
                  onChange={(e) => changeAttribute("cvc", e.target.value)}
                  placeholder="CVV"
                />
              </div>
            </div>
            <button onClick={() => purchase()} className="complete">
              Save
            </button>
          </div>
        </div>
      ) : null}
      <span className="package-title">Current Package</span>
      <div className="package-content">
        <div className="features">
          <div className="package-name">
            <span>{user.package.name}</span>
            {user.package.videos > 0 ? (
              <span className="description">
                {user.package.videos} video{user.package.videos > 1 ? "s" : ""}{" "}
                per month
              </span>
            ) : null}
          </div>
          <div className="line"></div>
          <div className="features-list">
            <span className="features-list-title">Available Features</span>
            {features1 &&
              features1.length &&
              features1.map((feature) => {
                return (
                  <div className="feature">
                    <AiFillCheckCircle color="#2A9DF4" size={25} />
                    <span>{feature}</span>
                  </div>
                );
              })}
            <span className="features-list-title add-ons">Add-Ons</span>
            {features2 && features2.length
              ? features2.map((feature) => {
                  return (
                    <div className="feature">
                      <AiFillCheckCircle color="#2A9DF4" size={25} />
                      <span>{feature}</span>
                    </div>
                  );
                })
              : null}
            <button
              className="update"
              onClick={() =>
                // navigate("../add-add-ons", {
                //   state: { update: true, user: user },
                // })
                setIsPurchaseAddOnsActive(true)
              }
            >
              Purchase Add-Ons
            </button>
          </div>
        </div>
        <div className="details-col">
          <div className="details">
            <div className="price-name">
              <span className="name">{user.package.name} Package</span>
              <span className="price">
                ${user.package.price / 100}/
                {user.package.type === "monthly" ? "mo" : "year"}
              </span>
            </div>
            <div className="dates">
              <div className="date">
                <AiOutlineCalendar color="#6872CF" size={20} />
                <div className="texts">
                  <span className="text-title">Last Billing Date</span>
                  <span className="actual-date">
                    {moment(user.package.lastBilling).format("DD MMMM YYYY")}
                  </span>
                </div>
              </div>
              <div className="vertical-line" />
              <div className="date">
                <AiOutlineCalendar color="white" size={20} />
                <div className="texts">
                  <span className="text-title">Next Billing Date</span>
                  <span className="actual-date">
                    {user.package.active
                      ? moment(user.package.nextBilling).format("DD MMMM YYYY")
                      : "Cancelled"}
                  </span>
                </div>
              </div>
            </div>
            <div className="actions">
              {/* <button className="update" onClick={() => updatePackage()}>
                Manage Package
              </button> */}
              <button
                className="update"
                onClick={() => setIsManagePackageOpen(true)}
              >
                Manage Package
              </button>
              {/* {user.package.active ? (
              <button
                className="cancel"
                onClick={() => {
                  cancelPackage();
                }}
              >
                Cancel Package
              </button>
            ) : null} */}
            </div>
            {user.package.active &&
            user.package.nextPackage &&
            user.package.nextPackage !== null ? (
              <div className="other-packages">
                <span className="other-title">
                  Next Package - Starting on{" "}
                  {moment(user.package.nextBilling).format("DD MMMM YYYY")}
                </span>
                <div className="option starter">
                  <div className="name-price">
                    <div className="text">
                      <span className="option-title">
                        {user.package.nextPackage.name}
                        <span
                          onClick={() => setSeeDetails(!seeDetails)}
                          className="see-details"
                        >
                          {" "}
                          - {seeDetails ? "Hide details" : "See details"}
                        </span>
                      </span>
                    </div>
                    <span className="price">
                      ${user.package.nextPackage.price / 100}/month
                    </span>
                  </div>
                  {seeDetails ? (
                    <div className="features-list details-features">
                      {featuresDetails &&
                        featuresDetails.length &&
                        featuresDetails.map((feature) => {
                          return (
                            <div className="feature">
                              <AiFillCheckCircle color="#2A9DF4" size={20} />
                              <span>{feature}</span>
                            </div>
                          );
                        })}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          {addOnsPrice > 0 ? (
            <div className="details">
              <div className="price-name">
                <span className="name">Add-Ons</span>
                <span className="price">${addOnsPrice} one-time purchase</span>
              </div>
              <div className="features-list">
                {features2 &&
                  features2.length &&
                  features2.map((feature) => {
                    return (
                      <div className="feature">
                        <AiFillCheckCircle color="#2A9DF4" size={25} />
                        <span>{feature}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}
          <div className="details black">
            <div className="price-name">
              <span className="name">{moment().format("MMMM")} Total</span>
              <span className="name-description">
                Current month’s package plus any add-ons
              </span>
              <span className="price">
                ${user.package.price / 100 + addOnsPrice}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="payments">
        <span className="payment-title">Payment Methods</span>
        {paymentMethod === "Credit Card" && (
          <div className="cards">
            {user.stripeInfo && user.stripeInfo.cards
              ? user.stripeInfo.cards.map((card, index) => {
                  return (
                    <div
                      className={`card ${
                        card.brand === "Visa" ? "visa" : "master"
                      }`}
                    >
                      <img
                        alt="brand"
                        src={
                          card.brand === "Visa"
                            ? Visa
                            : card.brand === "MasterCard"
                            ? Master
                            : NeutralCard
                        }
                      />
                      <span>
                        <span className="brand">{card.brand}</span> ending in{" "}
                        {card.last4}
                      </span>
                      {/* <div className="bottom">
                        {!card.default ? (
                          <input
                            type="checkbox"
                            value={card.selected}
                            onChange={(e) => {
                              const obj = JSON.parse(JSON.stringify(user));
                              obj.stripeInfo.cards = obj.stripeInfo.cards.map(
                                (card) => {
                                  card.selected = false;
                                  return card;
                                }
                              );

                              obj.stripeInfo.cards[index].selected =
                                e.target.checked;

                              setUser(obj);
                            }}
                          />
                        ) : null}
                        {user.stripeInfo.cards &&
                        user.stripeInfo.cards.length > 1 ? (
                          <div className="actions">
                            <button onClick={() => removeCard(card)}>
                              Remove
                            </button>
                          </div>
                        ) : null}
                      </div> */}
                    </div>
                  );
                })
              : null}
          </div>
        )}
        {/* {user.stripeInfo.cards.length > 1 ? (
          <button className="update-btn" onClick={() => updateDefaultCard()}>
            Update Default
          </button>
        ) : null} */}
        <div className="cards-actions">
          <span onClick={() => setIsNewCardActive(true)}>+ Add New</span>
          <div className="vertical-line"></div>
          <span
            onClick={() => {
              console.log(user.stripeInfo.cards[0]);
              const cardObj = {
                number: `XXXX XXXX XXXX ${user.stripeInfo.cards[0].last4}`,
                cvc: "XXX",
                name: user.stripeInfo.cards[0].name,
                exp_month: user.stripeInfo.cards[0].exp_month,
                exp_year: user.stripeInfo.cards[0].exp_year,
              };
              setCard(cardObj);
              setIsNewCardActive(true);
            }}
          >
            Edit
          </span>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default CustomerYourPackage;
